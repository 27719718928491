const getFormattedHours = (startHour: number, endHour: number) => {
  const hours = [];

  for (let hour = startHour; hour <= endHour; hour++) {
    let formattedHour;
    if (hour === 0) {
      formattedHour = "12am";
    } else if (hour < 12) {
      formattedHour = `${hour}am`;
    } else if (hour === 12) {
      formattedHour = "12pm";
    } else {
      formattedHour = `${hour - 12}pm`;
    }
    hours.push(formattedHour);
  }

  return hours;
};

const getNextAppointmentTime = (window: number) => {
  const now = new Date();

  // Get the current minutes
  let minutes = now.getMinutes();

  // Calculate how much time to add to round up to the next interval
  const remainder = minutes % window;
  const addMinutes = remainder === 0 ? 0 : window - remainder;

  // Set the future time with the calculated minutes
  now.setMinutes(minutes + addMinutes);

  // Return the rounded-up time as a string (optional: format it as you need)
  return now;
};

export { getFormattedHours, getNextAppointmentTime };
