import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  UpdateVisitField,
  changeVisitData,
  selectStep,
  selectVisit,
  changeStep,
  changeVisit,
  changeVisitMeta,
  UpdateVisitMeta,
} from "../../features/visit/visitSlice";
import Diagnosis from "./Diagnosis";
import Treatment from "./Treatment";
import Payment from "./Payment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getLayout,
  changeVisitStatusById,
  getLayoutById,
  saveVisit,
  updateVisit,
  getVisit,
} from "../../apis/visit";
import { PrescriptionDetails, Visit } from "../../interfaces/Visit";
import { Layout } from "react-grid-layout";
import { saveTransaction } from "../../apis/payment";
import { useNotification } from "../../NotificationContext";
import { format, parseISO, setHours, setMinutes, setSeconds } from "date-fns";
import { LanguageContext } from "../../providers/LanguageProvider";
import { LayoutComponent } from "../../interfaces/LayoutComponent";
import ConfirmationModal from "../../components/ConfirmationModal";
import { getUsers } from "../../apis/user";
import { getSettings } from "../../apis/setting";
import CustomPage from "./CustomPage";
import { classNames } from "../../utils/CssUtil";
import { generateLayoutObject, updateGridItems } from "../../utils/LayoutUtil";
import Modal from "../../components/Modal";
import Prescription from "./Prescription";

const componentMap: { [key: string]: React.ComponentType<any> } = {
  custom: CustomPage,
  diagnosis: Diagnosis,
  treatment: Treatment,
  payment: Payment,
};

interface Page {
  id: string;
  label: string;
  gridItems: LayoutComponent[];
  layout?: Layout[];
  type: string;
  component: string;
}

type PageSequence = Array<Page>;

const AddVisit = () => {
  const currentStep = useAppSelector(selectStep);
  const visit = useAppSelector(selectVisit);
  const user = window.localStorage.getItem("user");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const defaultTransaction = {
    amount: 0,
    visitId: null,
    note: "",
    paymentMode: "cash",
    date: format(new Date(), "yyyy-MM-dd"),
  };
  const [transaction] = useState(defaultTransaction);
  const { showNotification } = useNotification();
  const { translate: t } = useContext(LanguageContext);
  const [isConfirmationBox, showConfirmationBox] = useState(false);
  const [users, setUsers] = useState<Array<any>>([]);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);

  const [isDoctorOnPrescription, setDoctorOnPrescription] =
    useState<boolean>(true);
  const [loggedInUser, setLoggedInUser] = useState<any>();
  const [pageSequence, setPageSequence] = useState<PageSequence>([]);
  // const [visitFile, setVisitFile] = useState<any>();
  const [isPostponeVisit, setIsPostponeVisit] = useState(false);
  const [visitId, setVisitId] = useState<any>();
  const [prescriptionDetails, setPrescriptionDetails] =
    useState<PrescriptionDetails>();
  const [isPrescriptionVisible, setIsPrescriptionVisible] = useState(false);

  useEffect(() => {
    fetchUsers({});
    fetchSettings();
  }, []);

  useEffect(() => {
    if (visit && visit.clinicVisitTypeLayoutId) {
      getVisitTypeLayoutById(visit.clinicVisitTypeLayoutId, visit.meta);
    }
  }, [visit.clinicVisitTypeLayoutId]);

  useEffect(() => {
    if (params.patientId && params.typeId) {
      updateField("patientId", parseInt(params.patientId));
      updateField("clinicVisitTypeId", parseInt(params.typeId));
    }
    if (params.visitId) {
      updateField("id", parseInt(params.visitId));
      fetchVisit();
    }
    if (params.typeId) {
      getVisitTypeLayout(parseInt(params.typeId), {});
    } else {
      // fetch layout for editable visit
    }
  }, [params]);

  useEffect(() => {
    const user = window.localStorage.getItem("user");
    if (user) {
      const userObj = JSON.parse(user);
      setLoggedInUser(userObj);
    }
  }, [user]);

  useEffect(() => {
    if (searchParams.get("step")) {
      const step = searchParams.get("step");
      if (step) {
        dispatch(changeStep(parseInt(step)));
      }
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };

  const updateMetaField = useCallback(
    (fieldName: string, value: any) => {
      const payload: UpdateVisitMeta = { fieldName, value };
      dispatch(changeVisitMeta(payload));
    },
    [dispatch] // dependency array
  );

  const fetchUsers = (params: any) => {
    getUsers(params).then((res: any) => {
      setUsers(res.data.data);
    });
  };

  const handleNext = () => {
    if (params.visitId) {
      updateVisitData();
      navigate(`/visits/${params.visitId}?step=${currentStep + 1}`);
    } else {
      saveVisitData();
    }
  };
  const handlePrevious = () => {
    navigate(`/visits/${params.visitId}?step=${currentStep - 1}`);
  };

  const updateDateField = async (date: any) => {
    const now = new Date();
    const currentTime = {
      hours: now.getHours(),
      minutes: now.getMinutes(),
      seconds: now.getSeconds(),
    };
    const updatedDate = setHours(
      setMinutes(
        setSeconds(parseISO(date), currentTime.seconds),
        currentTime.minutes
      ),
      currentTime.hours
    );
    const isoDate = new Date(updatedDate).toISOString();
    updateField("date", isoDate);
    return isoDate;
  };

  const saveVisitData = async () => {
    const updatedDate = await updateDateField(visit.date);
    saveVisit({ ...visit, date: updatedDate, is_scheduled: false })
      .then((res: any) => {
        dispatch(changeVisit(res));
        navigate(`/visits/${res.id}?step=${currentStep + 1}`);
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        showNotification("error", message, "", "topRight");

        if (message) {
          const existingVisitId = message.split("|")[1]; // Extracting the existing visit ID
          if (existingVisitId) {
            setVisitId(existingVisitId);
            setIsPostponeVisit(true);
          }
        } else {
          showNotification(
            "error",
            "An error occurred while saving the visit",
            "",
            "topRight"
          );
        }
      });
  };

  const fetchVisit = () => {
    if (visit.clinicVisitTypeLayoutId) {
      getVisitTypeLayoutById(visit.clinicVisitTypeLayoutId, visit.meta);
    }
  };

  const updateVisitData = async () => {
    updateVisit(visit)
      .then((res: any) => {
        dispatch(changeVisit(res));
      })
      .catch((err) => {
        if (err?.data?.message) {
          showNotification("error", err.data.message, "", "topRight");
        }
      });
  };

  const handleFinish = () => {
    showConfirmationBox(true);
  };

  const handleCloseConfirmationModal = () => {
    updateVisitData();
    addTransaction();
    showConfirmationBox(false);
    dispatch(changeStep(1));

    navigate("/visits");
  };

  const handleSubmitConfirmationModal = () => {
    updateVisitData();
    addTransaction();
    changeVisitStatusById({ id: params.visitId, flags: 4 }).then((res) => {
      dispatch(changeStep(1));

      navigate("/visits");
    });
  };

  const addTransaction = () => {
    transaction.visitId = visit.id;
    transaction.amount = visit.amountPaid ? visit.amountPaid : 0;
    saveTransaction({
      ...transaction,
    }).then((res: any) => {
      showNotification(
        "success",
        "Visit Transaction Added successfully",
        "",
        "topRight"
      );
    });
  };

  const processLayoutResponse = (response: any, data: any) => {
    updateField("clinicVisitTypeLayoutId", parseInt(response.data.id));
    const updatedPages = response.data.layout.map((i: Page) => {
      if (data.layoutContent) {
        // const layoutKeys = Object.keys(data.layoutContent);
        i.gridItems =
          i.gridItems &&
          i.gridItems?.map((item) => {
            const updatedItem = { ...item };
            // Update grid item values based on layoutContent and vitals
            updatedItem.data.value = getUpdatedValue(item.data.key, data);
            return updatedItem;
          });
      }

      return i;
    });
    setPageSequence(updatedPages);
  };

  const getUpdatedValue = (key: string, data: Record<string, any>): any => {
    if (data.defaultFields && key in data.defaultFields) {
      return data.defaultFields[key];
    }
    if (data.layoutContent && key in data.layoutContent) {
      return data.layoutContent[key];
    }
    if (data.vitals) {
      const selectedVital = data.vitals.find(
        (vital: any) => vital.label === key
      );
      if (selectedVital) {
        return selectedVital.value;
      }
      return data.vitals[key];
    }
    return null;
  };

  const getVisitTypeLayout = (id: any, data: any) => {
    getLayout(id).then((res: any) => processLayoutResponse(res, data));
  };

  const getVisitTypeLayoutById = (id: any, data: any) => {
    getLayoutById(id).then((res: any) => processLayoutResponse(res, data));
  };

  const handleAssigneeChange = (value: any) => {
    updateField("userId", parseInt(value));
  };

  const fetchSettings = () => {
    getSettings().then((res: any) => {
      const [result] = res.data.data.filter(
        (i: any) => i.code === "doctor_on_prescription"
      );
      if (result) {
        updateField(
          "userId",
          result.value === "logged_in_user" ? loggedInUser?.id : result.value
        );
        setDoctorOnPrescription(
          result.value === "logged_in_user" ? false : true
        );
      }
    });
  };

  const setValue = useCallback(
    (e: any, gridItem: LayoutComponent, index: number) => {
      setFocusIndex(index);

      setPageSequence((prevPages: Page[]) => {
        const updatedPages = updatePageSequence(prevPages, gridItem, e);

        // If pages are unchanged, return previous state
        if (prevPages === updatedPages) return prevPages;

        // Handle meta field update

        const { layoutContent, vitals, defaultFields } = generateLayoutObject(
          updatedPages[currentStep - 1].gridItems
        );

        (Object.entries(defaultFields) as [keyof Visit, string][]).forEach(
          ([key, value]) => {
            updateField(key, value);
          }
        );
        updateMetaField("layoutContent", layoutContent);
        updateMetaField("vitals", vitals);

        return updatedPages;
      });
    },
    [currentStep, updateMetaField]
  );

  // Helper function to update the page sequence
  const updatePageSequence = (
    prevPages: Page[],
    gridItem: LayoutComponent,
    e: any
  ): Page[] => {
    return prevPages.map((page, pageIndex) => {
      if (pageIndex !== currentStep - 1) return page;

      const updatedGridItems = updateGridItems(page.gridItems, gridItem, e);

      if (page.gridItems !== updatedGridItems) {
        return { ...page, gridItems: updatedGridItems };
      }

      return page;
    });
  };

  // Helper function to get new value based on component type

  const renderPages = () => {
    const currentPageIndex = currentStep - 1;

    return pageSequence.map((page, index) => {
      if (index === currentPageIndex) {
        const Component = componentMap[page.component] || null;

        if (Component) {
          return (
            <div>
              <Component
                key={page.id}
                layoutKey={page.id}
                gridItems={page.gridItems}
                layout={page.layout}
                visit={visit}
                onSetValue={setValue}
              />
            </div>
          );
        }

        // If the type is not mapped, handle accordingly
        return null;
      }

      return null; // Return null if the page is not the current one
    });
  };

  const handlePostponeVisit = async () => {
    let serialNumber;
    await getVisit(visitId).then((res: any) => {
      serialNumber = res.serialNumber;
    });

    try {
      const res: any = await updateVisit({
        ...visit,
        serialNumber: serialNumber,
        id: visitId,
      });
      dispatch(changeVisit(res));
      showNotification("success", "Visit updated successfully", "", "topRight");
      navigate("/visits");
    } catch (err: any) {
      const message =
        err?.data?.message || "An error occurred while updating the visit";
      showNotification("error", message, "", "topRight");
    }
  };

  const handleClosePostponeVisitModal = () => {
    setIsPostponeVisit(false);
    // navigate("/visits");
  };

  const renderFooter = () => {
    let hasMedicineSelect = false;
    let hasMedicineInstructions = false;
    if (pageSequence.length) {
      const { gridItems } = pageSequence[currentStep - 1];
      hasMedicineSelect = gridItems?.some(
        (item) => item.component === "MedicineSelect"
      );
      hasMedicineInstructions = gridItems?.some(
        (item) => item.component === "MedicineInstructions"
      );
    }

    return (
      <div className="footer-content">
        <div className="flex flex-row-reverse">
          {currentStep === pageSequence.length && (
            <button
              className="px-6 py-2 mx-2 my-2 text-sm transition duration-150 ease-in-out rounded text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              onClick={() => handleFinish()}
            >
              Finish
            </button>
          )}
          {currentStep < pageSequence.length && (
            <button
              onClick={() => handleNext()}
              className="px-6 py-2 mx-2 my-2 text-sm transition duration-150 ease-in-out rounded text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            >
              Next
            </button>
          )}
          {currentStep > 1 && (
            <button
              onClick={() => handlePrevious()}
              className="px-6 py-2 mx-2 my-2 text-sm transition duration-150 ease-in-out rounded text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            >
              Previous
            </button>
          )}
          {hasMedicineSelect && hasMedicineInstructions && (
            <button
              className="px-6 py-2 mx-2 my-2 text-sm text-gray-900 transition duration-150 ease-in-out bg-white border rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              onClick={() => generatePrescription()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    );
  };

  const generatePrescription = () => {
    console.log("m here");

    setIsPrescriptionVisible(true);
    if (visit.patient) {
      const details: PrescriptionDetails = {
        symptoms: [],
        finalDiagnosis: visit.finalDiagnosis.map((i) => i.label).join(","),
        provisionalDiagnosis: visit.provisionalDiagnosis
          .map((i) => i.label)
          .join(","),
        medicines: visit.medicines,
        advice: visit.advice,
        patient: visit.patient,
        visitId: visit.id,
      };
      setPrescriptionDetails(details);
    }
  };

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex flex-col ">
        {isDoctorOnPrescription && (
          <div className="flex p-4 mb-5 bg-white rounded">
            <div className="flex flex-col w-full">
              <span className="text-sm font-medium text-gray-700">
                Select Assignee
              </span>
              <select
                className="max-w-xl pt-1 pb-1 border-gray-300 rounded-lg"
                name="selectedAssignee"
                id="assignee"
                value={visit.userId ?? ""}
                onChange={(e) => handleAssigneeChange(e.target.value)}
              >
                {users.map((user: any) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName + " " + user.lastName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="text-white bg-white flexp-4 rounded-t-md">
          <nav aria-label="Progress w-full ">
            <ol className="bg-white border-2 border-gray-300 divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
              {pageSequence.map((page: any, index: number) => (
                <li key={index} className="relative md:flex md:flex-1">
                  <div className="flex items-center w-full group">
                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                      {currentStep === index + 1 ? (
                        <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border-2 rounded-full border-primary group-hover:border-gray-400">
                          <span className="text-primary">{index + 1}</span>
                        </span>
                      ) : currentStep < index + 2 ? (
                        <span
                          className={classNames(
                            " flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border-2 text-gray-500 rounded-full  border-gray-300  group-hover:border-gray-400 "
                          )}
                        >
                          {index + 1}
                        </span>
                      ) : (
                        <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-full bg-primary group-hover:border-gray-400">
                          <svg
                            className="w-6 h-6 text-white"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                      <span
                        className={classNames(
                          currentStep === index + 1
                            ? "text-primary"
                            : " text-gray-900",
                          "ml-4 text-sm font-medium capitalize"
                        )}
                      >
                        {page.label}
                      </span>
                    </span>

                    {pageSequence.length !== index + 1 && (
                      <div
                        className="absolute top-0 right-0 hidden w-5 h-full md:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-full h-full text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
      <div className="flex bg-white flex-col min-h-[60vh]">{renderPages()}</div>
      <footer className="px-4 py-4 bg-white rounded-b-md">
        {renderFooter()}
      </footer>
      {isConfirmationBox && (
        <ConfirmationModal
          message="Are you sure do you want to complete this visit ?"
          onClose={handleCloseConfirmationModal}
          onSubmit={handleSubmitConfirmationModal}
          okLabel="Mark Complete"
          cancelLabel="Not Completed"
        ></ConfirmationModal>
      )}

      {isPostponeVisit && (
        <ConfirmationModal
          message="You have already scheduled this visit with the same doctor on same date. Do you want to postpone this visit?"
          onClose={() => handleClosePostponeVisitModal()}
          onSubmit={() => {
            handlePostponeVisit();
            setIsPostponeVisit(false);
          }}
          okLabel="Yes"
          cancelLabel="Cancel"
          noLabel="No"
          onNo={() => {
            setIsPostponeVisit(false);
            saveVisitData();
          }}
        ></ConfirmationModal>
      )}
      {isPrescriptionVisible && prescriptionDetails && (
        <Modal
          width="max-w-2xl"
          title="Prescription Preview"
          onClose={() => setIsPrescriptionVisible(false)}
          modalContent={
            <Prescription preview prescriptionDetails={prescriptionDetails} />
          }
        />
      )}
    </div>
  );
};
export default AddVisit;
