import React, { FC, memo, useEffect, useRef } from "react";
import { AmsaInputProps } from "../../interfaces/AmsaInputs";

const AmsaInput: FC<AmsaInputProps> = ({
  data,
  setValue,
  shouldFocus,
}: AmsaInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && shouldFocus) {
      inputRef.current.focus();
    }
  }, [shouldFocus]);
  return (
    <div className="block">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {data.label}
      </label>
      <div className="relative mt-2">
        <input
          id={`key-${data.key}`}
          autoFocus
          ref={inputRef}
          type="text"
          onChange={setValue}
          value={data.value ?? " "}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      {data.selectedUnit && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none top-6">
          <span className="text-gray-500 sm:text-sm" id="unit">
            {data.selectedUnit}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(AmsaInput);
