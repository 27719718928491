import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  UpdateFieldPayload,
  UpdateHistoryData,
  changeHistoryData,
  changePatient,
  changePatientData,
  selectPatient,
} from "../../features/patient/patientSlice";
import { HistoryData, Patient } from "../../interfaces/Patient";
import { getDisorders, saveDisorder } from "../../apis/disorder";
import { Disorder } from "../../interfaces/Disorder";
import { useParams } from "react-router-dom";
import { getPatient } from "../../apis/patient";
import { LanguageContext } from "../../providers/LanguageProvider";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import MultiSelect from "../../components/MultiSelect";
import { Option } from "../../interfaces/MultiSelectTypes";

type DisorderType = "allergy" | "disease" | "surgery";
type HistoryField = "allergies" | "previousDiseases" | "previousSurgeries";

const AdvanceProfile: React.FC<{ errors: Patient | null }> = ({ errors }) => {
  const { translate: t } = useContext(LanguageContext);
  const hasId = (item: Disorder): item is Required<Disorder> =>
    item.id !== undefined;

  const patient = useAppSelector(selectPatient);
  const [allergies, setAllergies] = useState<Array<Disorder>>([]);
  const [surgeries, setSurgeries] = useState<Array<Disorder>>([]);
  const [diseases, setDiseases] = useState<Array<Disorder>>([]);
  const [allergySearch, setAllergySearch] = useState<string>("");
  const [diseaseSearch, setDiseaseSearch] = useState<string>("");
  const [surgerySearch, setSurgerySearch] = useState<string>("");

  const [isAllergyDropdownOpen, setIsAllergyDropdownOpen] = useState(false);
  const [isDiseaseDropdownOpen, setIsDiseaseDropdownOpen] = useState(false);
  const [isSurgeryDropdownOpen, setIsSurgeryDropdownOpen] = useState(false);

  const dispatch = useAppDispatch();
  const params = useParams();

  const [medicinesEditorState, setMedicinesEditorState] = useState(
    EditorState?.createEmpty()
  );
  const [familyHistoryEditorState, setFamilyHistoryEditorState] = useState(
    EditorState?.createEmpty()
  );

  useEffect(() => {
    if (params && params.patientId) {
      getPatient(params.patientId).then((res: any) => {
        const response = res.data.data;
        if (response?.historyData?.ongoingMedicines) {
          convertTextToEditor(
            response?.historyData?.ongoingMedicines,
            setMedicinesEditorState
          );
        }
        if (response?.historyData?.familyHistory) {
          convertTextToEditor(
            response?.historyData?.familyHistory,
            setFamilyHistoryEditorState
          );
        }
        dispatch(changePatient(res.data.data));
      });
    }
    fetchDisorders();
  }, [dispatch, params]);

  const updateField = (
    fieldName: keyof Patient,
    value: string | number | boolean
  ) => {
    const payload: UpdateFieldPayload = { fieldName, value };
    dispatch(changePatientData(payload));
  };

  const updateHistoryField = (
    fieldName: keyof HistoryData,
    value: string | null | Array<any>
  ) => {
    const payload: UpdateHistoryData = { fieldName, value };
    dispatch(changeHistoryData(payload));
  };

  const fetchDisorders = () => {
    getDisorders({ per_page: 500, type: "allergy" }).then((res: any) => {
      setAllergies(res.data.data);
    });
    getDisorders({ per_page: 500, type: "surgery" }).then((res: any) => {
      setSurgeries(res.data.data);
    });
    getDisorders({ per_page: 500, type: "disease" }).then((res: any) => {
      setDiseases(res.data.data);
    });
  };

  const toggleAllergyDropdown = () =>
    setIsAllergyDropdownOpen(!isAllergyDropdownOpen);
  const toggleDiseaseDropdown = () =>
    setIsDiseaseDropdownOpen(!isDiseaseDropdownOpen);
  const toggleSurgeryDropdown = () =>
    setIsSurgeryDropdownOpen(!isSurgeryDropdownOpen);

  const handleChange = (
    option: Option,
    field: HistoryField,
    selectedOptions: Array<Option> | undefined,
    updateHistoryField: (field: HistoryField, data: Array<Option>) => void
  ) => {
    let updatedOptions = selectedOptions || [];

    const [ifExists] = updatedOptions.filter(
      (i) => i.value === option.value.toString()
    );

    if (!ifExists) {
      updatedOptions = [
        ...updatedOptions,
        { label: option.label, value: option.value },
      ];
    }

    updateHistoryField(field, updatedOptions);
  };

  const addNewDisorder = async (
    name: string,
    type: string,
    historyDataKey: keyof HistoryData
  ) => {
    const {
      data: { data: disorder },
    } = await saveDisorder({ name, type });
    let existingData: Array<Option> = (patient.historyData as any)[
      historyDataKey
    ];
    await fetchDisorders();
    if (historyDataKey && existingData) {
      updateHistoryField(historyDataKey, [
        ...existingData,
        { label: disorder.name, value: disorder.id },
      ]);
    }
    existingData = [];
  };

  const onEditorStateChange = (
    editorState: EditorState,
    field: keyof HistoryData,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    setState(editorState);
    const contentState = editorState.getCurrentContent();
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    updateHistoryField(field, contentStateString);
  };

  const convertTextToEditor = (
    text: any,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    if (text) {
      const contentState = convertFromRaw(JSON.parse(text));
      const initialEditorState = EditorState?.createWithContent(contentState);
      setState(initialEditorState);
    }
  };

  const searchDisorders = async (
    type: DisorderType,
    keyword: string,
    setSearch: React.Dispatch<React.SetStateAction<string>>,
    setData: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    setSearch(keyword);
    const {
      data: { data: disordersData },
    } = await getDisorders({ type, name: keyword });
    setData(disordersData);
  };

  const handleAllergyRemoval = (option: Option) => {
    const updatedAllergies = patient.historyData.allergies.filter(
      (selected: Option) => selected.value !== option.value
    );
    updateHistoryField("allergies", updatedAllergies);
  };

  const handleDiseaseRemoval = (option: Option) => {
    const updatedDiseases = patient.historyData.previousDiseases.filter(
      (selected: Option) => selected.value !== option.value
    );
    updateHistoryField("previousDiseases", updatedDiseases);
  };
  const handleSurgeryRemoval = (option: Option) => {
    const updatedSurgeries = patient.historyData.previousSurgeries.filter(
      (selected: Option) => selected.value !== option.value
    );
    updateHistoryField("previousSurgeries", updatedSurgeries);
  };

  return (
    <div>
      <div className="bg-white">
        <div className="grid w-full gap-4 p-4 lg:grid-cols-2">
          <div className="block">
            <fieldset className="">
              <span className="text-sm font-medium text-gray-700">
                {t("patient_habits")}
              </span>
              <div className="grid grid-cols-2">
                <div className="block">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-6 ">
                      <input
                        id="is-alcoholic"
                        type="checkbox"
                        checked={patient.isAlcoholic}
                        onChange={(e) =>
                          updateField("isAlcoholic", e.target.checked)
                        }
                        className="w-4 h-4 border-gray-300 rounded cursor-pointer text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="is-alcoholic"
                        className="font-medium text-gray-900 cursor-pointer"
                      >
                        {t("alcohol")}
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2">
                    <div className="flex items-center h-6">
                      <input
                        id="bp"
                        type="checkbox"
                        checked={patient.isBloodPressure}
                        onChange={(e) =>
                          updateField("isBloodPressure", e.target.checked)
                        }
                        className="w-4 h-4 border-gray-300 rounded cursor-pointer text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="bp"
                        className="font-medium text-gray-900 cursor-pointer"
                      >
                        {t("blood_pressure")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="block">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-6">
                      <input
                        id="smoking"
                        type="checkbox"
                        checked={patient.isSmoking}
                        onChange={(e) =>
                          updateField("isSmoking", e.target.checked)
                        }
                        className="w-4 h-4 border-gray-300 rounded cursor-pointer text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="smoking"
                        className="font-medium text-gray-900 cursor-pointer"
                      >
                        {t("smoking")}
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2">
                    <div className="flex items-center h-6">
                      <input
                        id="diabetic"
                        type="checkbox"
                        checked={patient.isDiabetic}
                        onChange={(e) =>
                          updateField("isDiabetic", !patient.isDiabetic)
                        }
                        className="w-4 h-4 border-gray-300 rounded cursor-pointer text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="diabetic"
                        className="font-medium text-gray-900 cursor-pointer"
                      >
                        {t("diabetic")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("previous_surgeries")}
            </span>
            <MultiSelect
              placeholder="Select Surgeries"
              selectedOptions={patient.historyData.previousSurgeries}
              options={surgeries
                .filter(hasId)
                .map((i) => ({ label: i.name, value: i.id?.toString() }))}
              isOpen={isSurgeryDropdownOpen}
              searchTerm={surgerySearch}
              toggleDropdown={toggleSurgeryDropdown}
              onOptionRemove={handleSurgeryRemoval}
              onOptionSelect={(surgery) => {
                handleChange(
                  surgery,
                  "previousSurgeries",
                  patient.historyData.previousSurgeries,
                  updateHistoryField
                );
              }}
              onAddNewOption={(value) => {
                addNewDisorder(value, "surgery", "previousSurgeries");
              }}
              onSearch={(keyword) => {
                searchDisorders(
                  "surgery",
                  keyword,
                  setSurgerySearch,
                  setSurgeries
                );
              }}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("allergies")}
            </span>
            <MultiSelect
              placeholder="Select Allergies"
              selectedOptions={patient.historyData.allergies}
              options={allergies
                .filter(hasId)
                .map((i) => ({ label: i.name, value: i.id?.toString() }))}
              isOpen={isAllergyDropdownOpen}
              searchTerm={allergySearch}
              toggleDropdown={toggleAllergyDropdown}
              onOptionRemove={handleAllergyRemoval}
              onOptionSelect={(allergy: Option) =>
                handleChange(
                  allergy,
                  "allergies",
                  patient.historyData.allergies,
                  updateHistoryField
                )
              }
              onAddNewOption={(value) => {
                addNewDisorder(value, "allergy", "allergies");
              }}
              onSearch={(keyword) => {
                searchDisorders(
                  "allergy",
                  keyword,
                  setAllergySearch,
                  setAllergies
                );
              }}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("previous_disease")}
            </span>

            <MultiSelect
              placeholder="Select Diseases"
              selectedOptions={patient.historyData.previousDiseases}
              options={diseases
                .filter(hasId)
                .map((i) => ({ label: i.name, value: i.id?.toString() }))}
              isOpen={isDiseaseDropdownOpen}
              searchTerm={diseaseSearch}
              toggleDropdown={toggleDiseaseDropdown}
              onOptionRemove={handleDiseaseRemoval}
              onOptionSelect={(disease: Option) =>
                handleChange(
                  disease,
                  "previousDiseases",
                  patient.historyData.previousDiseases,
                  updateHistoryField
                )
              }
              onAddNewOption={(value) => {
                addNewDisorder(value, "disease", "previousDiseases");
              }}
              onSearch={(keyword) => {
                searchDisorders(
                  "disease",
                  keyword,
                  setDiseaseSearch,
                  setDiseases
                );
              }}
            />
          </div>
          <div className="block">
            <label
              htmlFor="comment"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("ongoing_medicines")}
            </label>
            <div className="mt-2">
              <Editor
                editorState={medicinesEditorState}
                toolbarClassName="border border-gray-300 rounded-md"
                wrapperClassName="h-auto"
                editorClassName="h-full border border-gray-300 rounded-md p-2 "
                onEditorStateChange={(editorState) =>
                  onEditorStateChange(
                    editorState,
                    "ongoingMedicines",
                    setMedicinesEditorState
                  )
                }
                toolbar={{
                  options: ["inline", "list", "blockType"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
              />
            </div>
          </div>
          <div className="block">
            <label
              htmlFor="comment"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t("family_history")}
            </label>
            <div className="mt-2">
              <Editor
                editorState={familyHistoryEditorState}
                toolbarClassName="border border-gray-300 rounded-md"
                wrapperClassName="h-auto"
                editorClassName="h-full border border-gray-300 rounded-md p-2 "
                onEditorStateChange={(editorState) =>
                  onEditorStateChange(
                    editorState,
                    "familyHistory",
                    setFamilyHistoryEditorState
                  )
                }
                toolbar={{
                  options: ["inline", "list", "blockType"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceProfile;
