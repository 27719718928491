import { format, getDate, getHours } from "date-fns";
import React, { useState } from "react";
import { Event } from "../../interfaces/Event";
import { classNames } from "../../utils/CssUtil";
import { getFormattedHours } from "../../utils/DateUtil";

type WeekViewProps = {
  weekDays: Array<Date>;
  events: Array<Event>;
  startDateOfWeek: number;
};

const WeekView: React.FC<WeekViewProps> = ({
  weekDays,
  events,
  startDateOfWeek,
}) => {
  const [hourOffset] = useState(10);
  const [weekViewHours] = useState(getFormattedHours(10, 20));

  return (
    <div
      style={{ width: "165%" }}
      className="flex flex-col flex-none max-w-full sm:max-w-none md:max-w-full"
    >
      <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
        <div className="grid-cols-7 -mr-px text-sm leading-6 text-gray-500 border-r border-gray-100 divide-x divide-gray-100 sm:grid">
          <div className="col-end-1 w-14"></div>
          {weekDays &&
            weekDays.map((day: any, i: number) => (
              <div key={i} className="flex items-center justify-center py-3">
                <span>
                  {format(day.date, "E")}

                  <span className="items-center justify-center font-semibold text-gray-900">
                    {" " + format(day.date, "dd")}
                  </span>
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="flex flex-auto">
        <div className="sticky left-0 z-10 flex-none bg-white w-14 ring-1 ring-gray-100"></div>
        <div className="grid flex-auto grid-cols-1 grid-rows-1">
          {/* <!-- Horizontal lines --> */}
          <div
            className="grid col-start-1 col-end-2 row-start-1 divide-y divide-gray-100"
            style={{
              gridTemplateRows: "repeat(48, minmax(3.5rem, 1fr))",
            }}
          >
            <div className="row-end-1 h-7"></div>
            {weekViewHours &&
              weekViewHours.length > 0 &&
              weekViewHours.map((item, index) => (
                <React.Fragment key={index}>
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      {item}
                    </div>
                  </div>
                  <div></div>
                </React.Fragment>
              ))}
          </div>

          {/* <!-- Vertical lines --> */}
          <div className="hidden grid-cols-7 col-start-1 col-end-2 grid-rows-1 row-start-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
            <div className="col-start-1 row-span-full"></div>
            <div className="col-start-2 row-span-full"></div>
            <div className="col-start-3 row-span-full"></div>
            <div className="col-start-4 row-span-full"></div>
            <div className="col-start-5 row-span-full"></div>
            <div className="col-start-6 row-span-full"></div>
            <div className="col-start-7 row-span-full"></div>
            <div className="w-8 col-start-8 row-span-full"></div>
          </div>

          {/* Events */}
          <ol
            className="grid grid-cols-1 col-start-1 col-end-2 row-start-1 sm:grid-cols-7 sm:pr-8"
            style={{
              gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
            }}
          >
            {events &&
              events.length > 0 &&
              events.map((event: Event, index: number) => (
                <li
                  key={index}
                  className={classNames("relative flex mt-px ")}
                  style={{
                    gridRow: `${
                      (getHours(new Date(event.startDate)) - hourOffset) * 12 +
                      2
                    } / span 12`,
                    gridColumnStart:
                      getDate(new Date(event.startDate)) -
                      getDate(startDateOfWeek) +
                      1,
                  }}
                >
                  <button className="absolute flex flex-col p-2 overflow-y-auto text-xs leading-5 rounded-lg group inset-1 bg-blue-50 hover:bg-blue-100">
                    <p className="order-1 font-semibold text-blue-700">
                      {format(new Date(event.startDate), "dd hh:mm a")}
                    </p>
                    <p className="order-2 font-semibold text-blue-700">
                      {event.description}
                    </p>
                    <p className="text-blue-500 group-hover:text-blue-700">
                      <span>{event.title}</span>
                    </p>
                  </button>
                </li>
              ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default WeekView;
