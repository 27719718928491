import React from "react";
import { Event } from "../../interfaces/Event";
import { format } from "date-fns";

const EventDetails: React.FC<{
  event: Event | null;
  closeModal: () => void;
}> = ({ event, closeModal }) => {
  return (
    <div className="">
      <span className="font-medium"> Event Details</span>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Title
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {event?.title}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Start Time
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {event
                ? format(new Date(event.startDate), "yyyy-MM-dd hh:mm a")
                : ""}
            </dd>
          </div>
          {event && event.endDate && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                End Time
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {format(new Date(event?.endDate), "yyyy-MM-dd hh:mm a")}
              </dd>
            </div>
          )}
        </dl>
      </div>
      <div className="flex items-center justify-end w-full py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => closeModal()}
          type="button"
          className="px-3 py-2 text-sm font-semibold text-gray-900 border rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default EventDetails;
