import React from "react";
const Board = () => {
  return (
    <div className="flex items-center justify-center p-48">
      <div className="grid grid-cols-3">
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">X</span>
        </div>
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">O</span>
        </div>
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">X</span>
        </div>
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">X</span>
        </div>
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">X</span>
        </div>
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">O</span>
        </div>
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">O</span>
        </div>
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">X</span>
        </div>
        <div className="flex justify-center w-32 h-32 p-2 border-4 ">
          <span className="font-bold text-8xl">O</span>
        </div>
      </div>
    </div>
  );
};

export default Board;
