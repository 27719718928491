import React, { useContext, useEffect, useState } from 'react';
import { getCompany, saveCompany, updateCompany } from '../../apis/company';
import { useNotification } from '../../NotificationContext';
import { LanguageContext } from '../../providers/LanguageProvider';
const AddCompany: React.FC<{
  closeModal: () => void;
  editableId: any;
}> = ({ closeModal, editableId }) => {
  const defaultCompany = {
    name: '',
    code: '',
  };
  const [company, setCompany] = useState(defaultCompany);
  const [errors, setErrors] = useState<any>();
  const { showNotification } = useNotification();
  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    if (editableId) {
      fetchCompany(editableId);
    }
  }, [editableId]);

  const fetchCompany = (id: number) => {
    getCompany(id).then((res: any) => {
      setCompany(res.data);
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (company?.name === '') {
      validationErrors.name = '* Name is required';
    }
    if (company?.code === '') {
      validationErrors.molecule = '* Code is required';
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const addCompany = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveCompany({
      ...company,
    }).then((res: any) => {
      showNotification('success', 'Company Added successfully', '', 'topRight');
      closeModal();
    });
  };

  const updateCompanyData = () => {
    updateCompany({
      id: editableId,
      ...company,
    }).then((res) => {
      showNotification(
        'success',
        'Company Updated successfully',
        '',
        'topRight'
      );
      closeModal();
    });
  };

  const onSave = () => {
    if (editableId) {
      updateCompanyData();
    } else {
      addCompany();
    }
  };

  return (
    <div className=''>
      <span className='font-medium'>
        {editableId ? t('update') : t('add')} {t('company')}
      </span>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('name')}
        </label>
        <div className='mt-1'>
          <input
            id='name'
            name='name'
            type='text'
            value={company?.name}
            onChange={(e) => setCompany({ ...company, name: e.target.value })}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.name && (
          <span className='text-sm text-red-500 leading-0'>{errors.name}</span>
        )}
      </div>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('code')}
        </label>
        <div className='mt-1'>
          <input
            id='code'
            name='code'
            type='text'
            value={company?.code}
            onChange={(e) => setCompany({ ...company, code: e.target.value })}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.molecule && (
          <span className='text-sm text-red-500 leading-0'>
            {errors.molecule}
          </span>
        )}
      </div>

      <div className='flex items-center justify-start py-4 gap-x-6 border-gray-900/10 '>
        <button
          onClick={() => onSave()}
          type='submit'
          className='px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark'
        >
          {editableId ? t('update') : t('save')}
        </button>
        <button
          onClick={() => closeModal()}
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
        >
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};

export default AddCompany;
