import api from "../middlware/api";
import { BASE_URL } from "../constants";
import { camelToSnake } from "../utils/CamelToSnake";

export const getExpenseCategories = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/expense-categories?${query.toString()}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveExpenseCategory = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/expense-categories`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getExpenseCategory = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/expense-categories/${payload}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateExpenseCategory = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .put(
        `${BASE_URL}/api/v1/expense-categories/${payload.id}`,
        camelToSnake(payload)
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const exportExpenseCategories = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/export/expense-categories?${query.toString()}`,{
        headers:{
          "Content-Type":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType:"blob"
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};