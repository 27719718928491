import React from "react";

const LoaderComponent: React.FC<{ message?: string }> = ({
  message = "Loading ...",
}) => {
  return (
    <div className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:p-6">
      <div className="flex flex-col items-center justify-center space-y-4">
        <img
          src="/order.png"
          alt="Loading..."
          className="w-20 h-20 spin-vertically"
        />
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {message}
          </h2>
        </div>
      </div>
    </div>
  );
};
export default LoaderComponent;
