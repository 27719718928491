import { BASE_URL } from "../constants";
import api from "../middlware/api";
import { camelToSnake } from "../utils/CamelToSnake";

export const getServices = (params: any) => {
  let query = new URLSearchParams();
  for (let key in params) {
    query.set(key, params[key]);
  }
  return api.get(`${BASE_URL}/api/v1/services?${query.toString()}`);
};

export const saveService = (payload: any) => {
  return api.post(`${BASE_URL}/api/v1/services`, camelToSnake(payload));
};

export const updateService = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .put(`${BASE_URL}/api/v1/services/${payload.id}`, camelToSnake(payload))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getService = (payload: any) => {

  return api.get(`${BASE_URL}/api/v1/services/${payload}`)


};

export const exportServices = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/export/services?${query.toString()}`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
