import React, { useContext, useEffect, useState } from "react";
import {
  getTranslation,
  saveTranslation,
  updateTranslation,
} from "../../apis/auth";
import { useNotification } from "../../NotificationContext";
import { LanguageContext } from "../../providers/LanguageProvider";
const AddTranslation: React.FC<{
  closeModal: () => void;
  editableId: any;
  language: any;
}> = ({ closeModal, editableId, language }) => {
  const deafaultTranslation = {
    key: "",
    value: "",
  };
  const [translation, setTranslation] = useState(deafaultTranslation);
  const { showNotification } = useNotification();
  const { translate: t } = useContext(LanguageContext);

  useEffect(()=>{
    if (editableId)
    {
      fetchTranslation(editableId);
    }
  }, [editableId]);

  const createTranslation = () => {
    saveTranslation({
      key: translation.key,
      value: translation.value,
      locale: language,
    }).then((res: any) => {
      showNotification(
        "success",
        "Translation Added successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const fetchTranslation =(id:any)=>{
    getTranslation(id).then((res:any)=>{
      setTranslation(res.data);
    });
  };

  const updateTranslationsData = () => {
    updateTranslation({
      ...translation,
      id: editableId,
    }).then((res) => {
      showNotification(
        "success",
        "Translation Updated successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const onSave = () => {
    if (editableId) {
      updateTranslationsData();
    } else {
      createTranslation();
    }
  };

  return (
    <div className="">
      <span className="font-medium">
        {editableId ? t("update_translation") : t("add_translation")} 
        {/* Update Translation */}
      </span>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {"key"}
        </label>
        <div className="mt-1">
          <input
            id="name"
            name="name"
            type="text"
            value={translation?.key}
            disabled={editableId}
            onChange={(e) =>
              setTranslation({ ...translation, key: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {"value"}
        </label>
        <div className="mt-1">
          <input
            id="code"
            name="code"
            type="text"
            value={translation?.value}
            onChange={(e) =>
              setTranslation({ ...translation, value: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => onSave()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {editableId ? t("update") : t("save")}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};

export default AddTranslation;
