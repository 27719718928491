import React, { useContext, useState } from "react";
import { LanguageContext } from "../providers/LanguageProvider";
import { useNotification } from "../NotificationContext";
import { saveReferredDoctors } from "../apis/referredDoctor";

const AddReferredDoctor: React.FC<{
  closeModal: () => void;
}> = ({ closeModal }) => {
  const defaultDoctors = {
    name: "",
    phone: "",
    address: "",
    degree: "",
  };
  const [referredDoctor, setReferredDoctor] = useState(defaultDoctors);
  const [errors, setErrors] = useState<any>();
  const { showNotification } = useNotification();

  const { translate: t } = useContext(LanguageContext);

  const validateForm = () => {
    const validationErrors: any = {};
    if (referredDoctor?.name === "") {
      validationErrors.name = "* name is required";
    }
    if (referredDoctor?.phone === "") {
      validationErrors.phone = "* phone is required";
    }else if (referredDoctor.phone && referredDoctor.phone.length !== 10) {
      validationErrors.phone = "Phone number should be 10 digits";
    }

    if (referredDoctor?.address === "") {
      validationErrors.address = "* address is required";
    }
    if (referredDoctor?.degree === null) {
      validationErrors.degree = "* degree is required";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const AddReferredDoctor = (params:any) => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveReferredDoctors(params).then((res: any) => {
      showNotification(
        "success",
        "Referred doctor Added successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const setName = (name: string) => {
    setReferredDoctor({
      ...referredDoctor,
      name,
    });
  };

  const setPhone = (phone: string) => {
    setReferredDoctor({
      ...referredDoctor,
      phone,
    });
  };

  const setAddress = (address: string) => {
    setReferredDoctor({
      ...referredDoctor,
      address,
    });
  };

  const setDegree = (degree: string) => {
    setReferredDoctor({
      ...referredDoctor,
      degree,
    });
  };

  return (
    <div className="">
      <span className="font-medium">
        {t("add")} {t("referred_doctor")}
      </span>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("name")}
        </label>
        <div className="mt-1">
          <input
            id="name"
            name="name"
            type="text"
            value={referredDoctor?.name}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[a-zA-Z]*$/.test(value)) {
                setName(e.target.value)
              }
            }}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.name && (
          <span className="text-sm text-red-500 leading-0">{errors.name}</span>
        )}
      </div>

      

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("phone")}
        </label>
        <div className="mt-1">
          <input
            id="phone"
            name="phone"
            type="text"
            value={referredDoctor?.phone}
            maxLength={10}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\+?[0-9]*$/.test(value)) {
                setPhone(e.target.value)
              }
            }}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.phone && (
          <span className="text-sm text-red-500 leading-0">{errors.phone}</span>
        )}
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("address")}
        </label>
        <div className="mt-1">
          <input
            id="address"
            name="address"
            type="text"
            value={referredDoctor?.address}
            onChange={(e) => setAddress(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.address && (
          <span className="text-sm text-red-500 leading-0">{errors.address}</span>
        )}
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("degree")}
        </label>
        <div className="mt-1">
          <input
            id="degree"
            name="degree"
            type="text"
            value={referredDoctor?.degree}
            onChange={(e) => setDegree(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.degree && (
          <span className="text-sm text-red-500 leading-0">{errors.degree}</span>
        )}
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => AddReferredDoctor(referredDoctor)}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {t("save")}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};

export default AddReferredDoctor;
