export const validatePhone = (phone: string): boolean => {
  // Add your phone validation logic here
  // Return true if the phone is valid, false otherwise
  return phone.length === 10; // Example: Phone length should be 10 digits
};

export const validateEmail = (email: string): boolean => {
  // Add your email validation logic here
  // Return true if the email is valid, false otherwise
  const emailRegex = /^\S+@\S+\.\S+$/;
  return emailRegex.test(email); // Example: Validate using a regular expression
};

export const validateGender = (gender: string): boolean => {
  // Add your gender validation logic here
  // Return true if the gender is valid, false otherwise
  return gender === "male" || gender === "female" || gender === "other"; // Example: Accept only "Male" or "Female"
};
