const Instructions = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full space-y-2">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-6 h-6 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
          />
        </svg>
      </span>
      <span className="font-bold">Get Started with the form customization</span>
      <span className="text-sm">
        Or Drag & Drop items from the left to this area to start building
      </span>
      <div className="flex"></div>
    </div>
  );
};

export default Instructions;
