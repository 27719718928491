import { useEffect, useState } from "react";
import { useNotification } from "../NotificationContext";

const SetNewPassword: React.FC<{
  title: string;
  onSubmit: (confirmPassword: string) => void;
}> = ({ title, onSubmit }) => {
  const [password, setNewPassword] = useState("");
  const [passwordLengthError, setPasswordLengthError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const { showNotification } = useNotification();
  const [passwordVisible, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisibility] =
    useState(false);

  useEffect(() => {
    validateConfirmPassword();
  }, [confirmPassword]);

  useEffect(() => {
    validatePassword();
  }, [password]);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(!confirmPasswordVisible);
  };

  const validatePassword = () => {
    if (password.length < 6 || password.length > 12) {
      setIsValidPassword(false);

      setPasswordLengthError(
        "Password should contains atleast 6 characters and max 12 characters."
      );
    } else {
      setPasswordLengthError("");
    }
  };

  const validateConfirmPassword = () => {
    if (confirmPassword !== password) {
      setIsValidPassword(false);

      setConfirmPasswordError("Password does not matched.");
    } else {
      setIsValidPassword(true);
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = () => {
    if (isValidPassword && confirmPassword.length > 0) {
      onSubmit(confirmPassword);
    } else {
      showNotification("error", "Incorrect Password", "", "topRight");
    }
  };

  return (
    <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg font-medium leading-6 text-gray-900"
            id="modal-title"
          >
            {title}
          </h3>
          <div className="block">
            <label className="block my-2 text-sm font-medium text-left text-gray-700 sm:mt-px sm:pt-2">
              Enter New Password
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="relative flex max-w-lg rounded-md shadow-sm">
                <input
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={password}
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  id="password"
                  max={50}
                  autoComplete="password"
                  className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                ></input>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility()}
                    className="cursor-pointer"
                  >
                    {passwordVisible ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <div>
                {passwordLengthError.length > 0 && (
                  <p className="pt-2 text-sm text-left text-red-500">
                    {passwordLengthError}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="block">
            <label className="block my-2 text-sm font-medium text-left text-gray-700 sm:mt-px sm:pt-2">
              Confirm Password
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="relative flex max-w-lg rounded-md shadow-sm">
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  name="confirmPassword"
                  id="confirmPassword"
                  max={50}
                  type={confirmPasswordVisible ? "text" : "password"}
                  autoComplete="confirmPassword"
                  className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                ></input>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button
                    type="button"
                    onClick={() => toggleConfirmPasswordVisibility()}
                    className="cursor-pointer"
                  >
                    {confirmPasswordVisible ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <div>
                {confirmPasswordError.length > 0 && (
                  <p className="pt-2 text-sm text-left text-red-500">
                    {confirmPasswordError}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 ">
        <button
          onClick={() => handleSubmit()}
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
        >
          Submit
        </button>
      </div>
    </div>
  );
};
export default SetNewPassword;
