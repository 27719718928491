export const physiotherapyData = [
  [
    {
      label: "",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 3,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Name:",
      isClickable: false,
      isInput: true,
      colspan: 5,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Birth Date:",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Occupation:",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Surname",
      isClickable: false,
      isInput: true,
      colspan: 5,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Sports:",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Segments",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Location",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Side",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Durat (acu-1)",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "T/?",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "VRS (n-M)",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Rec/con",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Painful Movements:",
      isClickable: false,
      isInput: false,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Si Pa",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",

      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",

      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",

      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",

      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",

      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",

      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",

      isVerticalText: false,
    },
  ],
  [
    {
      label: "Pa Conc",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",

      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      alignment: "",
      isVerticalText: false,

      content: "",
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      alignment: "",

      content: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      alignment: "",

      content: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      alignment: "",

      content: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      alignment: "",

      content: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      alignment: "",

      content: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      alignment: "",

      content: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Extremit.",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 3,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "CP",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "DI",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "PE",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Pa Prev",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Investigations:",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Surgery",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Medications: ",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Trauma",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Internal dysf.: ",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Fracture",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Posture: ",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Move",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 4,
      content: "",
      alignment: "text-center",
      isVerticalText: true,
    },
    {
      label: "SEGMENTS",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
    {
      label: "(lt) ANTE (rt)",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
    {
      label: "(lt) RETRO (rt)",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
    {
      label: "(lt) MEDIO (rt)",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
    {
      label: "(lt) LATERO (rt)",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
    {
      label: "(lt) INTRA (rt)",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
    {
      label: "(lt) EXTRA (rt)",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: " ",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "HYPOTHESIS",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 6,
      content: "",
      alignment: "text-center",
      isVerticalText: true,
    },
    {
      label: "SOMATO-SOMATO",
      isClickable: false,
      isInput: false,
      colspan: 3,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "SOMATO-VISCERAL",
      isClickable: false,
      isInput: false,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "VISCERAL-SOMATO",
      isClickable: false,
      isInput: false,
      colspan: 3,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "VISCERAL-VISCERAL",
      isClickable: false,
      isInput: false,
      colspan: 3,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "TS-CP",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "TS-CL",
      isClickable: false,
      isInput: false,
      colspan: 3,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "TS-TH",
      isClickable: false,
      isInput: false,
      colspan: 3,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "TS-LU",
      isClickable: false,
      isInput: false,
      colspan: 3,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "TS-PV",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "ACI",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "AUN",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "ADI",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "ARE",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "AEN",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "AHE",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "APR AMR ACR",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Vascular Sequence",
      isClickable: false,
      isInput: false,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Visceral Sequence",
      isClickable: false,
      isInput: false,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Glandular Sequence",
      isClickable: false,
      isInput: false,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Receptorial Sequence",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Cutaneous",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Thermoregulatory",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Lymphatic",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Immune",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Adipose",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Metabolic",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "STC",
      isClickable: false,
      isInput: false,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "SLI",
      isClickable: false,
      isInput: false,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "SAM",
      isClickable: false,
      isInput: false,
      colspan: 4,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "SPS",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "PALPATORY VERIFICATION",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 22,
      content: "",
      alignment: "text-center",
      isVerticalText: true,
    },
    {
      label: "CATENERIES",
      isClickable: false,
      isInput: false,
      colspan: 3,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "AP",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "lt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "rt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "LL",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "lt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "rt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "OB",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "lt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "rt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Control",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 4,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "Tensostructures",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 10,
      content: "",
      alignment: "text-center",
      isVerticalText: true,
    },
    {
      label: "CP",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "an-me-cp 1",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-cp 1",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },

    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-cp 1",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-cp 2",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-cp 2",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-cp 2",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "CL",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "an-me-cp 3",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-cp 3",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },

    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-cp 3",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-cl",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-cl",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-cl",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Trunk",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 6,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "TH",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
    },
    {
      label: "an-me-th 1",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-th 1",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },

    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-sc",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-th 2",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-th 2",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-th",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "LU",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "an-me-lu 1",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-lu 1",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },

    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-lu p",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-lu 2",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-lu 2",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-lu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "PV",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "text-center",
      isVerticalText: false,
    },
    {
      label: "an-me-pv 1",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-pv 1",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },

    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-pv p",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-pv 2",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-pv 2",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "ir-pv",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "AP OB (ir me)",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "lt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "rt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "LL (an)",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "lt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "rt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "AP (re)",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "lt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "rt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "LL OB",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "lt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "rt",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],

  [
    {
      label: "Pivots",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 2,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-me-sc",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-sc",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-sc",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-sc",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-hu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-hu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-hu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-hu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "tensors",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 3,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-me-cu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-cu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-cu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-cu",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-ca",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-ca",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-ca",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-ca",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-di",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-di",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-di",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-di",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "Pivots",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-me-cx",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-cx",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-cx",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-cx",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "tensors",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 3,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-me-ge",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-ge",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-ge",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-ge",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-ta",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-ta",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-ta",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-ta",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "an-me-pe",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "an-la-pe",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-me-pe",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "re-la-pe",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: true,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "POSTERIOR POINTS",
      isClickable: false,
      isInput: false,
      colspan: 2,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 10,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "Results at 8 d.",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "TREAT",
      isClickable: false,
      isInput: false,
      colspan: 1,
      rowspan: 3,
      content: "",
      isVerticalText: false,
      alignment: "text-center",
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 11,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 3,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 11,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
  [
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 1,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
    {
      label: "",
      isClickable: false,
      isInput: true,
      colspan: 11,
      rowspan: 1,
      content: "",
      alignment: "",
      isVerticalText: false,
    },
  ],
];
