import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AmsaInputProps } from "../../interfaces/AmsaInputs";

const AmsaEditor: React.FC<AmsaInputProps> = ({
  data,
  setValue,
}: AmsaInputProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (data.value) {
      const contentState = convertFromRaw(JSON.parse(data.value));
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, []);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    setValue(contentStateString);
  };

  return (
    <div className="block h-full">
      <span className="text-sm font-medium text-gray-700">{data.label}</span>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="h-64 overflow-hidden  border rounded-md"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ["inline", "list", "blockType"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
        }}
      />
    </div>
  );
};

export default AmsaEditor;
