import { configureStore } from "@reduxjs/toolkit";
import patientReducer from "./features/patient/patientSlice";
import counterReducer from "./features/counter/counterSlice";
import visitReducer from "./features/visit/visitSlice";
// ...

const store = configureStore({
  reducer: {
    patientState: patientReducer,
    visitState: visitReducer,
    counter: counterReducer,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
