import React, { useEffect, useState } from "react";
import MultiSelect from "../../components/MultiSelect";
import { Option } from "../../interfaces/MultiSelectTypes";
import { addSymptom, getSymptoms } from "../../apis/symptom";
import { Visit } from "../../interfaces/Visit";
import { updateField } from "../../utils/StateUtil";
import { useAppDispatch } from "../../hooks";
type SymptomSelectProps = {
  visit: Visit;
};
const SymptomSelect: React.FC<SymptomSelectProps> = ({ visit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [symptoms, setSymptoms] = useState<Array<Option>>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSymptoms();
  }, []);

  const handleSearch = (search: string) => {
    setSearchTerm(search);
    fetchSymptoms(search);
  };

  const fetchSymptoms = async (search: string = "") => {
    try {
      const {
        data: { data: response },
      } = await getSymptoms({ name: search });
      const symptomData = response.map((i: Record<string, number>) => {
        return {
          label: i.name,
          value: i.id,
        };
      });
      setSymptoms(symptomData);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSymptomSelect = (option: Option) => {
    if (!visit.symptoms.some((selected) => selected.value === option.value)) {
      updateField("symptoms", [...visit.symptoms, option], dispatch);
    }
  };

  const handleSymptomRemoval = (option: Option) => {
    const updatedSymptoms = visit.symptoms.filter(
      (selected) => selected.value !== option.value
    );
    updateField("symptoms", updatedSymptoms, dispatch);
  };

  const createNew = async (symptom: string) => {
    try {
      const { data } = await addSymptom({ name: symptom });
      updateField(
        "symptoms",
        [
          ...visit.symptoms,
          {
            label: data.name,
            value: data.id,
          },
        ],
        dispatch
      );
      setSearchTerm("");
      fetchSymptoms();
    } catch (error) {}
  };

  return (
    <div className="z-40 block">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Symptoms
      </label>
      <MultiSelect
        onAddNewOption={createNew}
        selectedOptions={visit.symptoms}
        options={symptoms}
        isOpen={isOpen}
        searchTerm={searchTerm}
        onSearch={handleSearch}
        onOptionSelect={handleSymptomSelect}
        onOptionRemove={handleSymptomRemoval}
        toggleDropdown={toggleDropdown}
      />
    </div>
  );
};

export default SymptomSelect;
