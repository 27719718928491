import api from "../middlware/api";
import { camelToSnake } from "../utils/CamelToSnake";
import { BASE_URL } from "../constants";

export const getMedicines = (params: any) => {
  let query = new URLSearchParams();
  for (let key in params) {
    query.set(key, params[key]);
  }
  return api.get(`${BASE_URL}/api/v1/medicines?${query.toString()}`);
};

export const saveMedicine = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/medicines`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMedicine = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/medicines/${payload}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateMedicine = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .put(`${BASE_URL}/api/v1/medicines/${payload.id}`, camelToSnake(payload))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const exportMedicines = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/export/medicines?${query.toString()}`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
