import React, { useContext, useEffect, useState } from "react";
import { Pagination, PaginationProps } from "antd";
import Filters from "../../components/Filters";
import { Filter } from "../../interfaces/Filter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getVisits } from "../../apis/visit";
import { Visit } from "../../interfaces/Visit";
import { LanguageContext } from "../../providers/LanguageProvider";
import { format } from "date-fns";
const VisitRegisterList = () => {
  const [meta, setMeta] = useState<any>();
  const [visits, setVisits] = useState<Array<Visit>>([]);
  const { translate: t } = useContext(LanguageContext);

  const navigate = useNavigate();
  const { search } = useLocation();

  const visitFilters: Array<Filter> = [
    {
      label: "Patient Name",
      key: "patient_name",
      value: "",
      component: "input",
    },
    {
      label: "Date",
      key: "date",
      value: "",
      component: "date",
    },
    {
      label: "Phone",
      key: "phone",
      value: "",
      component: "input",
    },
    {
      label: "Month",
      key: "month",
      value: "",
      component: "input",
    },
  ];

  const [filters] = useState<Array<Filter>>(visitFilters);
  const [isClearFilterList] = useState(false);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      fetchVisits({ per_page: 10, ...filters });
    } else {
      fetchVisits({ per_page: 10 });
    }
  }, [search]);

  const handleFilters = (params: any) => {
    if (params.length > 0) {
      let updatedQueryParams = new URLSearchParams();
      params.forEach((i: any) => {
        updatedQueryParams.set(i.key, i.value);
      });
      setSearchParams(updatedQueryParams.toString());
    } else {
      navigate(`/visit-register`);
    }
  };

  const fetchVisits = (params = {}) => {
    getVisits(params).then((res: any) => {
      setVisits(res.data);
      setMeta(res.meta);
    });
  };

  //use per_page
  const onPaginationChange: PaginationProps["onChange"] = (page, pageSize) => {
    fetchVisits({ page, per_page: pageSize });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* header for mobile screen */}
      <div className="flex lg:hidden grow">
        <div className="flex grow">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("visit_register_list")}
          </h1>
        </div>
        <div className="flex">
          <Filters
            clearFilterList={isClearFilterList}
            filters={filters}
            fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
          ></Filters>
        </div>
      </div>
      <div className="hidden lg:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("visit_register_list")}
          </h1>
        </div>
        <div className="mx-2 mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <div className="mr-2">
            <Filters
              clearFilterList={isClearFilterList}
              filters={filters}
              fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
            ></Filters>
          </div>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("date")}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("patient_name")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("phone")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("gender")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("visit_type")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {visits.map((visit) => (
                    <tr key={visit.id}>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {format(new Date(visit.date), "yyyy-MM-dd hh:mm aa")}
                      </td>

                      <td className="py-4 pr-3 text-sm font-medium text-gray-900 capitalize whitespace-nowrap">
                        {visit.patient?.firstName} {visit.patient?.lastName}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {visit.patient?.phone}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                        {visit.patient?.gender}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                        {visit?.clinicVisitType?.visitType.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end pt-5">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                total={meta?.total}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitRegisterList;
