import React, { useEffect, useState } from "react";
import { useNotification } from "../../NotificationContext";
import { Select } from "antd";
import {
  getTransaction,
  saveTransaction,
  updateTransaction,
} from "../../apis/payment";
import { useParams } from "react-router-dom";
const AddVisitTransaction: React.FC<{
  closeModal: () => void;
  editableId: any;
  visitId: any;
}> = ({ closeModal, editableId, visitId }) => {
  const defaultTransaction = {
    visitId: null,
    amount: "",
    paymentMode: "",
    note: "",
    date: "",
  };
  const [transaction, setTransaction] = useState(defaultTransaction);
  const [errors, setErrors] = useState<any>();
  const { showNotification } = useNotification();
  const params = useParams();

  const paymentModes = [
    {
      name: "Cash",
      value: "cash",
    },

    {
      name: "Cheque",
      value: "cheque",
    },

    {
      name: "Online-Transfer",
      value: "online-transfer",
    },

    {
      name: "UPI",
      value: "upi",
    },
  ];

  useEffect(() => {
    if (editableId) {
      fetchTransaction(editableId);
    }
  }, [editableId]);

  const fetchTransaction = (id: number) => {
    getTransaction(id).then((res: any) => {
      setTransaction(res.data.data);
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (transaction?.amount === "") {
      validationErrors.amount = "* amount is required";
    }
    if (transaction?.paymentMode === "") {
      validationErrors.paymentMode = "* Payment mode is required";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const addTransaction = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);

    saveTransaction({
      ...transaction,
      visitId: visitId ? visitId : params.visitId,
    }).then((res: any) => {
      showNotification(
        "success",
        "Transaction Added successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const updateTransactionData = () => {
    updateTransaction({
      ...transaction,
      id: editableId,
    }).then((res) => {
      showNotification(
        "success",
        "Transaction Updated successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const onSave = () => {
    if (editableId) {
      updateTransactionData();
    } else {
      addTransaction();
    }
  };

  const setPaymentMode = (paymentMode: string) => {
    setTransaction({
      ...transaction,
      paymentMode,
    });
  };

  return (
    <div className="">
      <span className="font-medium">
        {editableId ? "Update" : "Add"} Transaction
      </span>
      <div className="block mb-3">
        <span className="text-sm font-medium text-gray-700"> Date</span>

        <input
          type="date"
          id="date"
          name="date"
          value={transaction?.date}
          onChange={(e) =>
            setTransaction({ ...transaction, date: e.target.value })
          }
          className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
        />
        {errors && errors.date && (
          <span className="text-sm text-red-500 leading-0">{errors.date}</span>
        )}
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Amount
        </label>
        <div className="mt-1">
          <input
            id="amount"
            name="amount"
            type="number"
            value={transaction?.amount}
            onChange={(e) =>
              setTransaction({ ...transaction, amount: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.amount && (
          <span className="text-sm text-red-500 leading-0">
            {errors.amount}
          </span>
        )}
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Payment Mode
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={setPaymentMode}
            value={transaction.paymentMode}
            options={paymentModes}
          />
        </div>

        {errors && errors.paymentMode && (
          <span className="text-sm text-red-500 leading-0">
            {errors.paymentMode}
          </span>
        )}
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Payment Note
        </label>
        <div className="mt-1">
          <input
            id="paymentNote"
            name="paymentNote"
            type="text"
            value={transaction?.note}
            onChange={(e) =>
              setTransaction({ ...transaction, note: e.target.value })
            }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.paymentNote && (
          <span className="text-sm text-red-500 leading-0">
            {errors.paymentNote}
          </span>
        )}
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => onSave()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {editableId ? "Update" : "Save"}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddVisitTransaction;
