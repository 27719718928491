import { useEffect, useState } from "react";
import Modal from "../components/Modal";
import ChangePassword from "../components/ChangePassword";
import { getUser } from "../apis/user";
import { useParams } from "react-router-dom";

const Profile = () => {
  const [changePasswordModalOpen, setChangePasswordVisibility] =
    useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      fetchUser(userId);
    }
  }, [userId]);

  const handleCloseModal = () => {
    setChangePasswordVisibility(false);
  };

  const fetchUser = (id: any) => {
    getUser(id).then((res: any) => {
      setUser(res.data);
    });
  };
  return (
    <div className="px-4 py-5 bg-white border rounded-md md:px-8 md:py-8">
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          Your Profile
        </h3>
        <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">
          Personal details
        </p>
      </div>
      <div className="mt-6 border-t border-gray-200">
        <dl className="ml-6 divide-y divide-gray-200">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {user?.firstName + " " + user?.lastName}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Phone
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {user?.phone}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Email
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {user?.email}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Role
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {user?.role}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {user?.address}
            </dd>
          </div>
        </dl>
        <div
          className="px-4 py-6 border-t border-gray-200"
          onClick={() => setChangePasswordVisibility(true)}
        >
          <dt>
            <div className="pb-4 cursor-pointer">
              <span>Change Your Password</span>
              <p className="text-sm text-gray-500">
                Click here to change password .
              </p>
            </div>
          </dt>
        </div>
      </div>
      {changePasswordModalOpen && (
        <Modal
          modalContent={
            <ChangePassword closeModal={() => handleCloseModal()} />
          }
        />
      )}
    </div>
  );
};
export default Profile;
