import api from '../middlware/api';
import { BASE_URL } from '../constants';
import { camelToSnake } from '../utils/CamelToSnake';

export const getReferrals = (params: any) => {
    let query = new URLSearchParams();
    for (let key in params) {
        query.set(key, params[key]);
    }
    return new Promise((resolve, reject) => {

        api
            .get(`${BASE_URL}/api/v1/clinic-referrals?${query.toString()}`)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const addReferral = (payload: any) => {
    return new Promise((resolve, reject) => {
      api
        .post(`${BASE_URL}/api/v1/referrals`, camelToSnake(payload))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };