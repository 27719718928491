import React, { useContext, useEffect, useState } from "react";
import { useNotification } from "../../NotificationContext";
import { LanguageContext } from "../../providers/LanguageProvider";
import { saveSettings } from "../../apis/setting";

const AddSettings: React.FC<{
  closeModal: () => void;
}> = ({ closeModal }) => {
  const defaultSettings = {
    name: "",
    code: "",
    value: "-",
  };

  const [setting, setSettings] = useState(defaultSettings);
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<any>();

  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    setSettings(defaultSettings);
  }, []);

  const createSetting = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveSettings({
      name: setting.name,
      code: setting.code,
      value: setting.value,
    }).then(() => {
      showNotification("success", "Key Added successfully", "", "topRight");
      closeModal();
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (setting?.name === '') {
      validationErrors.name = '* Key is required';
    }

    if (setting?.value === '') {
      validationErrors.value = '* Value is required';
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  return (
    <div className="">
      <span className="font-medium">Add Settings</span>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {/* {t("name")} */}
          Key
        </label>
        <div className="mt-1">
          <input
            id="name"
            name="name"
            type="text"
            value={setting.name}
            onChange={(e) => setSettings({ ...setting, name: e.target.value })}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
           {errors && errors.name && (
          <span className='text-sm text-red-500 leading-0'>{errors.name}</span>
        )}
        </div>
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Value
        </label>
        <div className="mt-1">
          <input
            id="value"
            name="value"
            type="text"
            value={setting.value}
            onChange={(e) => setSettings({ ...setting, value: e.target.value })}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
           {errors && errors.value && (
          <span className='text-sm text-red-500 leading-0'>{errors.value}</span>
        )}
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => createSetting()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {t("save")}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};

export default AddSettings;
