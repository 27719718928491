import { Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getPatients } from "../../apis/patient";
import { getUsers } from "../../apis/user";
import {
  getClinicVisitTypes,
  getVisit,
  saveVisit,
  updateVisit,
} from "../../apis/visit";
import { Patient } from "../../interfaces/Patient";
import { User } from "../../interfaces/User";
import { useNotification } from "../../NotificationContext";
import { LanguageContext } from "../../providers/LanguageProvider";
import DatePicker from "react-datepicker";
import { getNextAppointmentTime } from "../../utils/DateUtil";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useNavigate } from "react-router-dom";

const AddAppointment: React.FC<{
  closeModal: () => void;
  patientId: any;
  userId?: number;
  existingVisitData?: any;
}> = ({ closeModal, patientId, userId, existingVisitData }) => {
  const appointmentWindow = parseInt(
    window.localStorage.getItem("appointmentWindow") ?? "15"
  );

  const [visit, setVisit] = useState({
    // date: getNextAppointmentTime(appointmentWindow),
    date: existingVisitData?.date || getNextAppointmentTime(appointmentWindow),
    isInpatient: true,
    patientId: "",
    parentId: null,
    clinicUserId: "",
    clinicVisitTypeId: 1,
    assessment: {},
    userId: existingVisitData?.userId || null,
    meta: {
      height: "",
      weight: "",
    },
    fee: 0,
    discount: 0,
    medicines: [],
    services: [],
    symptoms: [],
    provisionalDiagnosis: [],
    finalDiagnosis: [],
  });

  const { showNotification } = useNotification();
  const [patients, setPatients] = useState<Array<Patient>>([]);
  const [users, setUsers] = useState<Array<User>>([]);
  const Option = Select;
  const { translate: t } = useContext(LanguageContext);
  const [errors, setErrors] = useState<any>();
  const [clinicVisitTypes, setClinicVisitTypes] = useState([]);
  const [isPostponeVisit, setIsPostponeVisit] = useState(false);
  const [visitId, setVisitId] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (patientId) {
      fetchPatients({ id: patientId });
      setVisit({ ...visit, patientId });
    } else {
      fetchPatients({});
    }
    fetchUsers({ role: "doctor" });
    fetchClinicVisitTypes();
  }, []);

  const fetchPatients = (params: any) => {
    getPatients(params).then((res: any) => {
      setPatients(res.data.data);
    });
  };

  const fetchUsers = (params: any) => {
    getUsers(params).then((res: any) => {
      setUsers(res.data.data);
    });
  };

  const fetchClinicVisitTypes = async () => {
    await getClinicVisitTypes().then((res) => {
      setClinicVisitTypes(res.data);
      setVisit((prev) => ({
        ...prev,
        clinicVisitTypeId: res.data[0].id,
      }));
    });
  };

  const onSave = () => {
    console.log("in save",existingVisitData);
    
    if (existingVisitData && existingVisitData?.id) {
      rescheduleVisit();
    } else {
      createScheduleVisit();
    }
  };

  const createScheduleVisit = async () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);

    saveVisit({
      ...visit,
      date: new Date(visit.date).toISOString(),
      is_scheduled: true,
    })
      .then(() => {
        showNotification(
          "success",
          "Appointment Added successfully",
          "",
          "topRight"
        );
        closeModal();
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
        const message = err?.response?.data?.message;
        if (message) {
          if (message.includes("|")) {
            setErrors({ date: message.split("|")[0] });
            const existingVisitId = message.split("|")[1];
            setVisitId(existingVisitId);
            setIsPostponeVisit(true);
          }
        } else {
          showNotification(
            "error",
            "An error occurred while saving the visit",
            "",
            "topRight"
          );
        }
      });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    const now = new Date()
    
    if (!visit?.userId) {
      validationErrors.userId = "* doctor name is required";
    }
    if (visit?.patientId === "") {
      validationErrors.patient = "* patient name is required";
    }

    if (!visit?.date || isNaN(new Date(visit.date).getTime())) {
      validationErrors.date = "* date is required";
    }
    else {
      const visitDateTime = new Date(visit.date);
      
      if (visitDateTime < now) {
        validationErrors.date = "* date must be after current time";
      }
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const handlePatientChange = (patientId: any) => {
    setVisit({
      ...visit,
      patientId,
    });
  };

  const handleUserChange = (userId: any) => {
    setVisit({
      ...visit,
      userId,
    });
  };

  const handleDateChange = (value: Date | null) => {
    if (value) {
      setVisit({
        ...visit,
        date: value,
      });
    }
  };

  const handleClinicVisitTypeChange = (item: any) => {
    setVisit({
      ...visit,
      clinicVisitTypeId: item,
    });
  };

  const handlePostponeVisit = async () => {
    let serialNumber;
    await getVisit(visitId).then((res: any) => {
      serialNumber = res.serialNumber;
    });

    try {
      const res: any = await updateVisit({
        ...visit,
        serialNumber: serialNumber,
        date: new Date(visit.date).toISOString(),
        id: visitId,
      });
      showNotification("success", "Visit updated successfully", "", "topRight");
      closeModal();
    } catch (err: any) {
      const message =
        err?.data?.message || "An error occurred while updating the visit";
      showNotification("error", message, "", "topRight");
    }
  };

  const handleClosePostponeVisitModal = () => {
    setIsPostponeVisit(false);
  };

  const createNewVisit = async () => {
    saveVisit({
      ...visit,
      date: new Date(visit.date).toISOString(),
      is_scheduled: false,
    })
      .then((res: any) => {
        showNotification(
          "success",
          "Appointment Added successfully",
          "",
          "topRight"
        );
        closeModal();

        navigate("/visits");
      })
      .catch((err) => {});
  };

  const rescheduleVisit = async () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    await getVisit(existingVisitData.id).then((res: any) => {
      console.log({ res });
      userId = res.userId;
    });

    try {
      const res: any = await updateVisit({
        ...visit,
        date: new Date(visit.date).toISOString(),
        id: existingVisitData.id,
      });
      showNotification(
        "success",
        "Visit Reschedule successfully",
        "",
        "topRight"
      );
      closeModal();
    } catch (err: any) {
      const message =
        err?.data?.message || "An error occurred while updating the visit";
      showNotification("error", message, "", "topRight");
    }
  };

  return (
    <div className="">
      <span className="font-medium">{t("appointment")}</span>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("patient_name")}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handlePatientChange}
            value={visit.patientId}
            disabled={patientId ? true : false}
          >
            {patients.map((patient) => (
              <Option key={patient.id} value={patient.id}>
                {patient.firstName + " " + patient.lastName}
              </Option>
            ))}
          </Select>
          {errors && errors.patient && (
            <span className="text-sm text-red-500 leading-0">
              {errors.patient}
            </span>
          )}
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {"Doctor Name"}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handleUserChange}
            value={visit.userId}
            disabled={userId ? true : false}
          >
            {users.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.firstName + " " + user.lastName}
              </Option>
            ))}
          </Select>
          {errors && errors.userId && (
            <span className="text-sm text-red-500 leading-0">
              {errors.userId}
            </span>
          )}
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {"Visit Type"}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handleClinicVisitTypeChange}
            value={visit.clinicVisitTypeId}
          >
            {clinicVisitTypes.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
          {errors && errors.clinicUserId && (
            <span className="text-sm text-red-500 leading-0">
              {errors.clinicUserId}
            </span>
          )}
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("date")}
        </label>
        <div className="mt-1">
          <DatePicker
            selected={new Date(visit.date)}
            onChange={handleDateChange}
            portalId="root-panel"
            showTimeSelect={true}
            timeFormat="HH:mm"
            timeIntervals={appointmentWindow}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          {errors && errors.date && (
            <span className="text-sm text-red-500 leading-0">
              {errors.date}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => onSave()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {t("save")}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>

      {isPostponeVisit && (
        <ConfirmationModal
          message="You have already scheduled this visit with the same doctor on same date. Do you want to postpone this visit?"
          onClose={() => handleClosePostponeVisitModal()}
          onSubmit={() => {
            handlePostponeVisit();
            setIsPostponeVisit(false);
          }}
          okLabel="Yes"
          cancelLabel="Cancel"
          noLabel="No"
          onNo={() => {
            setIsPostponeVisit(false);
            createNewVisit();
          }}
        ></ConfirmationModal>
      )}
    </div>
  );
};

export default AddAppointment;
