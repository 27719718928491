import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import "./layout-design.scss";
import DynamicComponent from "./DynamicComponent";
import { Select } from "antd";
import { useNotification } from "../../NotificationContext";
import { LayoutComponent, Page, Vital } from "../../interfaces/LayoutComponent";
import {
  getLayout,
  getClinicVisitTypes,
  saveVisitTypeLayout,
} from "../../apis/visit";
import ConfirmationModal from "../../components/ConfirmationModal";
import { camelize } from "../../utils/SnakeToCamel";
import PageSettings from "./PageSettings";
import Diagnosis from "../visits/Diagnosis";
import Treatment from "../visits/Treatment";
import Payment from "../visits/Payment";
import { layoutComponents } from "../../utils/LayoutComponent";
import { classNames } from "../../utils/CssUtil";
import Instructions from "./Instructions";
import VitalList from "./VitalList";
import dragHandle from "../../assets/images/icons/dragHandle.svg";

import {
  createNewLayoutComponent,
  isComponentExist,
  removeComponent,
  updateGridItem,
  updateLayoutItem,
} from "../../utils/LayoutUtil";
import ComponentSettingsModal from "./ComponentSettingsModal";
import { setDefaultVisit } from "../../features/visit/visitSlice";
import { useAppDispatch } from "../../hooks";
import { format } from "date-fns";
import { Visit } from "../../interfaces/Visit";
const ResponsiveGridLayout = WidthProvider(Responsive);

const LayoutDesign: React.FC = () => {
  const { showNotification } = useNotification();
  const [currentStep, setCurrentStep] = useState(1);
  const [pages, setPages] = useState<Page[]>([
    {
      label: "Page 1",
      gridItems: [],
      id: "page",
      layout: [],
      type: "custom",
      component: "custom",
    },
  ]);
  const visit: Visit = {
    date: format(new Date(), "yyyy-MM-dd hh:mm:ss"),
    chiefComplaint: "",
    flag: "",
    patientId: null,
    parentId: null,
    serialNumber: "",
    isInpatient: false,
    clinicVisitTypeId: 1,
    clinicVisitTypeLayoutId: null,
    userId: null,
    meta: {
      layoutContent: {},
    },
    fee: 0,
    discount: 0,
    amountPaid: 0,
    medicines: [],
    services: [],
    symptoms: [],
    provisionalDiagnosis: [],
    finalDiagnosis: [],
    advice: "",
    clinicVisitType: {
      id: 1,
      clinicId: 0,
      visitTypeId: 0,
      visitType: {
        id: 1,
        name: "",
      },
    },
    visitFiles: [],
  };
  const [visitTypes, setVisitTypes] = useState([]);
  const [selectedVisitType, setSelectedVisitType] = useState();
  const [gridVisibility, setGridVisibility] = useState(true);
  const [isModalOpen, setModalVisibility] = useState<boolean>(false);
  const [isPageModalOpen, setIsPageModalOpen] = useState(false);
  const [editablePageIndex, setEditablePageIndex] = useState<number | null>(
    null
  );
  const [selectedTab, setSelectedTab] = useState<"general" | "vitals">(
    "general"
  );
  const [instructionsVisible, setInstructionsVisible] = useState<boolean>(true);
  const components: LayoutComponent[] = layoutComponents;
  const [selectedComponent, setSelectedComponent] = useState<LayoutComponent>();
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const dispatch = useAppDispatch();

  const labelRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.focus();
    }
  }, [selectedComponent]);

  useEffect(() => {
    dispatch(setDefaultVisit());

    (async () => {
      try {
        const response = await getClinicVisitTypes();
        setVisitTypes(response.data);
        setSelectedVisitType(response.data[0].id);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedVisitType) {
      getVisitTypeLayout(selectedVisitType);
    }
  }, [selectedVisitType]);

  const getVisitTypeLayout = (id: any) => {
    try {
      getLayout(id).then((res: any) => {
        if (res.data && res.data.layout.length > 0) {
          const updatedPages = res.data.layout.map((i: Page) => {
            i.layout =
              i.layout &&
              i.layout.map((j) => {
                j.static = false;
                j.isResizable = true;
                j.isDraggable = true;
                return j;
              });
            return i;
          });
          setPages(updatedPages);
        } else {
          setPages([
            {
              label: "Page 1",
              gridItems: [],
              component: "custom",
              type: "custom",
              id: "1",
              layout: [],
            },
          ]);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const addComponent = (component?: LayoutComponent) => {
    const comp = component || selectedComponent;

    if (!comp) return;

    const currentPage = pages[currentStep - 1];

    if (isComponentExist(currentPage, comp.id)) {
      updateExistingComponent(currentPage, comp);
    } else {
      addNewComponent(currentPage, comp);
    }

    setIsSettingsModalVisible(false);
    setSelectedComponent(undefined);
  };

  const updateExistingComponent = (page: Page, comp: LayoutComponent) => {
    const updatedGridItems = page.gridItems?.map((gridItem) =>
      gridItem.id === comp.id ? updateGridItem(gridItem, comp) : gridItem
    );

    const updatedLayout = page.layout?.map((layoutItem) =>
      parseInt(layoutItem.i) === comp.id
        ? updateLayoutItem(layoutItem, comp)
        : layoutItem
    );

    updatePage(currentStep - 1, updatedGridItems, updatedLayout);
  };

  const addNewComponent = (page: Page, comp: LayoutComponent) => {
    const newId = new Date().getTime();
    const newComponent = createNewLayoutComponent(newId, comp);

    const updatedGridItems = [...page.gridItems, { ...comp, id: newId }];
    const updatedLayout = [...page.layout, newComponent];

    updatePage(currentStep - 1, updatedGridItems, updatedLayout);
  };

  const updatePage = (
    pageIndex: number,
    updatedGridItems: LayoutComponent[],
    updatedLayout: any[]
  ) => {
    const updatedPages = [...pages];
    updatedPages[pageIndex] = {
      ...updatedPages[pageIndex],
      gridItems: updatedGridItems,
      layout: updatedLayout,
    };
    setPages(updatedPages);
  };

  const editComponent = () => {
    setIsSettingsModalVisible(true);
  };

  const deleteComponent = (gridItem?: LayoutComponent) => {
    // Use the passed `gridItem` or fallback to `selectedComponent`
    const itemToDelete = gridItem || selectedComponent;

    if (itemToDelete) {
      const updatedPages = removeComponent(
        pages,
        currentStep - 1,
        itemToDelete.id
      );
      setPages(updatedPages);
    }
  };

  const selectComponent = useCallback(
    (property: string, e: ChangeEvent<HTMLInputElement>) => {
      if (selectedComponent) {
        setSelectedComponent({
          ...selectedComponent,
          ...(property === "label"
            ? {
                data: {
                  ...selectedComponent.data,
                  label: e.target.value,
                  key: camelize(e.target.value),
                },
              }
            : { [property]: e.target.value ? parseInt(e.target.value) : 0 }),
        });
      }
    },
    [selectedComponent]
  );

  const toggleFlyout = () => {
    setIsPageModalOpen(!isPageModalOpen);
  };

  const addPage = (params = { label: "" }) => {
    if (pages.length <= 5) {
      setPages([
        ...pages,
        {
          label:
            params && params.label && params.label !== ""
              ? params.label
              : `Page ${pages.length + 1}`,
          gridItems: [],
          id: new Date().getTime().toString(),
          layout: [],
          component: "custom",
          type: "custom",
        },
      ]);
    } else {
      alert("You can add maximum 3 pages");
    }
  };

  const setPageLabel = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedPages = pages.map((page, i) => {
      if (index === i) {
        page.label = e.target.value;
      }
      return page;
    });
    setPages(updatedPages);
  };

  const nextPage = () => {
    if (currentStep < pages.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousPage = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const setPageOrder = (layout: Layout[]) => {
    setEditablePageIndex(null);
    const sortedLayout = layout.sort((a, b) => a.y - b.y);
    const sortedPages = sortedLayout.map((item) => {
      const index = pages.findIndex((page) => page.id === item.i);
      return pages[index];
    });
    setPages(sortedPages);
  };

  const saveLayout = (clinicVisitTypeId: any) => {
    setEditablePageIndex(null);
    const emptyPages = pages.filter(
      (page) => !page.gridItems.length && page.type === "custom"
    );
    if (emptyPages.length) {
      alert("Page can not be blank!");
    }
    if (selectedVisitType && emptyPages.length === 0) {
      saveVisitTypeLayout(clinicVisitTypeId, pages).then((res: any) => {
        showNotification(
          "success",
          "Layout Added Successfully",
          "",
          "topRight"
        );
      });
    }
  };

  const handleModalSubmit = () => {
    saveLayout(selectedVisitType);
    setModalVisibility(false);
  };

  const handleChange = (value: Array<string>) => {
    const options = value.map((i) => {
      return {
        label: i,
        value: i,
      };
    });

    if (selectedComponent) {
      setSelectedComponent({
        ...selectedComponent,
        data: { ...selectedComponent.data, options },
      });
    }
  };

  const handleUnit = (value: string) => {
    if (selectedComponent && selectedComponent.data.isVital) {
      setSelectedComponent({
        ...selectedComponent,
        data: { ...selectedComponent.data, selectedUnit: value },
      });
    }
  };

  const handleChangeVisitType = (id: any) => {
    setSelectedVisitType(id);
    getVisitTypeLayout(id);
  };

  const deletePage = (index: number) => {
    setEditablePageIndex(null);

    if (index < 0) {
      return; // Early return if index is invalid
    }

    const pageHasGridItems = pages[index].gridItems.length > 0;
    const isConfirmed =
      !pageHasGridItems ||
      window.confirm(
        "The page you have selected has some components in it, are you sure you want to delete it?"
      );

    if (isConfirmed) {
      const updatedPages = [
        ...pages.slice(0, index),
        ...pages.slice(index + 1),
      ];
      setPages(updatedPages);
    }
  };

  const renderDefaultContent = (page: Page) => {
    switch (page.component) {
      case "diagnosis":
        return <Diagnosis />;
      case "treatment":
        return <Treatment preview={true} />;
      case "payment":
        return <Payment preview={true} />;
      default:
        return <div>Unknown Component.</div>;
    }
  };

  const onDrop = (layout: Layout[], layoutItem: Layout, event: Event) => {
    if (selectedComponent) {
      addComponent({ ...selectedComponent, x: layoutItem.x });
    }
  };

  const dragStarted = (component: LayoutComponent) => {
    setSelectedComponent(component);
    setInstructionsVisible(false);
  };

  const vitalDragStarted = (vital: Vital) => {
    setInstructionsVisible(false);
    const units = vital.units.split(",");
    const unit = units.length > 0 ? units[0] : null;
    const vitalComponent: LayoutComponent = {
      id: new Date().getTime(),
      component: "AmsaInput",
      class: "",
      label: "Text Input",
      w: 3,
      h: 2,
      x: 0,
      y: 0,
      minH: 2,
      maxH: 2,
      minW: 1,
      maxW: 4,
      data: {
        key: vital.key,
        label: vital.label,
        isRequired: false,
        value: "",
        selectedUnit: unit,
        units: vital.units,
        isVital: true,
      },
    };
    setSelectedComponent(vitalComponent);
  };

  return (
    <>
      <div>
        <div className="flex justify-between">
          <div className="flex items-center">
            <h1 className="text-base font-semibold leading-6 text-gray-900 ">
              Design Layout
            </h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              Design custom layout based on your requirements
            </p> */}
          </div>
          <div className="flex flex-row items-center justify-center">
            <div>
              <Select
                className="w-full h-full py-1.5"
                placeholder={"Visit Type"}
                options={visitTypes}
                onChange={handleChangeVisitType}
                popupMatchSelectWidth={false}
                value={selectedVisitType}
                fieldNames={{
                  label: "name",
                  value: "id",
                }}
              />
            </div>
            <div className="flex items-center ml-2">
              <button
                onClick={() => {
                  setGridVisibility(!gridVisibility);
                  setSelectedComponent(undefined);
                }}
                type="button"
                className="p-1 text-white bg-indigo-600 rounded-full shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
              <button
                onClick={() => toggleFlyout()}
                type="button"
                className="p-1 ml-2 text-white bg-indigo-600 rounded-full shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                  />
                </svg>
              </button>
              {selectedComponent && (
                <>
                  <button
                    onClick={() => editComponent()}
                    type="button"
                    className="p-1 ml-2 text-white bg-indigo-600 rounded-full shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => deleteComponent()}
                    type="button"
                    className="p-1 ml-2 text-white bg-indigo-600 rounded-full shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </>
              )}
              <button
                onClick={() => setModalVisibility(true)}
                type="button"
                className="px-3 py-1.5 ml-2 text-white bg-indigo-600 rounded-full shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                save
              </button>
            </div>
          </div>
        </div>

        <div className="relative flex gap-2 pt-2">
          <PageSettings
            isPageModalOpen={isPageModalOpen}
            editablePageIndex={editablePageIndex}
            layoutPages={pages}
            onAddPage={() => addPage()}
            onDeletePage={(index) => deletePage(index)}
            onPageEditable={(index) => setEditablePageIndex(index)}
            onLabelChange={(e, index) => setPageLabel(e, index)}
            onPositionChange={(layout) => setPageOrder(layout)}
          />

          <div className="w-[30%]   bg-white rounded-md component-list text-center p-2">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option>General Fields</option>
                <option>Health Vitals</option>
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav
                  className="flex justify-around -mb-px space-x-8"
                  aria-label="Tabs"
                >
                  <button
                    onClick={() => setSelectedTab("general")}
                    className={classNames(
                      selectedTab === "general"
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "px-1 py-4 text-sm font-medium  border-b-2  whitespace-nowrap"
                    )}
                  >
                    General Fields
                  </button>
                  <button
                    onClick={() => setSelectedTab("vitals")}
                    className={classNames(
                      selectedTab === "vitals"
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "px-1 py-4 text-sm font-medium  border-b-2  whitespace-nowrap"
                    )}
                  >
                    Health Vitals
                  </button>
                </nav>
              </div>
            </div>
            {selectedTab === "general" ? (
              components
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((component) => (
                  <div
                    onDragStart={() => dragStarted(component)}
                    key={component.id}
                    draggable={true}
                    className="flex droppable-element items-center text-sm border-[1px] border-solid border-[#ccc] rounded-md bg-white min-h-[60px] mt-2"
                  >
                    <div className="flex justify-between w-full p-2">
                      <span>{component.label}</span>
                      <span>
                        <img className="h-6" src={dragHandle} alt="handle" />
                      </span>
                    </div>
                  </div>
                ))
            ) : (
              <VitalList onDragStart={vitalDragStarted} />
            )}
          </div>
          {pages.length > 0 && (
            <div
              className={
                "w-full  bg-white border-[#ccc] border-dashed layout-design"
              }
            >
              <div className="flex items-center border-b text-primary rounded-t-md justify-evenly">
                {pages.map((i: any, index) => (
                  <div
                    key={i.id}
                    onClick={() => setCurrentStep(index + 1)}
                    className="flex flex-col w-full cursor-pointer"
                  >
                    <div
                      className={
                        classNames(
                          currentStep === index + 1
                            ? " p-6  border-b-2 border-primary border-solid"
                            : ""
                        ) + "text-xs text-center text-primary   md:text-base"
                      }
                    >
                      {i.label}
                    </div>
                  </div>
                ))}
              </div>

              <div className="h-[calc(100vh-293px)] overflow-y-scroll">
                {pages[currentStep - 1].component === "custom" ? (
                  pages[currentStep - 1].gridItems.length === 0 &&
                  instructionsVisible ? (
                    <Instructions />
                  ) : (
                    <ResponsiveGridLayout
                      onDrop={onDrop}
                      draggableHandle=".drag-handle"
                      isDroppable={true}
                      className="h-64 layout"
                      layouts={{ lg: pages[currentStep - 1].layout }}
                      onLayoutChange={(layout: Layout[]) => {
                        const updatedPages = [...pages];
                        updatedPages[currentStep - 1].layout = layout;
                        setPages([...updatedPages]);
                      }}
                      cols={{ lg: 12, md: 12, sm: 8, xs: 4, xxs: 2 }}
                      rowHeight={40}
                    >
                      {pages[currentStep - 1].gridItems.map((gridItem) => {
                        return (
                          <div
                            className={`
                            ${
                              gridItem.component === "FasciaChart"
                                ? "border-0 p-1"
                                : "border-solid p-2 bg-white"
                            } ${
                              selectedComponent &&
                              gridItem.component !== "FasciaChart" &&
                              selectedComponent.id === gridItem.id
                                ? "border-blue-500 border-2"
                                : "border-[#ccc]" + (gridVisibility ? "" : "")
                            } border relative group`}
                            key={gridItem.id}
                          >
                            <DynamicComponent
                              gridItem={gridItem}
                              setValue={() => {}}
                              shouldFocus={false}
                              visit={visit}
                            />
                            <span
                              onClickCapture={(e) => {
                                e.stopPropagation();
                                setSelectedComponent(gridItem);
                                editComponent();
                              }}
                              className="absolute z-20 opacity-0 cursor-pointer pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 group-hover:block top-1 right-12"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                />
                              </svg>
                            </span>
                            <span
                              onClickCapture={(e) => {
                                e.stopPropagation();
                                deleteComponent(gridItem);
                              }}
                              className="absolute z-20 opacity-0 cursor-pointer pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 group-hover:block top-1 right-6"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </span>

                            <span className="absolute z-20 opacity-0 cursor-pointer pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 group-hover:block drag-handle top-1 right-1">
                              <img
                                className="w-6 h-6"
                                src={dragHandle}
                                alt="handle"
                              />
                            </span>
                          </div>
                        );
                      })}
                    </ResponsiveGridLayout>
                  )
                ) : (
                  pages[currentStep - 1].type === "default" &&
                  renderDefaultContent(pages[currentStep - 1])
                )}
              </div>

              <footer className="float-right px-2 py-2 bg-white footer">
                {currentStep > 1 && (
                  <button
                    onClick={() => previousPage()}
                    className="px-6 py-2 mx-2 my-2 text-sm transition duration-150 ease-in-out rounded text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  >
                    Previous
                  </button>
                )}

                {currentStep !== pages.length && (
                  <button
                    onClick={() => nextPage()}
                    className="px-6 py-2 mx-2 my-2 text-sm transition duration-150 ease-in-out rounded text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                  >
                    Next
                  </button>
                )}
              </footer>
            </div>
          )}
        </div>
      </div>
      {isSettingsModalVisible && selectedComponent && (
        <ComponentSettingsModal
          selectedComponent={selectedComponent}
          onSelect={selectComponent}
          onClose={() => setIsSettingsModalVisible(false)}
          onAddComponent={addComponent}
          onSelectChange={handleChange}
          onUnitChange={handleUnit}
        />
      )}

      {isModalOpen && (
        <ConfirmationModal
          message="Are you sure do you want to save component "
          onClose={() => {
            setModalVisibility(false);
          }}
          onSubmit={handleModalSubmit}
          okLabel="Save"
          cancelLabel="Cancel"
        ></ConfirmationModal>
      )}
    </>
  );
};

export default LayoutDesign;
