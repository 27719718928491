import { LayoutComponent } from "../interfaces/LayoutComponent";
import { physiotherapyData } from "./Physiotherapy";

export const layoutComponents: LayoutComponent[] = [
  {
    id: 13,
    component: "VisitFiles",
    class: "",
    label: "Visit Files",
    w: 4,
    h: 6,
    x: 0,
    y: 0,
    minH: 6,
    maxH: 12,
    minW: 3,
    maxW: 5,
    data: {
      label: "Upload Files",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 12,
    component: "MedicineInstructions",
    class: "",
    label: "Medicine Instructions",
    w: 12,
    h: 6,
    x: 0,
    y: 0,
    minH: 6,
    maxH: 12,
    minW: 12,
    maxW: 12,
    data: {
      label: "Instructions",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 11,
    component: "AmsaEditor",
    class: "",
    label: "Advice",
    w: 4,
    h: 6,
    x: 0,
    y: 0,
    minH: 6,
    maxH: 12,
    minW: 3,
    maxW: 6,
    data: {
      key: "advice",
      label: "Advice",
      isRequired: false,
      value: "",
      isDefault: true,
    },
  },
  {
    id: 10,
    component: "MedicineSelect",
    class: "",
    label: "Medicines",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 3,
    minW: 1,
    maxW: 4,
    data: {
      key: "medicines",
      label: "Medicines",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 9,
    component: "DiseaseSelect",
    class: "",
    label: "Final Diagnosis",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 3,
    minW: 1,
    maxW: 4,
    data: {
      key: "finalDiagnosis",
      label: "Final Diagnosis",
      isRequired: true,
      value: "",
      props: {
        propertyType: "finalDiagnosis",
        diseaseType: "disease",
      },
    },
  },
  {
    id: 8,
    component: "DiseaseSelect",
    class: "",
    label: "Provisional Diagnosis",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 3,
    minW: 1,
    maxW: 4,
    data: {
      key: "provisional_diagnosis",
      label: "Provisional Diagnosis",
      isRequired: false,
      value: "",
      props: {
        propertyType: "provisionalDiagnosis",
        diseaseType: "disease",
      },
    },
  },
  {
    id: 7,
    component: "SymptomSelect",
    class: "",
    label: "Symptoms",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 3,
    minW: 1,
    maxW: 4,
    data: {
      key: "symptoms",
      label: "Symptoms",
      isRequired: true,
      value: "",
    },
  },
  {
    id: 6,
    component: "AmsaInput",
    class: "",
    label: "Chief Complaint",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 2,
    minW: 1,
    maxW: 4,
    data: {
      key: "chiefComplaint",
      label: "Chief Complaint",
      isRequired: false,
      value: "",
      isDefault: true,
    },
  },
  {
    id: 5,
    component: "AmsaInput",
    class: "",
    label: "Text Input",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 2,
    minW: 1,
    maxW: 4,
    data: {
      key: "textInput",
      label: "label",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 4,
    component: "AmsaDateInput",
    class: "",
    label: "Date Input",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 2,
    minW: 1,
    maxW: 3,
    data: {
      key: "dateInput",
      label: "label",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 3,
    component: "AmsaStaticSelect",
    class: "Class 1",
    label: "Static Select",
    w: 3,
    h: 2,
    x: 0,
    y: 0,
    minH: 2,
    maxH: 2,
    minW: 1,
    maxW: 3,
    data: {
      key: "selectInput",
      label: "label",
      isRequired: false,
      value: "",
      options: [
        {
          label: "Option A",
          value: "Option A",
        },
        {
          label: "Option B",
          value: "Option B",
        },
      ],
    },
  },
  {
    id: 2,
    component: "AmsaEditor",
    class: "",
    label: "Rich Text Editor",
    w: 4,
    h: 6,
    x: 0,
    y: 0,
    minH: 6,
    maxH: 12,
    minW: 3,
    maxW: 5,
    data: {
      key: "textEditor",
      label: "label",
      isRequired: false,
      value: "",
    },
  },
  {
    id: 1,
    component: "FasciaChart",
    class: "",
    label: "Fascia Chart",
    w: 10,
    h: 6,
    x: 0,
    y: 0,
    minH: 6,
    maxH: 12,
    minW: 12,
    maxW: 12,
    data: {
      key: "fasciaChart",
      label: "label",
      isRequired: false,
      value: physiotherapyData,
    },
  },
];
