export const formatString = (input: string) => {
  // Replace underscores with spaces
  let formatted = input.replace(/_/g, " ");

  // Capitalize the first letter of each word
  formatted = formatted.replace(/\b\w/g, (char) => char.toUpperCase());

  return formatted;
};

export const convertToReadableString = (input: string) => {
  // Add a space before each uppercase letter, except the first letter, then trim and capitalize the result
  const result = input
    .replace(/([A-Z])/g, " $1") // Add space before each uppercase letter
    .trim() // Remove leading spaces
    .split(" ") // Split into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(" "); // Join words with a space

  return result;
};
