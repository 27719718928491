import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import { getTranslations } from "../apis/auth";

export interface LanguageContextInterface {
  language: string;
  translations: Array<any>;
  updateLanguage: (arg: string) => void;
  translate: (arg: string) => ReactNode;
}

interface LanguageProviderProps {
  children: ReactNode;
}
export const LanguageContext = createContext({} as LanguageContextInterface);
const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}: any) => {
  const [language, setLanguage] = useState("");
  const [translations, setTranslations] = useState<any>([]);
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    // Fetch translations from the API and update the state
    fetchTranslations(language);
  }, [language]);

  useMemo(() => {
    const user = window.localStorage.getItem("user");
    if (user) {
      const { preferredLanguage } = JSON.parse(user);
      setLanguage(preferredLanguage);
    }
  }, []);

  const fetchTranslations = async (language: any) => {
    try {
      if (token) {
        const res: any = await getTranslations(language);
        setTranslations(res.data);
      }
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  const updateLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
  };

  const translate = (key: string) => {
    const translation = translations.find((i: any) => i.key === key)?.value;
    return translation ? <>{translation}</> : key; // Return the translation if exists, else fallback to the translation key itself
  };

  return (
    <LanguageContext.Provider
      value={{ language, translations, updateLanguage, translate }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
