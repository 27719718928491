import { Select } from "antd";
import React from "react";
interface AmsaInputProps {
  data: any;
  setValue: any;
}
const AmsaStaticSelect: React.FC<AmsaInputProps> = ({ data, setValue }) => {
  return (
    <div className="block">
      <span className="text-sm font-medium text-gray-700">{data.label}</span>
      <div className="mt-2">
        <Select
          className="w-full h-full py-1.5"
          placeholder={data.placeholder}
          options={data.options}
          onChange={setValue}
          defaultOpen={false}
          value={data.value}
        />
      </div>
    </div>
  );
};

export default AmsaStaticSelect;
