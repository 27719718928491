import React from "react";
import { Event } from "../../interfaces/Event";
import { format, getMonth } from "date-fns";

type CalendarDay = {
  date: Date;
  events: Array<Event>;
};

type MonthViewProps = {
  calendarDays: Array<CalendarDay>;
  onDayClick: (day: CalendarDay) => void;
  selectedDay: Date | null;
  firstDayCurrentMonth: Date;
  onViewDetails: (event: Event) => void;
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const MonthView: React.FC<MonthViewProps> = ({
  calendarDays,
  onDayClick,
  onViewDetails,
  selectedDay,
  firstDayCurrentMonth,
}) => {
  return (
    <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
      <>
        {calendarDays &&
          calendarDays.map((day: any, i: number) => (
            <div
              key={i}
              data-index={i}
              onClick={() => onDayClick(day)}
              className={classNames(
                day.date === selectedDay
                  ? "bg-indigo-100"
                  : day.date !== selectedDay &&
                    getMonth(new Date(day.date)) ===
                      getMonth(new Date(firstDayCurrentMonth))
                  ? "bg-white"
                  : "bg-gray-100 text-gray-500",
                " relative px-1 py-1 text-sm hover:bg-indigo-50 text-gray-900 border border-gray-200"
              )}
            >
              <span>
                <time
                  className={classNames(
                    format(day.date, "yyyy-MM-dd") ===
                      format(new Date(), "yyyy-MM-dd")
                      ? "flex mb-1 h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white hover:cursor-pointer"
                      : ""
                  )}
                >
                  {format(new Date(day.date), "d")}
                </time>

                <div className="hidden px-1 lg:block text-primary">
                  {day?.events &&
                    day?.events.map((event: Event, index: number) => (
                      <div key={event.id}>
                        {index < 2 && (
                          <div className="">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                onViewDetails(event);
                              }}
                              className="inline-block w-full px-2 py-0.5 text-xs text-white text-ellipsis capitalize truncate bg-indigo-400 rounded-md cursor-pointer group-hover:text-indigo-600"
                            >
                              {event.eventCategory.name === "appointment" ? (
                                <span>{`${event.patientName} (${format(
                                  new Date(event.startDate),
                                  "hh:mm a"
                                )})`}</span>
                              ) : (
                                <span>{event.title}</span>
                              )}
                              <span></span>
                            </span>
                          </div>
                        )}
                      </div>
                    ))}

                  {day?.events.length > 2 && (
                    <span className="list-disc ">
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          onDayClick(day);
                        }}
                        className="px-2 py-0.5 text-xs text-white capitalize truncate bg-indigo-400 rounded-md cursor-pointer group-hover:text-indigo-600"
                      >
                        More Events..
                      </span>
                    </span>
                  )}
                </div>
              </span>
            </div>
          ))}
      </>
    </div>
  );
};

export default MonthView;
