import React, { useEffect, useState } from "react";
import MultiSelect from "../../components/MultiSelect";
import { Option } from "../../interfaces/MultiSelectTypes";
import { Visit } from "../../interfaces/Visit";
import { updateField } from "../../utils/StateUtil";
import { useAppDispatch } from "../../hooks";
import { getDisorders, saveDisorder } from "../../apis/disorder";
import { convertToReadableString } from "../../utils/StringUtil";
type DiseaseSelectProps = {
  visit: Visit;
  propertyType: "provisionalDiagnosis" | "finalDiagnosis";
  diseaseType: "disease" | "surgery" | "allergy";
};
const DiseaseSelect: React.FC<DiseaseSelectProps> = ({
  visit,
  propertyType,
  diseaseType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [diseases, setDiseases] = useState<Array<Option>>([]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    fetchDiseases();
  }, []);

  const handleSearch = (search: string) => {
    setSearchTerm(search);
    fetchDiseases(search);
  };

  const fetchDiseases = async (search: string = "") => {
    try {
      const {
        data: { data: response },
      } = await getDisorders({ name: search, type: diseaseType });
      const diseaseData = response.map((i: Record<string, number>) => {
        return {
          label: i.name,
          value: i.id,
        };
      });
      setDiseases(diseaseData);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleDiseaseSelect = (option: Option) => {
    if (
      !visit[propertyType].some((selected) => selected.value === option.value)
    ) {
      updateField(propertyType, [...visit[propertyType], option], dispatch);
    }
  };

  const handleDiseaseRemoval = (option: Option) => {
    const updatedDiseases = visit[propertyType].filter(
      (selected) => selected.value !== option.value
    );
    updateField(propertyType, updatedDiseases, dispatch);
  };

  const createNew = async (disease: string) => {
    try {
      const { data } = await saveDisorder({ name: disease, type: diseaseType });

      updateField(
        propertyType,
        [
          ...visit[propertyType],
          {
            label: data.data.name,
            value: data.data.id,
          },
        ],
        dispatch
      );
      setSearchTerm("");
      fetchDiseases();
    } catch (error) {}
  };

  return (
    <div className="block">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {convertToReadableString(propertyType)}
      </label>
      <MultiSelect
        onAddNewOption={createNew}
        selectedOptions={visit[propertyType]}
        options={diseases}
        isOpen={isOpen}
        searchTerm={searchTerm}
        onSearch={handleSearch}
        onOptionSelect={handleDiseaseSelect}
        onOptionRemove={handleDiseaseRemoval}
        toggleDropdown={toggleDropdown}
      />
    </div>
  );
};

export default DiseaseSelect;
