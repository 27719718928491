import React, { useCallback, useEffect, useState } from "react";
import MultiSelect from "../../components/MultiSelect";
import { Option } from "../../interfaces/MultiSelectTypes";
import { Visit } from "../../interfaces/Visit";
import { updateField } from "../../utils/StateUtil";
import { useAppDispatch } from "../../hooks";
import { getMedicines } from "../../apis/medicine";
import { debounce } from "lodash";

import { Medicine } from "../../interfaces/Medicine";
type MedicineSelectProps = {
  visit: Visit;
  onCreate: (value: string) => void;
};
const MedicineSelect: React.FC<MedicineSelectProps> = ({ visit, onCreate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [medicines, setMedicines] = useState<Array<Medicine>>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchMedicines();
  }, []);

  const handleSearch = (search: string) => {
    setSearchTerm(search);
    debouncedFetchMedicines(search);
  };

  const debouncedFetchMedicines = useCallback(
    debounce((query) => fetchMedicines(query), 500), // 300ms delay
    []
  );

  const fetchMedicines = async (search: string = "") => {
    try {
      const {
        data: { data: response },
      } = await getMedicines({ name: search, autocomplete: true });
      setMedicines(response);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleMedicineSelect = (option: Option) => {
    if (
      !visit.medicines.some(
        (selected) => selected?.id && selected.id.toString() === option.value
      )
    ) {
      const [medicine] = medicines.filter(
        (medicine) => medicine.id.toString() === option.value
      );

      updateField(
        "medicines",
        [
          ...visit.medicines,
          {
            id: option.value,
            name: option.label,
            instructions: {
              name: option.label,
              molecule: medicine.molecule,
              form: medicine.form,
              morning: 0,
              afternoon: 0,
              evening: 0,
              night: 0,
              meal: "before_meal",
              note: "",
              quantity: 0,
              frequency: "daily",
              duration: "",
              power: "",
              unit: "",
              dosage: "",
              durationPeriod: "days",
            },
          },
        ],
        dispatch
      );
    }
  };

  const handleMedicineRemoval = (option: Option) => {
    const updatedMedicines = visit.medicines.filter(
      (selected) => selected?.id && selected.id.toString() !== option.value
    );
    updateField("medicines", updatedMedicines, dispatch);
  };

  return (
    <div className="block">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Medicines
      </label>
      <MultiSelect
        onAddNewOption={() => onCreate(searchTerm)}
        selectedOptions={visit.medicines.map((i: any) => ({
          label: i.name,
          value: i?.id.toString(),
        }))}
        options={medicines.map((i) => ({
          label: i.name,
          value: i.id.toString(),
        }))}
        isOpen={isOpen}
        searchTerm={searchTerm}
        onSearch={handleSearch}
        onOptionSelect={handleMedicineSelect}
        onOptionRemove={handleMedicineRemoval}
        toggleDropdown={toggleDropdown}
      />
    </div>
  );
};

export default MedicineSelect;
