import api from "../middlware/api";
import { BASE_URL } from "../constants";
import { camelToSnake } from "../utils/CamelToSnake";

export const getExpenses = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/expenses?${query.toString()}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveExpense = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/expenses`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getExpense = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/expenses/${payload}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateExpense = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .put(`${BASE_URL}/api/v1/expenses/${payload.id}`, camelToSnake(payload))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const exportExpenses = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/export/expenses?${query.toString()}`,{
        headers:{
          "Content-Type":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType:"blob"
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};