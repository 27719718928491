import api from "../middlware/api";
import { camelToSnake } from "../utils/CamelToSnake";
import { BASE_URL } from "../constants";
import axios from "../middlware/api";

export const signIn = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/login`, camelToSnake(payload))
      .then((res) => {
        if (res.data.status !== 'multiple_clinics') {
          window.localStorage.setItem("user", JSON.stringify(res.data.user));
          window.localStorage.setItem(
            "permissions",
            JSON.stringify(res.data.user.permissions)
          );
          window.localStorage.setItem("token", res.data.accessToken);
          window.localStorage.setItem("calendarView", "month");
          axios.defaults.headers.common.Authorization = `Bearer ${res.data.accessToken}`;
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const signInWithClinic = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/login/clinic`, camelToSnake(payload))
      .then((res) => {
        window.localStorage.setItem("user", JSON.stringify(res.data.user));
        window.localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.user.permissions)
        );
        window.localStorage.setItem("token", res.data.accessToken);
        window.localStorage.setItem("calendarView", "month");
        axios.defaults.headers.common.Authorization = `Bearer ${res.data.accessToken}`;
        resolve(res);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  })
}

export const getTranslations = (payload: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/translations?locale=${payload}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const saveTranslation = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/translations/${payload.locale}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTranslation = (id: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/translations/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateTranslation = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .put(`${BASE_URL}/api/v1/translations/${payload.id}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const changePassword = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .patch(`${BASE_URL}/api/v1/users/change-password`, camelToSnake(payload))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendLinktoCreatePassword = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/send-create-password-link`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPassword = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/reset-password`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
