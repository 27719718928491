import React, { useContext, useEffect, useRef, useState } from "react";
import {
  BanknotesIcon,
  CalendarIcon,
  HomeIcon,
  UsersIcon,
  Cog8ToothIcon,
  UserGroupIcon,
  CurrencyRupeeIcon,
  ListBulletIcon,
  QueueListIcon,
  DocumentTextIcon,
  KeyIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";

import { BuildingOfficeIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Outlet, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { Select } from "antd";
import { updateUserData } from "../apis/user";
import { LanguageContext } from "../providers/LanguageProvider";

export interface navItem {
  name: string;
  icon: any;
  route: string;
  isCurrent: boolean;
  children: Array<navItem>;
  showChildren: boolean;
}

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ");
}

const Dashboard: React.FC<{}> = () => {
  const { translate: t } = useContext(LanguageContext);
  const { updateLanguage } = useContext(LanguageContext);
  const user = window.localStorage.getItem("user");
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState({
    id: "",
    preferredLanguage: "en",
    firstName: "",
    lastName: "",
    roles: [],
  });
  const [isMobileMenusVisible, setMobileMenuVisible] = useState(false);
  const [isVisibleUserOptions, setIsVisibleUserOptions] =
    useState<boolean>(false);
  const [developerSettings, setDeveloperSettings] = useState<navItem>({
    name: "Developer Settings",
    route: "#",
    icon: Cog8ToothIcon,
    isCurrent: false,
    children: [
      {
        name: "visit_types_list",
        icon: HomeIcon,
        route: "/visit-types-list",
        isCurrent: false,
        children: [],
        showChildren: false,
      },
    ],
    showChildren: false,
  });
  const baseNavigation = [
    {
      name: "dashboard",
      route: "/dashboard",
      icon: HomeIcon,
      isCurrent: false,
      children: [],
    },

    {
      name: "patient",
      route: "/patients",
      icon: UserGroupIcon,
      children: [],
      showChildren: false,
    },

    {
      name: "visit_list",
      route: "/visits",
      icon: QueueListIcon,
      isCurrent: false,
      children: [],
    },
    {
      name: "calender",
      route: "/calendar",
      icon: CalendarIcon,
      isCurrent: false,
      children: [],
    },
    {
      name: "expenses",
      route: "/expenses",
      icon: CurrencyRupeeIcon,
      isCurrent: false,
      children: [],
    },
    {
      name: "masters",
      route: "#",
      icon: ListBulletIcon,
      children: [
        {
          name: "medicine_list",
          route: "/medicines",
          icon: HomeIcon,
          isCurrent: false,
        },
        {
          name: "disorder_list",
          route: "/disorders",
          icon: HomeIcon,
          isCurrent: false,
        },
        {
          name: "service_list",
          route: "/services",
          icon: HomeIcon,
          isCurrent: false,
        },
        {
          name: "symptom_list",
          route: "/symptoms",
          icon: HomeIcon,
          isCurrent: false,
        },
        {
          name: "expense_category",
          route: "/expense-categories",
          icon: BanknotesIcon,
          isCurrent: false,
        },
        {
          name: "company_list",
          route: "/companies",
          icon: BuildingOfficeIcon,
          isCurrent: false,
        },
      ],
      showChildren: false,
    },

    {
      name: "logs",
      route: "/logs",
      icon: DocumentTextIcon,
      isCurrent: false,
      children: [],
    },

    {
      name: "roles_and_permissions",
      route: "/roles-and-permissions",
      icon: KeyIcon,
      isCurrent: false,
      children: [],
    },
    {
      name: "layout_design",
      route: "/layout-design",
      icon: RectangleGroupIcon,
      isCurrent: false,
      children: [],
    },
    {
      name: "referrals",
      route: "/referrals",
      icon: UserGroupIcon,
      children: [],
      showChildren: false,
    },
    {
      name: "user_list",
      route: "/users",
      icon: UsersIcon,
      children: [],
      showChildren: false,
    },
    {
      name: "settings",
      route: "/settings",
      icon: Cog8ToothIcon,
      isCurrent: false,
      children: [],
    },
  ];

  const [navigation, setNavigation] = useState<any>(baseNavigation);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (user) {
      setLoggedInUser(JSON.parse(user));
    }
  }, [user]);

  useEffect(() => {
    if (isVisibleUserOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisibleUserOptions]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !(dropdownRef.current as any).contains(event.target)
    ) {
      setIsVisibleUserOptions(false);
    }
  };

  const handleDeveloperSettingClick = (item: navItem) => {
    if (item.children && item.children.length) {
      item.showChildren = !item.showChildren;
      setMobileMenuVisible(true);
    } else {
      setMobileMenuVisible(false);
    }
    setDeveloperSettings(item);
    navigate(item.route);
  };

  const handleNavigation = (item: navItem) => {
    if (item.children && item.children.length) {
      item.showChildren = !item.showChildren;
      setMobileMenuVisible(true);
    } else {
      setMobileMenuVisible(false);
    }
    const updatedNavigations = navigation.map((i: any) => {
      if (i.name === item.name) {
        i.isCurrent = true;
      } else {
        if (i && i.children && i.children.length) {
          i.children = i.children.map((subMenu: any) => {
            if (item.name === subMenu.name) {
              item.isCurrent = true;
            } else {
              subMenu.isCurrent = false;
            }
            return subMenu;
          });
        } else {
          i.isCurrent = false;
        }
      }
      return i;
    });
    setNavigation(updatedNavigations);
    navigate(item.route);
  };

  const handleLanguageChange = (value: string) => {
    updateUserData({ id: loggedInUser?.id, preferredLanguage: value }).then(
      (res: any) => {
        window.localStorage.setItem("user", JSON.stringify(res.data));
        const user = JSON.parse(window.localStorage.getItem("user") || "{}");
        setLoggedInUser(user);
        updateLanguage(user.preferredLanguage);
      }
    );
  };

  const signOut = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
    navigate("/login");
  };

  const onProfileClick = () => {
    navigate(`/profile/${loggedInUser?.id}`);
  };

  return (
    <>
      <div>
        {isMobileMenusVisible && (
          <div
            className="relative z-50 lg:hidden"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-gray-900/80"></div>

            <div className="fixed inset-0 flex">
              <div className="relative flex flex-1 w-full max-w-xs mr-16">
                <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setMobileMenuVisible(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <svg
                      className="w-6 h-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                {/* mobile screen */}
                <div className="flex flex-col px-6 pb-4 overflow-y-auto bg-primary grow gap-y-5">
                  <div className="flex items-center h-16 shrink-0">
                    <img
                      className="w-auto h-14"
                      src={logo}
                      alt="Dhanvantaree"
                    />
                  </div>
                  <nav className="flex flex-col flex-1">
                    <ul className="flex flex-col flex-1 gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((i: any, index: any) => {
                            if (i.name !== "layout_design") {
                              return (
                                <li
                                  key={i.name}
                                  className={classNames(
                                    i.isCurrent
                                      ? i.children && i.children.length
                                        ? ""
                                        : "bg-active"
                                      : "",

                                    i.children && i.children.length === 0
                                      ? "hover:bg-hover"
                                      : i.children &&
                                        i.children.length &&
                                        !i.showChildren
                                      ? "hover:bg-hover"
                                      : "",

                                    "p-2 text-light text-sm leading-6  rounded-md group gap-x-3 cursor-pointer"
                                  )}
                                >
                                  <div
                                    className={classNames(
                                      "flex justify-between"
                                    )}
                                    onClick={() => handleNavigation(i)}
                                  >
                                    <div className="flex justify-between gap-x-3">
                                      <i.icon className="w-6 h-6" />
                                      <span className="mr-2">{t(i.name)}</span>
                                    </div>
                                    <div>
                                      {i.children && i.children.length > 0 && (
                                        <ChevronDownIcon className="w-6 h-6" />
                                      )}
                                    </div>
                                  </div>
                                  {i.children && i.showChildren && (
                                    <ul className="pt-2 -pb-2">
                                      {i.children.map((item: any) => (
                                        <li key={item.name}>
                                          <span
                                            onClick={() =>
                                              handleNavigation(item)
                                            }
                                            className={classNames(
                                              item.isCurrent ? "bg-active" : "",
                                              "flex p-2 ml-7 text-light font-semibold leading-6  rounded-md group gap-x-3 cursor-pointer hover:bg-hover"
                                            )}
                                          >
                                            {t(item.name)}
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              );
                            }
                            return null;
                          })}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex flex-col px-6 pb-4 overflow-y-auto bg-primary grow gap-y-5">
            <div className="flex items-center h-16 shrink-0">
              <img className="w-auto h-14" src={logo} alt="Dhanvantaree" />
            </div>
            <nav className="flex flex-col flex-1">
              <ul className="flex flex-col flex-1 gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((i: any, index: any) => {
                      return (
                        <li
                          key={i.name}
                          className={classNames(
                            i.isCurrent
                              ? i.children && i.children.length
                                ? ""
                                : "bg-active"
                              : "",

                            i.children && i.children.length === 0
                              ? "hover:bg-hover"
                              : i.children &&
                                i.children.length &&
                                !i.showChildren
                              ? "hover:bg-hover"
                              : "",

                            "p-2 text-light text-sm leading-6  rounded-md group gap-x-3 cursor-pointer"
                          )}
                        >
                          <div
                            className={classNames("flex justify-between")}
                            onClick={() => handleNavigation(i)}
                          >
                            <div className="flex justify-between gap-x-3">
                              <i.icon className="w-6 h-6" />
                              <span className="mr-2">{t(i.name)}</span>
                            </div>
                            <div>
                              {i.children && i.children.length > 0 && (
                                <ChevronDownIcon className="w-6 h-6" />
                              )}
                            </div>
                          </div>
                          {i.children && i.showChildren && (
                            <ul className="pt-2 -pb-2">
                              {i.children.map((item: any) => (
                                <li key={item.name}>
                                  <span
                                    onClick={() => handleNavigation(item)}
                                    className={classNames(
                                      item.isCurrent ? "bg-active" : "",
                                      "flex p-2 ml-7 text-light font-semibold leading-6  rounded-md group gap-x-3 cursor-pointer hover:bg-hover"
                                    )}
                                  >
                                    {t(item.name)}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                    {loggedInUser.roles.find(
                      (i: any) => i.name === "developer"
                    ) && (
                      <li
                        key="developer-settings"
                        className={classNames(
                          "p-2 text-light text-sm leading-6 rounded-md group gap-x-3 cursor-pointer"
                        )}
                      >
                        <div
                          className={classNames("flex justify-between")}
                          onClick={() =>
                            handleDeveloperSettingClick(developerSettings)
                          }
                        >
                          <div className="flex justify-between gap-x-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                              />
                            </svg>

                            <span className="mr-2">
                              {t(developerSettings.name)}
                            </span>
                          </div>
                          <div>
                            {developerSettings.children &&
                              developerSettings.children.length > 0 && (
                                <ChevronDownIcon className="w-6 h-6" />
                              )}
                          </div>
                        </div>
                        {developerSettings.children &&
                          developerSettings.showChildren && (
                            <ul className="pt-2 -pb-2">
                              {developerSettings.children.map((item: any) => (
                                <li key={item.name}>
                                  <span
                                    onClick={() => handleNavigation(item)}
                                    className={classNames(
                                      item.isCurrent ? "bg-active" : "",
                                      "flex p-2 ml-7 text-light font-semibold leading-6 text-white rounded-md group gap-x-3 cursor-pointer hover:bg-hover"
                                    )}
                                  >
                                    {t(item.name)}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex items-center h-16 px-4 bg-white border-b border-gray-200 shadow-sm shrink-0 gap-x-4 sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              onClick={() => setMobileMenuVisible(true)}
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>

            {/* below is the stright line which is needed in mobile view */}
            <div
              className="w-px h-6 bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            ></div>

            <div className="flex justify-end flex-1 lg:self-stretch gap-x-4 lg:gap-x-6">
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <Select
                  defaultValue="en"
                  style={{ width: 120 }}
                  onChange={handleLanguageChange}
                  value={loggedInUser.preferredLanguage}
                  options={[
                    { value: "en", label: "English" },
                    { value: "mr", label: "Marathi" },
                    { value: "hn", label: "Hindi" },
                  ]}
                />
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                    />
                  </svg>
                </button>

                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                ></div>

                <div className="relative">
                  <button
                    type="button"
                    className="-m-1.5 flex items-center p-1.5"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                    onClick={() =>
                      setIsVisibleUserOptions(!isVisibleUserOptions)
                    }
                  >
                    <span className="sr-only">Open user menu</span>
                    <div className="w-8 h-8 pt-1 uppercase bg-gray-200 border border-gray-400 rounded-full">
                      {loggedInUser?.firstName.charAt(0) +
                        loggedInUser?.lastName.charAt(0)}
                    </div>
                  </button>
                  {isVisibleUserOptions && (
                    <div
                      className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      ref={dropdownRef}
                    >
                      <span
                        className="block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                        role="menuitem"
                        id="user-menu-item-0"
                        onClick={onProfileClick}
                      >
                        {t("your_profile")}
                      </span>
                      <span
                        onClick={signOut}
                        className="block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                        role="menuitem"
                        id="user-menu-item-1"
                      >
                        {t("sign_out")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <main className="p-4 bg-[#ECEFFF] h-[calc(100vh-65px)] overflow-x-hidden">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
