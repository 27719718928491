import React, { useState } from "react";
import {
  sendLinktoCreatePassword,
  signIn,
  signInWithClinic,
} from "../apis/auth";
import { useNotification } from "../NotificationContext";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { Select } from "antd";
import { getSettings } from "../apis/setting";

export const Login = () => {
  const [errors, setErrors] = useState<any>([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPassword, setNewPasswordVisibility] = useState(false);
  const [clinics, setClinics] = useState<Array<any>>([]);
  const [selectedClinic, setSelectedClinic] = useState<any>();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const Option = Select;

  const handleSignIn = () => {
    const { hasErrors, validationErrors } = validateLoginForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    signIn({ email, password })
      .then(async (res: any) => {
        if (res.data.status === "multiple_clinics") {
          setClinics(res.data.clinics);
        } else {
          showNotification("success", "Logged in successfully", "", "topRight");
          await getSettings();
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        showNotification(
          "error",
          "Error Occured",
          err.details.message,
          "topRight"
        );
      });
  };

  const validateLoginForm = () => {
    const validationErrors: any = {};

    if (email === "") {
      validationErrors.email = "* Email is required";
    }

    if (password === "") {
      validationErrors.password = "*Password is required";
    }

    if (
      clinics.length > 0 &&
      (selectedClinic === "" || selectedClinic === null)
    ) {
      validationErrors.clinic = "*Please select atleast one clinic";
    }
    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const handleSetNewPassword = () => {
    setNewPasswordVisibility(!newPassword);
  };
  const handleSubmit = () => {
    sendLinktoCreatePassword({ email: email })
      .then((res: any) => {
        showNotification(
          "success",
          "Email sent",
          "Please check your inbox or spam",
          "topRight"
        );
      })
      .catch((err: any) => {
        showNotification(
          "error",
          "Error Occurred",
          err.response.data.message,
          "topRight"
        );
      });
  };

  const handleKeyUp = (e: any) => {
    if (e.which === 13) {
      if (newPassword) {
        handleSubmit();
      } else {
        handleSignIn();
      }
    }
  };

  const handleClinicChange = (value: any) => {
    setSelectedClinic(value);
  };

  const handleContinue = () => {
    const { hasErrors, validationErrors } = validateLoginForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    signInWithClinic({ email, password, clinicId: selectedClinic })
      .then(async (res: any) => {
        if (res.data.status === "multiple_clinics") {
          setClinics(res.data.clinics);
        } else {
          showNotification("success", "Logged in successfully", "", "topRight");
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        showNotification(
          "error",
          "Error Occured",
          err.details.message,
          "topRight"
        );
      });
  };

  return (
    <>
      <div className="flex flex-col justify-center min-h-screen px-6 py-10 lg:px-8 bg-primary">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="w-auto h-24 mx-auto" src={logo} alt="Your Company" />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={(e) => handleKeyUp(e)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
                {errors && errors.email && (
                  <span className="text-sm text-red-500 leading-0">
                    {errors.email}
                  </span>
                )}
              </div>
              {newPassword === false && (
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                  </div>
                  <div className="relative mt-2">
                    <input
                      id="password"
                      name="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={passwordVisible ? "text" : "password"}
                      onKeyUp={(e) => handleKeyUp(e)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <button
                        type="button"
                        onClick={() => togglePasswordVisibility()}
                        className="cursor-pointer"
                      >
                        {passwordVisible ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                  {errors && (
                    <span className="ml-2 text-sm text-red-500">
                      {errors.password}
                    </span>
                  )}
                </div>
              )}

              {clinics.length > 0 && (
                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Select Clinic
                    </label>
                  </div>
                  <div className="relative mt-2">
                    <Select
                      style={{ width: "100%", height: "38px" }}
                      onChange={handleClinicChange}
                      value={selectedClinic}
                    >
                      {clinics.map((clinic) => (
                        <Option
                          key={clinic.id}
                          value={clinic.id}
                          style={{ height: "38px" }}
                        >
                          {clinic.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {errors && (
                    <span className="ml-2 text-sm text-red-500">
                      {errors.clinic}
                    </span>
                  )}
                </div>
              )}

              <div className="text-sm text-right">
                {newPassword === false && (
                  <button
                    onClick={() => handleSetNewPassword()}
                    className="font-semibold cursor-pointer text-primary hover:text-indigo-500"
                  >
                    Forgot password?
                  </button>
                )}
              </div>

              <div>
                {newPassword ? (
                  <div className="flex gap-4">
                    <button
                      onClick={() => handleSubmit()}
                      type="submit"
                      className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Submit
                    </button>
                  </div>
                ) : selectedClinic ? (
                  <button
                    onClick={() => handleContinue()}
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    onClick={() => handleSignIn()}
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                  >
                    Sign in
                  </button>
                )}
              </div>

              <div className="flex justify-center text-center">
                <span className="text-sm text-gray-500">
                  Don't you have an account?
                  <a
                    href="https://dhanvantaree.com/sign-up"
                    target="_blank"
                    className="text-sm font-medium cursor-pointer text-primary hover:text-hover"
                  >
                    {" "}
                    Sign Up
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
