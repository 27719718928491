import { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Pagination, PaginationProps } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Filter } from "../../interfaces/Filter";
import Filters from "../../components/Filters";
import { exportPatients, getPatients } from "../../apis/patient";
import Modal from "../../components/Modal";
import AddAppointment from "../visits/AddAppointment";
import { LanguageContext } from "../../providers/LanguageProvider";
import { format } from "date-fns";
const PatientList = () => {
  const [userId, setUserId] = useState<number>();
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(
    null
  );
  const [meta, setMeta] = useState<any>();
  const [patients, setPatients] = useState<Array<any>>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isAppointmentModalOpen, setAddAppointmentModalVisibility] =
    useState<boolean>(false);
  const { translate: t } = useContext(LanguageContext);

  const patientFilters: Array<Filter> = [
    {
      label: "Name",
      key: "name",
      value: "",
      component: "input",
    },
    {
      label: "Phone",
      key: "phone",
      value: "",
      component: "input",
    },
    {
      label: "Reg. No",
      key: "registration_number",
      value: "",
      component: "input",
    },
  ];

  const [filters] = useState<Array<Filter>>(patientFilters);
  const [isClearFilterList] = useState(false);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      fetchPatients({ per_page: 10, ...filters });
    } else {
      fetchPatients({ per_page: 10 });
    }
  }, [search]);

  const handleFilters = (params: any) => {
    if (params.length > 0) {
      let updatedQueryParams = new URLSearchParams();
      params.forEach((i: any) => {
        updatedQueryParams.set(i.key, i.value);
      });
      setSearchParams(updatedQueryParams.toString());
    } else {
      navigate(`/patients`);
    }
  };

  useEffect(() => {
    fetchPatients({ per_page: 10 });
  }, []);

  const fetchPatients = (params = {}) => {
    getPatients(params)
      .then((res: any) => {
        setPatients(res.data.data);
        setMeta(res.data.meta);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  const handleScheduleAppointment = (id: number) => {
    setSelectedPatientId(id);
    const user = window.localStorage.getItem("user");
    if (user) {
      const loggedInUser = JSON.parse(user);
      setUserId(loggedInUser.id);
    }
    setAddAppointmentModalVisibility(true);
  };

  const handleCloseModal = () => {
    setAddAppointmentModalVisibility(false);
    setSelectedPatientId(null);
  };

  //use per_page
  const onPaginationChange: PaginationProps["onChange"] = (page, pageSize) => {
    fetchPatients({ page, per_page: pageSize });
  };

  const downloadPatientList = () => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      exportPatientsList({ ...filters });
    } else {
      exportPatientsList({});
    }
  };

  const exportPatientsList = (params: any) => {
    exportPatients(params).then((res: any) => {
      downloadFile(res.data);
    });
  };

  const downloadFile = (data: any) => {
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });
    // const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    const today = format(new Date(), "dd_MM_yyyy_hh_mm");
    downloadLink.download = "patients-" + today + ".xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="px-2 lg:px-8">
      {/* header for mobile screen */}
      <div className="flex lg:hidden">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("patient")}
          </h1>
        </div>
        <div className="flex flex-row-reverse w-full">
          <div>
            <button
              type="button"
              onClick={() => navigate("/patients/new")}
              className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => downloadPatientList()}
              className="block px-3 py-2 mx-2 text-sm font-semibold text-center text-white capitalize rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                />
              </svg>
            </button>
          </div>
          <Filters
            clearFilterList={isClearFilterList}
            filters={filters}
            fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
          ></Filters>
        </div>
      </div>
      <div className="hidden sm:items-center lg:flex">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("patient")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("patient_description")}
          </p>
        </div>
        <div className="mx-2 mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <div className="mr-2">
            <Filters
              clearFilterList={isClearFilterList}
              filters={filters}
              fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
            ></Filters>
          </div>
          <button
            type="button"
            onClick={() => navigate("/patients/new")}
            className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
          >
            {t("add_patient")}
          </button>
          <button
            type="button"
            onClick={() => downloadPatientList()}
            className="block px-3 py-2 ml-2 text-sm font-semibold text-center text-white capitalize rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
          >
            {t("export")}
          </button>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("name")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("email")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("phone")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("registration_number")}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize"
                    >
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {patients && patients.length > 0 ? (
                    patients.map((patient) => (
                      <tr key={patient.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                          {patient.firstName + " " + patient.lastName}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {patient.email}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {patient.phone}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {patient.registrationNumber}
                        </td>
                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-left whitespace-nowrap sm:pr-6">
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: "Edit",
                                  label: (
                                    <a
                                      href={`/patients/${patient.id}`}
                                      rel="noopener noreferrer"
                                    >
                                      {t("edit")}
                                    </a>
                                  ),
                                },
                                {
                                  key: "Create Visit",
                                  label: (
                                    <a
                                      rel="noopener noreferrer"
                                      href={`/patients/${patient.id}/visits/types`}
                                    >
                                      {t("create_new_visit")}
                                    </a>
                                  ),
                                },
                                {
                                  key: "Schedule Appointment",
                                  label: (
                                    <span
                                      onClick={() =>
                                        handleScheduleAppointment(patient.id)
                                      }
                                    >
                                      {t("schedule_an_appointment")}
                                    </span>
                                  ),
                                },
                              ],
                            }}
                            placement="bottomLeft"
                          >
                            <Button>{t("actions")}</Button>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        {t("Record Not Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end pt-5">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                total={meta?.total}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isAppointmentModalOpen && selectedPatientId && (
        <Modal
          modalContent={
            <AddAppointment
              patientId={selectedPatientId}
              userId={userId}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};

export default PatientList;
