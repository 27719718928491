import api from '../middlware/api';
import { BASE_URL } from '../constants';
import { camelToSnake } from '../utils/CamelToSnake';

export const getReferredDoctors = () => {
  return new Promise((resolve, reject) => {
   
    api
      .get(`${BASE_URL}/api/v1/referred-doctors`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveReferredDoctors = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/referred-doctor`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};



