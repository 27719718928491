import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  UpdateFieldPayload,
  changePatient,
  changePatientData,
  selectPatient,
} from "../../features/patient/patientSlice";
import { Patient } from "../../interfaces/Patient";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { generateOtp, getPatient, verifyOtp } from "../../apis/patient";
import { LanguageContext } from "../../providers/LanguageProvider";
import { getReferredDoctors } from "../../apis/referredDoctor";
import Modal from "../../components/Modal";
import AddReferredDoctor from "../AddReferredDoctor";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useNotification } from "../../NotificationContext";

const BasicProfile: React.FC<{ errors: Patient | null }> = ({ errors }) => {
  const [isConsentPopupVisible, setIsConsentPopupVisible] = useState(false);
  const { translate: t } = useContext(LanguageContext);
  const patient = useAppSelector(selectPatient);
  const [doctors, setDoctors] = useState<Array<any>>([]);
  const [isReferredDopctorModalOpen, setReferredDoctorModalOpen] =
    useState<any>();
  const [isVisibleOtpField, setOtpFieldVisible] = useState<boolean>(false);
  const [otp, setOtp] = useState<any>();
  const [validationErrors, setValidationErrors] = useState<any>([]);

  const dispatch = useAppDispatch();
  const params = useParams();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const genders = [
    { id: "male", title: "Male" },
    { id: "female", title: "Female" },
    { id: "other", title: "Other" },
  ];

  const bloodGroups = [
    {
      label: "A+",
      value: "A+",
    },
    {
      label: "A-",
      value: "A-",
    },
    {
      label: "B+",
      value: "B+",
    },
    {
      label: "B-",
      value: "B-",
    },
    {
      label: "AB+",
      value: "AB+",
    },
    {
      label: "AB-",
      value: "AB-",
    },
    {
      label: "O+",
      value: "O+",
    },
    {
      label: "O-",
      value: "O-",
    },
    {
      label: "Don't know",
      value: "Don't know",
    },
  ];

  const [commentsEditorState, setCommentsEditorState] = useState(
    EditorState.createEmpty()
  );

  const updateField = (fieldName: keyof Patient, value: string | number) => {
    const payload: UpdateFieldPayload = { fieldName, value };
    dispatch(changePatientData(payload));
  };

  useEffect(() => {
    if (params && params.patientId) {
      getPatient(params.patientId).then((res: any) => {
        const response = res.data.data;

        if (response?.comments) {
          convertTextToEditor(response.comments, setCommentsEditorState);
        }
        dispatch(changePatient(res.data.data));
      });
    }
  }, [dispatch, params]);

  useEffect(() => {
    fetchDoctors();
  }, []);

  const onBloodGroupChange = (value: string) => {
    updateField("bloodGroup", value);
  };

  const calculateAge = (birthDate: any) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handleAge = (value: any) => {
    const age = calculateAge(value);
    updateField("birthDate", value);
    updateField("age", age);
  };

  const fetchDoctors = () => {
    getReferredDoctors().then((res: any) => {
      setDoctors(res.data);
    });
  };

  const handleReferredDoctorChange = (value: any) => {
    updateField("referredBy", value);
  };

  const handleCloseModal = () => {
    setReferredDoctorModalOpen(false);
    fetchDoctors();
  };

  const onEditorStateChange = (
    editorState: EditorState,
    field: keyof Patient,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    setState(editorState);
    const contentState = editorState.getCurrentContent();
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    updateField(field, contentStateString);
  };

  const convertTextToEditor = (
    text: any,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    if (text) {
      const contentState = convertFromRaw(JSON.parse(text));
      const initialEditorState = EditorState.createWithContent(contentState);
      setState(initialEditorState);
    }
  };

  const validateConsentForm = () => {
    const validationErrors: any = {};

    if (otp === "") {
      validationErrors.otp = "OTP is required";
    }
    if (patient.phone === "" || patient.phone === null) {
      validationErrors.phone = "Phone is required";
    }
    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const handleSendOtp = () => {
    const { hasErrors, validationErrors } = validateConsentForm();
    if (hasErrors) {
      setValidationErrors(validationErrors);
      return;
    }
    setValidationErrors(null);
    generateOtp({ phone: patient.phone, type: "consent" }).then(() => {
      showNotification("success", "Otp sent successfully", "", "topRight");
      setOtpFieldVisible(true);
    });
  };

  const handleVerifyOtp = () => {
    const { hasErrors, validationErrors } = validateConsentForm();
    if (hasErrors) {
      setValidationErrors(validationErrors);
      return;
    }
    setValidationErrors(null);

    verifyOtp({
      phone: patient.phone,
      otp,
      type: "consent",
    })
      .then(() => {
        setOtpFieldVisible(false);
        setIsConsentPopupVisible(false);
        showNotification(
          "success",
          "You got the patient's consent",
          "",
          "topRight"
        );
        navigate("/patients");
      })
      .catch((err: any) => {
        showNotification("error", err.response.data.message, "", "topRight");
      });
  };

  return (
    <div className="bg-white ">
      <div className="grid w-full gap-4 p-4 lg:grid-cols-2">
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("first_name")} <span className="text-red-500">*</span>
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              placeholder=""
              required
              name="firstName"
              value={patient.firstName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z]*$/.test(value)) {
                  updateField("firstName", e.target.value)}
                }
              }
            />
            {errors?.lastName && (
              <span className="mt-2 text-xs text-red-600">
                {errors?.firstName}
              </span>
            )}
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("middle_name")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              placeholder=""
              value={patient.middleName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z]*$/.test(value)) {
                  updateField("middleName", e.target.value)}
                }
              }
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("last_name")} <span className="text-red-500">*</span>
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              placeholder=""
              value={patient.lastName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z]*$/.test(value)) {
                  updateField("lastName", e.target.value)}
                }
              }
            />
            {errors?.lastName && (
              <span className="mt-2 text-xs text-red-600">
                {errors?.lastName}
              </span>
            )}
          </div>

          <div className="block col-span-1 lg:col-span-2">
            <span className="text-sm font-medium text-gray-700">
              {t("gender")}
            </span>
            <div className="space-x-4 space-y-4 sm:flex sm:items-center xl:space-x-10 sm:space-y-0">
              {genders.map((gender) => (
                <div key={gender.id} className="flex items-center">
                  <input
                    id={gender.id}
                    name="notification-method"
                    value={gender.id}
                    type="radio"
                    checked={gender.id === patient.gender}
                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                    onChange={(e) => updateField("gender", e.target.value)}
                  />
                  <label
                    htmlFor={gender.id}
                    className="block ml-3 text-sm font-medium leading-6 text-gray-900"
                  >
                    {gender.title}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="block">
            <div className="flex justify-between">
              <span className="text-sm font-medium text-gray-700">
                {t("phone")} <span className="text-red-500">*</span>
              </span>
              <span
                onClick={() => setIsConsentPopupVisible(true)}
                className="text-sm font-normal text-yellow-600 underline hover:cursor-pointer"
              >
                Consent?
              </span>
            </div>

            <input
              type="text"
              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              placeholder=""
              name="phone"
              maxLength={10}
              value={patient.phone}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\+?[0-9]*$/.test(value)) {
                  updateField("phone", e.target.value)
                }
              }}
            />
            {errors?.phone && (
              <span className="mt-2 text-xs text-red-600">{errors?.phone}</span>
            )}
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("blood_group")}
            </span>
            <div className="mt-1">
              <Select
                className="w-full "
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onBloodGroupChange}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                value={patient.bloodGroup}
                options={bloodGroups}
                size="large"
              />
            </div>
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("email")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              placeholder=""
              value={patient.email}
              onChange={(e) => updateField("email", e.target.value)}
            />
            {errors?.email && (
              <span className="mt-2 text-xs text-red-600">{errors?.email}</span>
            )}
          </div>

          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("birth_date")}
            </span>
            <input
              id="date"
              name="date"
              type="date"
              value={patient.birthDate}
              onChange={(e) => handleAge(e.target.value)}
              max={new Date().toISOString().split("T")[0]}
              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              placeholder=""
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("age")}
            </span>
            <input
              type="text"
              value={patient.age}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\+?[0-9]*$/.test(value)) {
                  updateField("age", e.target.value)
                }
              }}
              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              placeholder=""
            />
             {errors?.age && (
              <span className="mt-2 text-xs text-red-600">{errors?.age}</span>
            )}
          </div>
          <div className="block">
            <div className="flex justify-between item-center">
              <span className="text-sm font-medium text-gray-700">
                {t("referred_by_doctor")}
              </span>
              <button
                type="button"
                onClick={() => setReferredDoctorModalOpen(true)}
                className="px-2 text-sm text-center text-white rounded-md bg-primary "
              >
                {t("add")}
              </button>
            </div>
            <select
              className="w-full max-w-xl pb-1 mt-1 border-gray-300 rounded-lg"
              name="selectedDoctor"
              id="referredDoctor"
              value={patient.referredBy}
              onChange={(e) => handleReferredDoctorChange(e.target.value)}
            >
              <option
                className="text-sm font-medium text-gray-500"
                value=""
                disabled
              >
                {"select referred doctor"}
              </option>
              {doctors.map((doctor: any) => (
                <option key={doctor.id} value={doctor.id}>
                  {doctor.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
          <div className="block col-span-2 lg:col-span-3">
            <span className="text-sm font-medium text-gray-700">
              {t("address")}
            </span>
            <textarea
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              placeholder=""
              value={patient.address}
              onChange={(e) => updateField("address", e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="block col-span-2 ">
            <span className="text-sm font-medium text-gray-700">
              {t("additional_details")}
            </span>
            <Editor
              editorState={commentsEditorState}
              toolbarClassName="border border-gray-300 rounded-md"
              wrapperClassName="h-auto"
              editorClassName="h-full border border-gray-300 rounded-md p-2"
              onEditorStateChange={(editorState) =>
                onEditorStateChange(
                  editorState,
                  "comments",
                  setCommentsEditorState
                )
              }
              toolbar={{
                options: ["inline", "list", "blockType"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
              }}
            />
          </div>
        </div>
      </div>
      {isReferredDopctorModalOpen && (
        <Modal
          modalContent={
            <AddReferredDoctor closeModal={() => handleCloseModal()} />
          }
        />
      )}
      {isConsentPopupVisible && (
        <Modal
          title="Patient's Consent"
          onClose={() => setIsConsentPopupVisible(false)}
          modalContent={
            <div className="p-2">
              <span className="text-sm">
                If patient's record already exists in the system and if you
                don't have access to it, you will have to seek patient's consent
                in order to access his/her record. Please confirm his/her mobile
                number below to send him the consent form.
              </span>
              <div className="block mt-2">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Phone
                </label>
                <input
                  type="text"
                  placeholder="Phone"
                  value={patient.phone}
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                />
              </div>

              {validationErrors && validationErrors.phone && (
                <span className="text-sm text-red-500 leading-0">
                  {validationErrors.phone}
                </span>
              )}
              {isVisibleOtpField && (
                <div className="block mt-2">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Enter OTP
                  </label>

                  <input
                    id="otp"
                    name="otp"
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    className="block w-full max-w-lg mt-1 border-gray-300 rounded-md shadow-sm form-control focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 sm:text-sm"
                  />
                  {validationErrors && validationErrors?.otp && (
                    <p className="mt-2 text-sm text-red-600">
                      {validationErrors?.otp}
                    </p>
                  )}
                </div>
              )}

              <div className="flex justify-end gap-2 mt-2 ">
                {isVisibleOtpField ? (
                  <button
                    onClick={() => handleVerifyOtp()}
                    type="submit"
                    className="px-3 py-2 ml-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
                  >
                    Verify OTP
                  </button>
                ) : (
                  <button
                    onClick={() => handleSendOtp()}
                    className="px-3 py-2 text-white rounded-md bg-primary"
                  >
                    Send OTP
                  </button>
                )}
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default BasicProfile;
