import { ChangeEvent, useEffect, useState } from "react";
import { getVitals } from "../../apis/vitals";
import dragHandle from "../../assets/images/icons/dragHandle.svg";
import { Vital } from "../../interfaces/LayoutComponent";

const VitalList: React.FC<{
  onDragStart: (vital: Vital) => void;
}> = ({ onDragStart }) => {
  const [vitals, setVitals] = useState<Array<Vital>>([]);
  const [filteredVitals, setFilteredVitals] = useState<Array<Vital>>([]);

  useEffect(() => {
    fetchVitals();
  }, []);

  const fetchVitals = async () => {
    const { data } = await getVitals();
    setVitals(data);
    setFilteredVitals(data);
  };

  const filterVitals = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedVitals = vitals.filter((i) =>
      i.label.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredVitals(selectedVitals);
  };

  const dragStarted = (vital: Vital) => {
    onDragStart(vital);
  };

  return (
    <div className="flex flex-col p-2 max-h-[75vh] space-y-2 overflow-y-scroll h-content">
      <div className="flex">
        <input
          type="text"
          className="w-full py-2 border-gray-400 rounded-md"
          placeholder="Search..."
          onChange={(e) => {
            filterVitals(e);
          }}
        />
      </div>
      {filteredVitals.map((vital) => (
        <div
          onDragStart={() => dragStarted(vital)}
          key={vital.id}
          draggable={true}
          className="flex droppable-element items-center text-sm border-[1px] border-solid border-[#ccc] rounded-md bg-white min-h-[60px] mt-2"
        >
          <div className="flex items-center justify-between w-full p-2">
            <span>{vital.label}</span>
            <span>
              <img className="h-6" src={dragHandle} alt="handle" />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VitalList;
