import React, { useEffect, useState } from "react";
import MultiSelect from "../../components/MultiSelect";
import { Option } from "../../interfaces/MultiSelectTypes";
import { Visit, VisitService } from "../../interfaces/Visit";
import { updateField } from "../../utils/StateUtil";
import { useAppDispatch } from "../../hooks";
import { getServices } from "../../apis/service";
type ServiceSelectProps = {
  visit: Visit;
  onCreate: (value: string) => void;
};
const ServiceSelect: React.FC<ServiceSelectProps> = ({ visit, onCreate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [services, setServices] = useState<Array<VisitService>>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchServices();
  }, []);

  const handleSearch = (search: string) => {
    setSearchTerm(search);
    fetchServices(search);
  };

  const fetchServices = async (search: string = "") => {
    try {
      const {
        data: { data: response },
      } = await getServices({ name: search });
      setServices(response);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleServiceSelect = (option: Option) => {
    if (
      !visit.services.some((selected) => {
        return selected.id.toString() === option.value;
      })
    ) {
      const [service] = services.filter(
        (service) => service.id.toString() === option.value
      );

      updateField(
        "services",
        [
          ...visit.services,
          {
            id: service.id,
            name: service.name,
            fee: service.fee,
            quantity: 1,
          },
        ],
        dispatch
      );
    }
  };

  const handleServiceRemoval = (option: Option) => {
    const updatedServices = visit.services.filter(
      (selected) => selected.id.toString() !== option.value
    );
    updateField("services", updatedServices, dispatch);
  };

  return (
    <MultiSelect
      onAddNewOption={() => onCreate(searchTerm)}
      selectedOptions={visit.services.map((i) => ({
        label: i.name,
        value: i.id.toString(),
      }))}
      options={services.map((i) => ({ label: i.name, value: i.id.toString() }))}
      isOpen={isOpen}
      searchTerm={searchTerm}
      onSearch={handleSearch}
      onOptionSelect={handleServiceSelect}
      onOptionRemove={handleServiceRemoval}
      toggleDropdown={toggleDropdown}
    />
  );
};

export default ServiceSelect;
