import React, { memo } from "react";
import AmsaInput from "./AmsaInput";
import AmsaStaticSelect from "./AmsaStaticSelect";
import AmsaDateInput from "./AmsaDateInput";
import AmsaEditor from "./AmsaEditor";
import FasciaChart from "../../components/FasciaChart";
import SymptomSelect from "../visits/SymptomSelect";
import { Visit } from "../../interfaces/Visit";
import DiseaseSelect from "../visits/DiseaseSelect";
import MedicineSelect from "../visits/MedicineSelect";
import MedicineInstructions from "../visits/MedicineInstructions";
import VisitFiles from "../visits/VisitFiles";


const componentRegistry: { [key: string]: React.FC<any> } = {
  AmsaInput,
  AmsaStaticSelect,
  AmsaDateInput,
  AmsaEditor,
  FasciaChart,
  SymptomSelect,
  DiseaseSelect,
  MedicineSelect,
  MedicineInstructions,
  VisitFiles,
};

interface DynamicComponentRendererProps {
  gridItem: any;
  setValue: any;
  shouldFocus?: boolean;
  visit?: Visit;
}

const DynamicComponent: React.FC<DynamicComponentRendererProps> = ({
  gridItem,
  setValue,
  shouldFocus,
  visit,
}) => {
  // Check if the component with the given name exists in the registry
  const Component = componentRegistry[gridItem.component];

  if (!Component) {
    return <div>Component {gridItem.component} not found.</div>;
  }

  return (
    <Component
      visit={visit}
      data={gridItem.data}
      {...gridItem.data.props}
      setValue={setValue}
      shouldFocus={shouldFocus}
    />
  );
};

export default memo(DynamicComponent);
