import { message, Select, Upload } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  getSettings,
  updateDefaultDoctor,
  updateSettings,
} from "../../apis/setting";
import { Setting } from "../../interfaces/Setting";
import { useNotification } from "../../NotificationContext";
import Modal from "../../components/Modal";
import { getUsers } from "../../apis/user";
import { LanguageContext } from "../../providers/LanguageProvider";
import {
  addClinicVisitType,
  getClinicVisitTypes,
  getVisitTypes,
  removeClinicVisitType,
} from "../../apis/visit";
import AddSettings from "./AddSettings";
import Subscription from "./Subscription";
import {
  RazorpayOptions,
  RazorpayResponse,
  Upgrade,
} from "../../interfaces/Transaction";
import { Clinic } from "../../interfaces/User";
import AddOn from "./AddOn";
import TranslationList from "./TranslationList";
import { useNavigate } from "react-router-dom";
import { RAZORPAY_KEY } from "../../constants";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

// import { userAgent } from "next/server";

const SettingsList = () => {
  const [settings, setSettings] = useState<Array<Setting>>([]);
  const [clinics, setClinics] = useState<any>({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [editableField, setEditableField] = useState<string>();
  const [isValid, setIsValid] = useState(true);
  const { showNotification } = useNotification();
  const [tabs] = useState([
    "General",
    "Accounts",
    "Subscriptions",
    "Addons",
    "Translations",
    "Prescriptions",
  ]);
  const [selectedTab, setSelectedTab] = useState("General");

  useState<boolean>(false);
  const [isSettingsModalOpen, setSettingsModalVisibility] =
    useState<boolean>(false);
  const [doctorOnPrescription, setDoctorOnPrescription] =
    useState<any>("logged_in_user");
  const [selectedDefaultDoctor, setSelectedDefaultDoctor] = useState<any>(null);
  const [prescription, setPrescription] = useState("default_header");
  const [headerOption, setHeaderOption] = useState(null);
  const [doctors, setDoctors] = useState<Array<any>>([]);
  const [visitTypes, setVisitTypes] = useState([]);
  const [selectedVisitTypes, setSelectedVisitTypes] = useState([]);
  const [notificationMedium, setNotificationMedium] = useState<any>("");
  const { translate: t } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [, setFile] = useState<any>();
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);

  const prescriptionOptions = [
    { id: "logged_in_user", title: "Logged In User" },
    { id: "default", title: "Default Doctor" },
  ];

  const notificationMediumOptions = [
    { id: "text", title: "Text" },
    { id: "whatsapp", title: "WhatsApp" },
    { id: "both", title: "Both" },
  ];

  const prescriptionTabOptions = [
    {
      id: "no_header",
      title: "No Header (Print Only the Prescription Content)",
      description:
        "Use this option if you already have printed letterheads. Only the prescription details will be printed, without any additional headers.",
    },
    {
      id: "default_header",
      title: "Default Header (Generated by the App)",
      description:
        "Automatically adds a header using your clinic's information (e.g., Clinic Name, Doctor's Name, Address, and Phone).",
    },
    {
      id: "custom_header_image",
      title: "Custom Header Image",
      description:
        "Upload an image of your own letterhead, and it will be used as the header on your printed prescriptions.",
    },
  ];

  const [isHovered, setIsHovered] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    fetchSettings();
    fetchVisitTypes();
    fetchClinicVisitTypes();
    fetchActiveClinic();
  }, []);

  useEffect(() => {
    fetchDoctors({ role: "doctor" });
  }, []);

  useEffect(() => {
    const notificationSetting = settings.find(
      (setting) => setting.code === "notification_medium"
    );

    if (notificationSetting) {
      setNotificationMedium(notificationSetting.value);
    }
  }, [settings]);

  //fetching all visit types in db
  const fetchVisitTypes = () => {
    getVisitTypes().then((res: any) => {
      setVisitTypes(res);
    });
  };

  //fetching only clinic's visit types
  const fetchClinicVisitTypes = () => {
    getClinicVisitTypes().then((res: any) => {
      setSelectedVisitTypes(res.data.map((i: any) => i.visitType.id));
    });
  };

  const handleOnVisitTypeChange = (item: any) => {
    if (selectedVisitTypes.length < item.length) {
      setSelectedVisitTypes(item);
    }
  };

  const handleRecentlySelected = (item: any) => {
    addClinicVisitType({ visitTypeId: item }).then((res: any) => {
      fetchClinicVisitTypes();
    });
  };

  const handleDeselectVisitType = (item: any) => {
    removeClinicVisitType(item)
      .then((res) => {
        showNotification(
          "success",
          "Removed Visit Type Successfully",
          "",
          "topRight"
        );
      })
      .catch((err) => {
        showNotification("error", err.message, "", "topRight");
      });
    fetchClinicVisitTypes();
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setCopied(false); // reset the copied state
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
    });
  };

  const fetchSettings = () => {
    getSettings().then((res: any) => {
      const [result] = res.data.data.filter(
        (i: any) => i.code === "doctor_on_prescription"
      );

      if (result && result.value !== "logged_in_user") {
        setDoctorOnPrescription("default");
        setSelectedDefaultDoctor(result.value);
      }
      setSettings(res.data.data);
    });
  };

  const fetchActiveClinic = () => {
    const userObj = window.localStorage.getItem("user");
    if (userObj) {
      const user = JSON.parse(userObj);
      const activeClinic = user.activeClinic;
      const clinicObj = {
        plan: activeClinic.plan,
        whatsappBalance: activeClinic.whatsappBalance,
        textBalance: activeClinic.textMessageBalance,
      };
      setClinics(clinicObj);
    }
  };

  const handleCloseSettingsModal = () => {
    setSettingsModalVisibility(false);
    fetchSettings();
  };

  const updateSettingsItem = (item: Setting, value: string) => {
    const updatedSettings = settings.map((setting) => {
      if (setting.id === item.id) {
        setting.value = value;
      }
      return setting;
    });
    setSettings(updatedSettings);
  };

  const updateSettingsData = (item: Setting) => {
    if (!item.value) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    if (isValid) {
      setEditableField("");
      updateSettings({
        id: item.id,
        ...item,
      }).then((res) => {
        showNotification(
          "success",
          "Setting Updated successfully",
          "",
          "topRight"
        );
        fetchSettings();
      });
    }
  };

  const changeTab = (value: any) => {
    setSelectedTab(value);
  };

  const fetchDoctors = (params = {}) => {
    getUsers(params).then((res: any) => {
      setDoctors(res.data);
    });
  };

  const updateDefaultDoctorSetting = (value: any) => {
    updateDefaultDoctor({ value }).then((res: any) => {
      showNotification(
        "success",
        "Doctor on prescription setting updated successfully",
        "",
        "topRight"
      );
      fetchSettings();
    });
  };

  const handlePrescriptionDoctorChange = (value: string) => {
    setDoctorOnPrescription(value);
    if (value === "logged_in_user") {
      updateDefaultDoctorSetting(value);
    }
  };

  const handleDefaultDoctorChange = (value: any) => {
    setSelectedDefaultDoctor(value);
    updateDefaultDoctorSetting(value);
  };

  const doctorOnPrescriptionRadio = (
    <div className="mt-2 space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
      {prescriptionOptions.map((option) => (
        <div key={option.id} className="flex items-center">
          <input
            id={option.id}
            type="radio"
            name="prescriptionDoctor"
            value={option.id}
            checked={doctorOnPrescription === option.id}
            className="text-primary focus:ring-primary"
            onChange={(e) => handlePrescriptionDoctorChange(e.target.value)}
          />
          <label className="ml-2 text-sm font-medium text-gray-700">
            {option.title}
          </label>
        </div>
      ))}
    </div>
  );

  const handleNotificationMediumChange = (value: string) => {
    setNotificationMedium(value);
    const updatedSettings = settings.map((setting) =>
      setting.code === "notification_medium" ? { ...setting, value } : setting
    );
    setSettings(updatedSettings);
    const updatedSetting = updatedSettings.find(
      (s) => s.code === "notification_medium"
    );
    if (updatedSetting) {
      updateSettingsData(updatedSetting);
    }
  };

  const notificationMediumRadio = (
    <div className="mt-2 space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
      {notificationMediumOptions.map((option) => (
        <div key={option.id} className="flex items-center">
          <input
            id={option.id}
            type="radio"
            name="notificationMedium"
            value={option.id}
            checked={notificationMedium === option.id}
            className="text-primary focus:ring-primary"
            onChange={(e) => handleNotificationMediumChange(e.target.value)}
          />
          <label className="ml-2 text-sm font-medium text-gray-700">
            {option.title}
          </label>
        </div>
      ))}
    </div>
  );

  const handlePrescriptionChange = (value: any) => {
    setPrescription(value);
  };

  const prescriptionRadio = (
    <div className="p-4 bg-white rounded-lg">
      <h3 className="text-sm font-medium text-gray-700">
        <b> Choose your prescription header style</b>
        <p className="max-w-2xl text-sm text-gray-500">
          Please select how you'd like your header to appear on printed
          prescriptions:
        </p>
      </h3>
      <div className="mt-3 space-y-4">
        {prescriptionTabOptions.map((option) => (
          <label key={option.id} className="block">
            <input
              id={option.id}
              type="radio"
              name="prescription"
              value={option.id}
              checked={prescription === option.id}
              className="mr-2 text-primary ring-offset-2 focus:ring-primary"
              onChange={() => handlePrescriptionChange(option.id)}
            />
            <span className="text-sm text-gray-900 ">{option.title}</span>
            <p className="ml-6 text-sm text-gray-500">{option.description}</p>
          </label>
        ))}
      </div>
    </div>
  );

  const beforeUploadingFile = (file: RcFile) => {
    setFileError(null);
    const isPNG = file.type === "image/png";
    const isJPG = file.type === "image/jpeg";
    const isAcceptedFileType = isPNG || isJPG;

    if (!isAcceptedFileType) {
      setFileError("Only JPG or PNG files are allowed.");
      return Upload.LIST_IGNORE;
    }

    const url = URL.createObjectURL(file);
    const img = new Image();
    img.src = url;

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      if (aspectRatio !== 4 / 1) {
        setFileError("Required aspect ratio is 4:1.");
        URL.revokeObjectURL(url);
        return;
      }
      setFileError(null);
      setFileUrl(file.name);
      showNotification(
        "success",
        "File uploaded successfully.",
        "",
        "topRight"
      );
      URL.revokeObjectURL(url);
    };

    return isAcceptedFileType;
  };

  const handleFileChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setLoading(true);
    setFile(info.file);
    setLoading(false);
  };

  const UploadFileButton = () => (
    <div className="p-4 cursor-pointer">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8, width: 100 }}>Upload</div>
    </div>
  );

  const customFileSetter = (arg: any) => {
    setFile(arg.file);
  };

  const uploadImage = (
    <div>
      {prescription === "custom_header_image" && (
        <div className="p-4 mt-2 border-t">
          <label className="block mt-4 text-sm font-medium text-gray-700">
            Upload Your Prescription Header
          </label>
          <div className="mt-2 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200">
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUploadingFile}
                onChange={handleFileChange}
                customRequest={customFileSetter}
                multiple={true}
              >
                {fileUrl ? (
                  <div className="uploaded-file-name">{fileUrl}</div>
                ) : (
                  <UploadFileButton />
                )}
              </Upload>
              {fileError && (
                <p className="mt-2 text-sm text-red-600">{fileError}</p>
              )}
              <p className="mt-2 text-sm text-gray-500">
                <span className="text-red-500">*</span> Image should be JPG, PNG
              </p>
              <p className="text-sm text-gray-500">
                <span className="text-red-500">*</span> Required ratio 4:1
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const renderItemContent = (item: Setting) => {
    if (item.code === "doctor_on_prescription") {
      return <div className="mt-1">{doctorOnPrescriptionRadio}</div>;
    }

    if (item.code === "notification_medium") {
      return <div className="mt-1">{notificationMediumRadio}</div>;
    }

    if (editableField === item.code) {
      return (
        <input
          name="code"
          type="text"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder=""
          value={item?.value}
          onChange={(e) => updateSettingsItem(item, e.target.value)}
          required
        />
      );
    }

    if (item.code === "location") {
      return (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => copyToClipboard(item.value)}
          className="relative w-2/3 p-2 break-words bg-gray-200 rounded-md text-wrap"
        >
          <span className="cursor-pointer">{item.value}</span>
          {/* Hover text */}
          {isHovered && !copied && (
            <div className="absolute left-0 p-1 mt-1 text-xs text-gray-600 bg-gray-100 border rounded shadow-md top-full">
              Click to copy
            </div>
          )}

          {/* Copied text */}
          {copied && (
            <div className="absolute left-0 p-1 mt-1 text-xs text-green-600 bg-gray-100 border rounded shadow-md top-full">
              Copied!
            </div>
          )}
        </div>
      );
    }

    return <span className="capitalize ">{item.value}</span>;
  };

  const renderItem = (item: Setting) => {
    return item.value ? (
      renderItemContent(item)
    ) : (
      <div className="mt-1 text-sm text-red-500">Required</div>
    );
  };

  const loadRazorpayScript = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const checkout = async (
    payload: Upgrade & { identifier: string },
    activeClinic: Clinic
  ) => {
    const isScriptLoaded = await loadRazorpayScript();

    if (!isScriptLoaded) {
      alert("Failed to load Razorpay SDK. Please check your connection.");
      return;
    }
    if (activeClinic) {
      if (!RAZORPAY_KEY) {
        throw new Error("RAZORPAY_KEY is not defined");
      }
      const rzOptions = {
        key: RAZORPAY_KEY,
        name: "Dhanvantaree",
        description: "Clinic Management Systems",
        image: "https://dhanvantaree.com/images/dhanvantaree.png",
        theme: {
          color: "#484FA2",
        },
      };

      const options: RazorpayOptions = {
        ...rzOptions,
        currency: "INR",
        prefill: {
          name: activeClinic.adminFirstName,
          email: activeClinic.email,
          contact: activeClinic.phone,
        },
        ...(payload.name === "essential"
          ? {
              subscription_id: payload.identifier,
            }
          : { order_id: payload.identifier }),

        handler: (response: RazorpayResponse) => {
          setShowSuccessModal(true);
        },
      };
      const rzp1 = new (window as any).Razorpay(options);
      rzp1.on("payment.failed", function (response: any) {
        console.log({
          "Error Code": response.error.code,
          Description: response.error.description,
          Source: response.error.source,
          Step: response.error.step,
          Reason: response.error.reason,
          "Order ID": response.error.metadata.order_id,
          "Payment ID": response.error.metadata.payment_id,
        });
      });
      rzp1.open();
    }
  };

  const handleUpgrade = () => {
    setSelectedTab("Subscriptions");
  };

  const handleBuy = () => {
    setSelectedTab("Addons");
  };

  return (
    <div className="px-4">
      <div className="pt-2 pb-16">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("settings")}
          </h1>
        </div>
        <div className="py-6">
          <div>
            <div className="hidden lg:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8">
                  {tabs.map((item) => (
                    <button
                      key={item}
                      onClick={() => changeTab(item)}
                      className={`${
                        selectedTab === item
                          ? "border-primary text-primary whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                          : "text-gray-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      }`}
                    >
                      {item}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {selectedTab === "General" && (
            <div className="py-6">
              <div>
                <div className="mt-6 divide-y divide-gray-200">
                  <div className="flex justify-between ">
                    <div className="space-y-1">
                      <h3 className="text-sm font-medium leading-6 text-gray-900">
                        <b> {t("organization_details")}</b>
                      </h3>

                      <p className="max-w-2xl text-sm text-gray-500">
                        This information will be displayed in prescription.
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() => setSettingsModalVisibility(true)}
                        className="block float-right px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Add New
                      </button>
                    </div>
                  </div>
                  <div className="px-4 mt-2 bg-white ">
                    {settings.map((item) => (
                      <div key={item.code} className="mt-2">
                        <dl className="divide-y divide-gray-200">
                          {item.isVisible ? (
                            <div className="grid grid-cols-3 py-4 border-b sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500 capitalize ">
                                {item.name}
                              </dt>
                              <dd className="flex items-center justify-between col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                                {renderItem(item)}

                                {item.code !== "doctor_on_prescription" &&
                                  item.code !== "notification_medium" && (
                                    <span className="flex-shrink-0 ml-4">
                                      {editableField !== item.code ? (
                                        <button
                                          type="button"
                                          className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                          onClick={() =>
                                            setEditableField(item.code)
                                          }
                                        >
                                          {t("edit")}
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                          onClick={() =>
                                            updateSettingsData(item)
                                          }
                                        >
                                          {t("update")}
                                        </button>
                                      )}
                                    </span>
                                  )}
                              </dd>
                            </div>
                          ) : (
                            ""
                          )}
                        </dl>
                      </div>
                    ))}

                    {doctorOnPrescription === "default" &&
                      doctors.length > 0 && (
                        <dl className="divide-y divide-gray-200">
                          <div className="py-4 capitalize sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="ml-6 text-sm font-medium text-gray-500">
                              Select doctor
                            </dt>
                            <dd className="flex items-center mt-1 text-sm text-gray-900 sm:mt-0">
                              <div className="mt-2">
                                <select
                                  className="w-full max-w-xl pt-1 pb-1 border-gray-300 rounded-lg sm:text-sm"
                                  name="selectedDoctor"
                                  id=""
                                  value={selectedDefaultDoctor}
                                  onChange={(e) =>
                                    handleDefaultDoctorChange(e.target.value)
                                  }
                                >
                                  {doctors.map((doctor: any) => (
                                    <option key={doctor.id} value={doctor.id}>
                                      {doctor.firstName + " " + doctor.lastName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </dd>
                          </div>
                        </dl>
                      )}
                    <div className="grid grid-cols-3 py-4 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500 capitalize">
                        Speciality
                      </dt>
                      <dd>
                        <Select
                          showSearch
                          mode="multiple"
                          value={selectedVisitTypes}
                          style={{ width: "100%" }}
                          placeholder="Select a Speciality"
                          optionFilterProp="label"
                          onChange={handleOnVisitTypeChange}
                          onSelect={handleRecentlySelected}
                          onDeselect={handleDeselectVisitType}
                          options={visitTypes.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })}
                        />
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
              {isSettingsModalOpen && (
                <Modal
                  modalContent={
                    <AddSettings
                      closeModal={() => handleCloseSettingsModal()}
                    />
                  }
                />
              )}
            </div>
          )}
          {selectedTab === "Accounts" && (
            <div className="py-6">
              <div>
                <div className="mt-6 divide-y divide-gray-200">
                  <div className="flex justify-between ">
                    <div className="space-y-1">
                      <h3 className="text-sm font-medium leading-6 text-gray-900">
                        <b> {t("account_details")}</b>
                      </h3>

                      <p className="max-w-2xl text-sm text-gray-500">
                        Account related information
                      </p>
                    </div>
                  </div>

                  <div className="px-4 mt-2 bg-white divide-y divide-gray-200">
                    <dl className="divide-y divide-gray-200">
                      {Object.keys(clinics).map((key) => (
                        <div
                          key={key}
                          className="grid grid-cols-3 py-4 sm:gap-4"
                        >
                          <dt className="text-sm font-medium text-gray-500 capitalize">
                            {key.replace(/([A-Z])/g, " $1").toLowerCase()}
                          </dt>
                          <dd className="flex items-center justify-between col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                            <span className="capitalize">
                              {clinics[key] !== null ? clinics[key] : "-"}
                            </span>
                            <span className="flex-shrink-0 ml-4">
                              {key === "plan" ? (
                                <button
                                  type="button"
                                  onClick={handleUpgrade}
                                  className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  {t("upgrade")}
                                </button>
                              ) : key === "whatsappBalance" ||
                                key === "textBalance" ? (
                                <button
                                  type="button"
                                  onClick={handleBuy}
                                  className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  {t("buy")}
                                </button>
                              ) : null}
                            </span>
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {selectedTab === "Prescriptions" && (
            <div className="py-6">
              <div>
                <div className="mt-6 divide-y divide-gray-200">
                  <div className="flex justify-between ">
                    <div className="space-y-1">
                      <h3 className="text-sm font-medium leading-6 text-gray-900 capitalize">
                        <b>Prescription Header Options</b>
                      </h3>
                    </div>
                  </div>

                  <div className="px-4 mt-2 bg-white divide-y divide-gray-200">
                    <dl className="divide-gray-200 ">
                      <div className="py-4">{prescriptionRadio}</div>
                      <div className="py-4 ">{uploadImage}</div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {selectedTab === "Prescriptions" && (
            <div className="py-6">
              <div>
                <div className="mt-6 divide-y divide-gray-200">
                  <div className="flex justify-between">
                    <div className="space-y-1">
                      <h3 className="text-sm font-medium leading-6 text-gray-900 capitalize">
                        <b>Prescription Header Options</b>
                      </h3>
                    </div>
                  </div>
                  <div className="px-4 mt-2 bg-white divide-y divide-gray-200">
                    <dl className="divide-gray-200">
                      <div className="py-4">{prescriptionRadio}</div>
                      <div className="py-4">{uploadImage}</div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === "Translations" && <TranslationList />}{" "}
          {selectedTab === "Subscriptions" && (
            <Subscription onCheckout={checkout} />
          )}
          {selectedTab === "Addons" && <AddOn onCheckout={checkout} />}
        </div>
      </div>
      {showSuccessModal && (
        <Modal
          title="Payment Success"
          onClose={() => setShowSuccessModal(false)}
          modalContent={
            <div className="block">
              <p>
                We’ve received your payment and are processing the necessary
                changes to your account. This may take a minute. Thank you for
                your patience and understanding.
              </p>
              <div className="flex justify-end">
                <button
                  onClick={() => setShowSuccessModal(false)}
                  className="px-3 py-1.5 border bg-white text-gray-900 rounded-md "
                >
                  Okay
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
export default SettingsList;
