import React from "react";
import { Event } from "../../interfaces/Event";
import { format } from "date-fns";

const DaysEvents: React.FC<{
  events: Array<Event> | null;
  date: any;
  closeModal: () => void;
  handleEventFormVisibility: () => void;
}> = ({ events, date, closeModal, handleEventFormVisibility }) => {
  return (
    <div className="">
      <div className="flex items-center justify-between">
        <span className="font-medium">
          {" "}
          Events
          <span className="ml-2 font-medium">
            ({format(new Date(date), "dd MMM yyyy")})
          </span>
        </span>

        <button
          onClick={() => handleEventFormVisibility()}
          type="button"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          Create New Event
        </button>
      </div>

      <div className="mt-4 border-t border-gray-100">
        <dl className="text-center divide-gray-100">
          {events && events?.length > 0 ? (
            events?.map((event: any, index: number) => (
              <div className="flex px-4 py-4 border-b-2 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {index + 1}.
                </dt>
                <div className="flex flex-col mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                  <div className="flex">
                    <span className="font-bold capitalize">{event?.title}</span>
                  </div>
                  <div className="flex">
                    <span className="pr-2 font-bold">Timing: </span>
                    <span className="capitalize">
                      {format(new Date(event?.startDate), "hh:mm a")}
                    </span>
                    {event && event.endDate !== null && (
                      <span>
                        - {format(new Date(event?.endDate), "hh:mm a")}
                      </span>
                    )}
                  </div>
                  <div className="flex">
                    <span className="capitalize">{event.description}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex p-4">
              <span className="w-full text-center">No Events Found!</span>
            </div>
          )}
        </dl>
      </div>

      <div className="flex items-center justify-end w-full pt-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => closeModal()}
          type="button"
          className="px-3 py-2 text-sm font-semibold text-gray-900 border rounded-md shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DaysEvents;
