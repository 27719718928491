import React, { useState } from "react";
import { Upgrade } from "../../interfaces/Transaction";
const UpgradePlanModal: React.FC<{
  selectedPlan: Upgrade;
  onUpgrade: (plan: Upgrade) => void;
  closeModal: () => void;
}> = ({ selectedPlan, onUpgrade, closeModal }) => {
  const discount = 10;
  const [form, setForm] = useState<Upgrade>(selectedPlan);
  const visitOptions = [10, 50, 200, 500, 1000];
  const [isLoader, setLoader] = useState<boolean>(false);

  return (
    <div>
      <div className="font-medium">Upgrade Plan</div>
      <div className="space-y-2">
        <div className="py-2 text-base text-gray-700">
          Take your experience to the next level!
        </div>
        {form.name === "essential" && (
          <>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Number of doctors
              </label>
              <div className="mt-1">
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={form.quantity}
                  onChange={(e) =>
                    setForm({ ...form, quantity: parseInt(e.target.value) })
                  }
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Select appropriate plan which suits your requirement.
              </label>
            </div>
            <div className="space-y-4 col-span-full">
              <label
                className={
                  form.frequency === "monthly"
                    ? "border-primary ring-1 ring-primary relative block cursor-pointer rounded-lg border-2 bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
                    : "border-transparent relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
                }
              >
                <input
                  type="radio"
                  name="server-size"
                  value="Monthly"
                  className="sr-only"
                  onChange={() =>
                    setForm({
                      ...form,
                      frequency: "monthly",
                    })
                  }
                />
                <span className="flex items-center ">
                  {form.frequency === "monthly" && (
                    <span className="mr-2">
                      <svg
                        className="w-5 h-5 text-primary"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}

                  <span className="flex flex-col text-sm">
                    <span className="font-medium text-gray-900">
                      Essential (Monthly)
                    </span>
                    <span className="text-gray-500">
                      <span className="block sm:inline">
                        ₹{form.price} x {form.quantity} Doctor
                      </span>
                    </span>
                  </span>
                </span>
                <span className="flex mt-2 text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right">
                  <span className="font-medium text-gray-900">
                    ₹{form.price * form.quantity}
                  </span>
                  <span className="ml-1 text-gray-500 sm:ml-0">/mo</span>
                </span>
                <span
                  className="absolute border-2 rounded-lg pointer-events-none -inset-px"
                  aria-hidden="true"
                ></span>
              </label>
              <label
                className={
                  form.frequency !== "monthly"
                    ? "border-primary ring-1 ring-primary relative block cursor-pointer rounded-lg border-2 bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
                    : "border-transparent relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
                }
              >
                <input
                  type="radio"
                  name="server-size"
                  value="Annual"
                  className="sr-only"
                  onChange={() =>
                    setForm({
                      ...form,
                      frequency: "annual",
                    })
                  }
                />
                <span className="flex items-center">
                  {form.frequency !== "monthly" && (
                    <span className="mr-2">
                      <svg
                        className="w-5 h-5 text-primary"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                  <span className="flex flex-col text-sm">
                    <span className="font-medium text-gray-900">
                      Essential (Annual)
                      <span className="block ml-1 sm:inline">
                        {discount}% Discount
                      </span>
                    </span>

                    <span className="text-gray-500">
                      <span className="block sm:inline">
                        ₹{(form.price * (100 - discount)) / 100} x{" "}
                        {form.quantity} Doctor(s) x 12 months
                      </span>
                    </span>
                  </span>
                </span>
                <span className="flex mt-2 text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right">
                  <span className="font-medium text-gray-900">
                    ₹{form.price * 12 * form.quantity * 0.9}
                  </span>
                  <span className="ml-1 text-gray-500 sm:ml-0">/year</span>
                </span>
                <span
                  className="absolute border-2 rounded-lg pointer-events-none -inset-px"
                  aria-hidden="true"
                ></span>
              </label>
            </div>
          </>
        )}
        {form.name === "flexivisit" && (
          <>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Select the number of visits which suits your requirement.
            </p>
            <fieldset aria-label="Choose a number of visits">
              <div className="grid grid-cols-3 gap-3 mt-2 sm:grid-cols-6">
                {visitOptions.map((number) => (
                  <label
                    key={number}
                    className={`flex items-center justify-center px-3 py-3 text-sm font-semibold uppercase border border-gray-300 rounded-md cursor-pointer focus:outline-none sm:flex-1 text-gray-900 hover:bg-hover hover:text-white ${
                      form.quantity === number ? "bg-primary text-white" : ""
                    }`}
                    onChange={() =>
                      setForm({
                        ...form,
                        quantity: number,
                      })
                    }
                  >
                    <input
                      type="radio"
                      name="visit-number"
                      value={number}
                      className="sr-only"
                    />
                    <span>{number}</span>
                  </label>
                ))}
              </div>
            </fieldset>
          </>
        )}
      </div>
      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10">
        <button
          onClick={() => {
            if (isLoader !== true) {
              setLoader(true);
              onUpgrade(form);
            }
          }}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {isLoader ? (
            <>
              <div className="inline-block w-4 h-4 pl-2 border-2 border-t-2 border-white rounded-full border-t-transparent animate-spin"></div>
              <span className="ml-2">Loading</span>
            </>
          ) : (
            <span>Upgrade</span>
          )}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UpgradePlanModal;
