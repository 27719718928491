import React from "react";
import { buyAddon } from "../../apis/setting";
import { Upgrade } from "../../interfaces/Transaction";
import { Clinic } from "../../interfaces/User";
const AddOn: React.FC<{
  onCheckout: (
    payload: Upgrade & { identifier: string },
    activeClinic: Clinic
  ) => void;
}> = ({ onCheckout }) => {
  const addOns = [
    {
      name: "whatsapp add-on",
      code: "whatsapp_addon",
      price: "499",
      features: [
        "Send Appointment Details",
        "Send Reminders",
        "Share Prescriptions",
        "Marketing Messages",
      ],
    },
    {
      name: "text add-on",
      code: "text_message_addon",
      price: "349",
      features: [
        "Send Appointment Details",
        "Send Reminders",
        "Share Prescriptions",
        "Marketing Messages",
      ],
    },
    {
      name: "customization",
      code: "customization_addon",
      price: "2499",
      features: [
        "Migrate old data",
        "Custom Prescription Design",
        "Share Prescriptions",
        "Customized Application flow",
      ],
    },
  ];

  const createOrder = async (name: string, quantity: number) => {
    try {
      const userObj = window.localStorage.getItem("user");
      if (userObj) {
        const user = JSON.parse(userObj);
        const activeClinic = user.activeClinic;
        const { data } = await buyAddon({ name, quantity });
        onCheckout(
          { price: 0, name, quantity, type: "addon", identifier: data.orderId },
          activeClinic
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="px-4 py-6 bg-white border min-h-[calc(100vh-400px)]">
      <span className="text-lg font-medium">Add Ons</span>
      <div className="pt-5 lg:grid lg:grid-cols-3 lg:gap-2">
        {addOns &&
          addOns.length > 0 &&
          addOns.map((item, index) => (
            <div className="flex flex-col overflow-hidden border border-gray-300 rounded-lg">
              <div className="px-6 py-3 bg-white sm:p-6 sm:pb-3">
                <div>
                  <h3
                    id="tier-standard"
                    className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide uppercase bg-indigo-100 rounded-full text-primary"
                  >
                    {item.name}
                  </h3>
                </div>
                <div className="flex items-baseline mt-4 text-2xl font-extrabold text-black">
                  ₹{item.price}
                  <span className="ml-1 text-lg font-medium text-gray-500">
                    {item.code === "whatsapp_addon" ||
                    item.code === "text_message_addon"
                      ? "/1000 msgs"
                      : "onwards"}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-between flex-1 px-6 pb-8 space-y-3 bg-gray-50 sm:p-5">
                <ul className="space-y-2">
                  {item.features.map((feature, index) => (
                    <li className="flex items-start">
                      <svg
                        className="flex-none w-5 h-6 text-primary"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <p className="ml-3 text-base text-gray-700">{feature}</p>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => createOrder(item.code, 1)}
                  aria-describedby="tier-team"
                  className="mt-8 block rounded-md bg-primary px-2.5 py-2.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                >
                  Buy
                </button>
              </div>
            </div>
          ))}
      </div>
      <div className="px-6 py-5">
        <a
          className="text-sm underline text-primary hover:cursor-pointer"
          href="https://dhanvantaree.com/pricing"
          target="_blank"
          rel="noreferrer"
        >
          Get More Details
        </a>
      </div>
    </div>
  );
};

export default AddOn;
