import React, { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { Pagination, PaginationProps } from "antd";
import { LanguageContext } from "../../providers/LanguageProvider";
import { getReferrals } from "../../apis/referral";
import AddReferral from "./AddReferral";

const ReferralsList = () => {
  const [referralRecords, setReferralRecords] = useState<Array<any>>([]);
  const [editableId, setEditableId] = useState(null);
  const [isReferralModalOpen, setReferralModalVisibility] =
    useState<boolean>(false);
  const [meta, setMeta] = useState<any>();
  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    fetchReferrals();
  }, []);

  const fetchReferrals = (params = {}) =>
    getReferrals(params).then((res: any) => {
      setReferralRecords(res.data);
    });

  const handleCloseModal = () => {
    setEditableId(null);
    setReferralModalVisibility(false);
    fetchReferrals({});
  };
  const onPaginationChange: PaginationProps["onChange"] = (page, pageSize) => {
    // fetchSymptoms({ page, per_page: pageSize });
  };

  //   const exportSymptomsList = (params: any) => {
  //     exportSymptoms(params).then((res: any) => {
  //       downloadFile(res.data);
  //     });
  //   };

  //   const downloadFile = (data: any) => {
  //     const blob = new Blob([data], { type: "application/vnd.ms-excel" });
  //     const url = window.URL.createObjectURL(blob);
  //     const downloadLink = document.createElement("a");
  //     downloadLink.href = url;
  //     const today = format(new Date(), "dd_MM_yyyy_hh_mm");
  //     downloadLink.download = "symptoms-" + today + ".xlsx";
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  //   };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex lg:hidden">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("referrals")}
          </h1>
        </div>

      </div>
      <div className="hidden sm:items-center lg:flex">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("referrals")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("referral_description")}
          </p>
        </div>
        <div className="flex mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => setReferralModalVisibility(true)}
            className="block px-3 py-2 ml-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("invite")}
          </button>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 capitalize"
                    >
                      {t("name")}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 capitalize"
                    >
                      {t("email")}/ {t("phone")}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 capitalize"
                    >
                      {t("status")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {referralRecords && referralRecords.length > 0 ? (
                    referralRecords?.map((record) => (
                      <tr key={record.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                          {record?.referredName}
                        </td>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                          <div className="text-gray-900">
                            {record.referredEmail}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {record.referredPhone}
                          </div>
                        </td>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                          {record.status}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={4}
                        className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        {t("Record Not Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end pt-5">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                total={meta?.total}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isReferralModalOpen && (
        <Modal
          modalContent={
            <AddReferral
              // editableId={editableId}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};
export default ReferralsList;
