import {
  changeVisitData,
  UpdateVisitField,
} from "../features/visit/visitSlice";
import { Visit } from "../interfaces/Visit";
import { AppDispatch } from "../store";

export const updateField = (
  fieldName: keyof Visit,
  value: any,
  dispatch: AppDispatch
) => {
  const payload: UpdateVisitField = { fieldName, value };
  dispatch(changeVisitData(payload));
};
