import api from "../middlware/api";
import { BASE_URL } from "../constants";
import { camelToSnake } from "../utils/CamelToSnake";

export const getEventCategories = () => {
    return new Promise((resolve, reject) => {
        api
            .get(`${BASE_URL}/api/v1/event-categories`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const addEvent = (payload: any) => {
    return new Promise((resolve, reject) => {
        api
            .post(`${BASE_URL}/api/v1/events`, camelToSnake(payload))
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getEvents = (params: any) => {
    let query = new URLSearchParams();
    for (let key in params) {
        query.set(key, params[key]);
    }
    return new Promise((resolve, reject) => {
        api.get(`${BASE_URL}/api/v1/events?${query.toString()}`).then((res) => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

export const checkDoctorAvailability = (payload: any) => {
    return api.post(`${BASE_URL}/api/v1/events/doctor-availability`, camelToSnake(payload));
}

export const checkDoctorAvailabilityForPatient = (payload: any) => {
    return api.post(`${BASE_URL}/api/v1/events/check-doctor-availability`, camelToSnake(payload));
}