import { Disorder } from "../interfaces/Disorder";

export const transformVisit = (visit: any) => {
  return {
    id: visit.id,
    parentId: visit.parentId,
    serialNumber: visit.serialNumber,
    date: visit.date,
    clinicUserId: visit.clinicUserId,
    position: visit.position,
    number: visit.number,
    isInpatient: visit.isInpatient,
    patientId: visit.patientId,
    provisionalDiagnosis: transformDisorders(visit.disorders, "provisional"),
    finalDiagnosis: transformDisorders(visit.disorders, "final"),
    roomId: visit.roomId,
    clinicVisitTypeId: visit.clinicVisitTypeId,
    clinicVisitType: visit.clinicVisitType,
    clinicVisitTypeLayoutId: visit.clinicVisitTypeLayoutId,
    services: transformServices(visit.services),
    symptoms: visit.symptoms,
    medicines: transformMedicines(visit.medicines), //converting to array of ids
    patient: visit.patient,
    meta: transformMeta(visit.meta),
    fee: visit.fee,
    discount: visit.discount,
    flag: visit.flag,
    visitFiles: visit.visitFiles,
    visitTransactions: visit.visitTransactions,
    advice: visit.advice,
  };
};

const transformMeta = (data: any) => {
  const meta = {
    height: data.height !== null ? data.height : "",
    weight: data.weight !== null ? data.weight : "",
    layout_content: data.layout_content,
    ...data,
  };
  return meta;
};

const transformMedicines = (medicines: Array<any>) => {
  const result = medicines?.map((i: any) => {
    return {
      id: i.id,
      name: i.name,
      instructions: i.pivot.instructions,
    };
  });
  return result;
};

const transformServices = (services: Array<any>) => {
  // const result = services.map(i => i.id);
  const result = services?.map((i: any) => {
    return {
      id: i.id,
      fee: i.pivot.fee,
      quantity: i.pivot.quantity,
      name: i.name,
    };
  });
  return result;
};

const transformDisorders = (disorders: Array<any>, type: string) => {
  const result = disorders
    .filter((element) => element.pivot.type === type)
    .map((disorder: Disorder) => { return { label: disorder.name, value: disorder.id } });

  return result;
};
