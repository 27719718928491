import { BASE_URL } from "../constants";
import api from "../middlware/api";
import { camelToSnake } from "../utils/CamelToSnake";

export const saveTransaction = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/visit-transactions`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateTransaction = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .put(
        `${BASE_URL}/api/v1/visit-transactions/${payload.id}`,
        camelToSnake(payload)
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTransaction = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/visit-transactions/${payload}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
