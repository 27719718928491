import React, { useContext, useEffect, useState } from "react";
import { getService, saveService, updateService } from "../../apis/service";
import { useNotification } from "../../NotificationContext";
import { Select } from "antd";
import { Tax } from "../../interfaces/Tax";
import { getTaxes } from "../../apis/tax";
import { LanguageContext } from "../../providers/LanguageProvider";

const AddService: React.FC<{
  closeModal: () => void;
  editableId: any;
  text?: string;
}> = ({ closeModal, editableId, text }) => {
  const { showNotification } = useNotification();
  const [taxes, setTaxes] = useState<Array<Tax>>([]);
  const [errors, setErrors] = useState<any>();
  const Option = Select;
  const { translate: t } = useContext(LanguageContext);

  const defaultService = {
    name: text,
    fee: "",
    isDefaultSelected: false,
    isTaxIncluded: false,
    unit: "",
    taxId: null,
  };
  const [service, setService] = useState(defaultService);

  useEffect(() => {
    fetchTaxes({});
    if (editableId) {
      fetchService(editableId);
    }
  }, [editableId]);

  const fetchService = (id: number) => {
    getService(id).then((res: any) => {
      setService(res.data.data);
    });
  };

  const updateServiceData = () => [
    updateService({
      id: editableId,
      ...service,
    }).then((res) => {
      showNotification(
        "success",
        "Service Updated successfully",
        "",
        "topRight"
      );
      closeModal();
    }),
  ];

  const onSave = () => {
    if (editableId) {
      updateServiceData();
    } else {
      addService();
    }
  };

  const addService = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveService({ ...service }).then((res: any) => {
      showNotification("success", "Service added successfully", "", "topRight");
      closeModal();
    });
  };

  const handleTaxChange = (taxId: any) => {
    setService({
      ...service,
      taxId,
    });
  };

  const cancel = () => {
    closeModal();
  };

  const fetchTaxes = (params: {}) => {
    getTaxes(params).then((res: any) => {
      setTaxes(res.data);
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (service?.name === "") {
      validationErrors.name = "* Name is required";
    }
    if (service?.fee === "") {
      validationErrors.fee = "* Fee is required";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  return (
    <div className="">
      <span className="font-medium">
        {editableId ? t("update") : t("add")} {t("services")}
      </span>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("name")}
        </label>
        <div className="mt-1">
          <input
            id="about"
            name="about"
            type="text"
            value={service?.name}
            onChange={(e) => setService({ ...service, name: e.target.value })}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.name && (
          <span className="text-sm text-red-500 leading-0">{errors.name}</span>
        )}
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("fee")}
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
          <input
            type="text"
            name="price"
            id="price"
            className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="0.00"
            value={service?.fee}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[0-9]*$/.test(value)) {
                setService({ ...service, fee: e.target.value })
              }
            }}
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            <select
              id="unit"
              name="unit"
              value={service?.unit}
              onChange={(e) => setService({ ...service, unit: e.target.value })}
              className="h-full py-0 pl-2 text-gray-500 bg-transparent border-0 rounded-md pr-7 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            >
              <option value="unit">Unit</option>
              <option value="hour">Hour</option>
              <option value="day">Day</option>
            </select>
          </div>
        </div>
        {errors && errors.fee && (
          <span className="text-sm text-red-500 leading-0">{errors.fee}</span>
        )}
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("default_selected")}
        </label>
        <div className="mt-1">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="isDefaultSelected"
              checked={service.isDefaultSelected === true ? true : false}
              onChange={() =>
                setService({
                  ...service,
                  isDefaultSelected: true,
                })
              }
              className="text-primary focus:ring-primary"
            />
            <span className="ml-2">Yes</span>
          </label>

          <label className="inline-flex items-center ml-6">
            <input
              type="radio"
              name="iisDefaultSelected"
              checked={service.isDefaultSelected === false ? true : false}
              onChange={() =>
                setService({
                  ...service,
                  isDefaultSelected: false,
                })
              }
              className="text-primary focus:ring-primary"
            />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("tax_included")}
        </label>
        <div className="mt-1">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="isVisible"
              checked={service.isTaxIncluded === true ? true : false}
              onChange={() =>
                setService({
                  ...service,
                  isTaxIncluded: true,
                })
              }
              className="text-primary focus:ring-primary"
            />
            <span className="ml-2">Yes</span>
          </label>

          <label className="inline-flex items-center ml-6">
            <input
              type="radio"
              name="isVisible"
              checked={service.isTaxIncluded === false ? true : false}
              onChange={() =>
                setService({
                  ...service,
                  isTaxIncluded: false,
                })
              }
              className="text-primary focus:ring-primary"
            />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("tax")}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={handleTaxChange}
            value={service.taxId}
          >
            {taxes.map((tax) => (
              <Option key={tax.id} value={tax.id}>
                {tax.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => onSave()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {editableId ? t("update") : t("save")}
        </button>
        <button
          onClick={() => cancel()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};
export default AddService;
