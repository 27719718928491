import React, { useEffect, useState } from "react";
import { Upload, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  UpdateVisitField,
  changeVisitData,
  selectVisit,
} from "../../features/visit/visitSlice";
import { Visit } from "../../interfaces/Visit";
import { useParams } from "react-router-dom";
import { getServices } from "../../apis/service";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { saveVisitFiles } from "../../apis/visit";
import SymptomSelect from "./SymptomSelect";
import DiseaseSelect from "./DiseaseSelect";
const Diagnosis = () => {
  const visit = useAppSelector(selectVisit);
  const params = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>();
  const [, setVisitFile] = useState<any>();

  useEffect(() => {
    if (params.patientId) {
      fetchDefaultServices();
    }
  }, [params]);

  const fetchDefaultServices = () => {
    getServices({ is_default_selected: true, per_page: 100 }).then(
      (res: any) => {
        const services = res.data.data.map((i: any) => {
          return {
            id: i.id,
            fee: i.fee,
            quantity: 1,
          };
        });
        updateField("services", services);
      }
    );
  };
  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };

  /*----------------------------provisional diagnosis's functions------------------------------------*/

  const customFileSetter = (arg: any) => {
    setVisitFile(arg.file);
  };

  const beforeUploadingFile = (file: RcFile) => {
    let isValid = true;
    const isPDF = file.type === "application/pdf";
    const isPNG = file.type === "image/png";
    const isJPG = file.type === "image/jpeg";

    const isAcceptedFileType = isPDF || isPNG || isJPG;

    if (!isAcceptedFileType) {
      isValid = false;

      message.error("You can only upload PDF, PNG, or JPG files!");
    }

    if (!isValid) {
      return Upload.LIST_IGNORE;
    }
    return isAcceptedFileType;
  };

  const handleFileChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setLoading(true);
    setVisitFile(info.file);
    uploadReports(info.file.originFileObj as RcFile);
    setFileUrl(info.file.name);
    setLoading(false);
  };

  const UploadFileButton = () => (
    <div className="cursor-pointer">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8, width: 100 }}>Upload</div>
    </div>
  );

  const getFileName = (file: string) => {
    return file.split("/").pop();
  };

  const uploadReports = (file: any) => {
    saveVisitFiles({ file: file })
      .then((res: any) => {
        const files = [...visit.visitFiles, res.url];
        updateField("visitFiles", files);
        message.success("Files uploaded successfully!");
      })
      .catch((err) => {
        message.error("Failed to upload files!");
      });
  };

  const handleChangeInChiefComplaint = (complaint: string) => {
    updateField("chiefComplaint", complaint);
  };

  return (
    <>
      <div className="grid w-full gap-2 p-2 lg:gap-4 lg:p-4 lg:grid-cols-2">
        <div className="relative grid grid-cols-2 gap-2 py-3 bg-white rounded-lg lg:px-6 lg:gap-4 lg:py-5 lg:border lg:border-gray-300 lg:shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
          <div className="block col-span-2">
            <span className="text-sm font-medium text-gray-700">
              Chief Complaint
            </span>

            <input
              type="text"
              id="chiefComplaint"
              name="chiefComplaint"
              value={visit.chiefComplaint}
              onChange={(e) => handleChangeInChiefComplaint(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
          <div className="block col-span-2">
            <span className="text-sm font-medium text-gray-700">Symptoms</span>
            <SymptomSelect visit={visit} />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              Provisional Diagnosis
            </span>

            <DiseaseSelect
              propertyType="provisionalDiagnosis"
              diseaseType="disease"
              visit={visit}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              Final Diagnosis
            </span>

            <DiseaseSelect
              propertyType="finalDiagnosis"
              diseaseType="disease"
              visit={visit}
            />
          </div>
        </div>

        <div className="relative grid items-center grid-cols-2 gap-2 py-3 bg-white rounded-lg lg:gap-4 lg:px-6 lg:py-5 lg:border lg:border-gray-300 lg:shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
          <div className="block">
            <span className="text-sm font-medium text-gray-700">Reports</span>

            <div className="mt-2 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200">
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Upload
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUploadingFile}
                  onChange={handleFileChange}
                  customRequest={customFileSetter}
                  multiple={true}
                >
                  <UploadFileButton />
                </Upload>
              </div>
            </div>
          </div>
          {visit.visitFiles && visit.visitFiles.length > 0 && (
            <div className="flex-grow block col-span-2">
              <span className="text-sm font-medium text-gray-700">
                Attached Reports
              </span>
              <div className="grid grid-cols-2 mt-2">
                <ul>
                  {visit.visitFiles &&
                    visit.visitFiles?.map((file: any, index: number) => (
                      <li className="m-1 text-sm text-gray-500" key={index}>
                        {file}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Diagnosis;
