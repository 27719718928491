import api from "../middlware/api";
import { BASE_URL } from "../constants";

export const getLogs = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/logs?${query.toString()}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
