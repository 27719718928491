import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../apis/auth";
import { useNotification } from "../NotificationContext";
import SetNewPassword from "./setNewPassword";

// using this page for reset password email redirection
const ResetPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [token, setToken] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const { showNotification } = useNotification();

  useEffect(() => {
    if (search) {
      const searchParams = new URLSearchParams(search);
      if (searchParams.has("token")) {
        setToken(searchParams.get("token"));
      }
      if (searchParams.has("email")) {
        setEmail(searchParams.get("email"));
      }
    }
  }, [search]);

  const handleSubmit = (password: string) => {
    resetPassword({
      token,
      password,
      email,
      password_confirmation: password,
    })
      .then((res: any) => {
        showNotification(
          "success",
          "Password Created successfully",
          "",
          "topRight"
        );
        navigate("/login");
      })
      .catch((err) => {
        showNotification(
          "error",
          "Error Occurred",
          err.data.message,
          "topRight"
        );
      });
  };
  return (
    <div>
      <div className="text-center mt-">
        <SetNewPassword
          title="Reset Password"
          onSubmit={(password) => handleSubmit(password)}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
