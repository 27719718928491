import {
  decrement,
  increment,
  incrementByAmount,
} from "../features/counter/counterSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import React, { useEffect, useState } from "react";

const Counter = () => {
  const count = useAppSelector((state) => state.counter.value);

  const [specific, setSpecific] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(incrementByAmount(specific));
  }, [specific]);

  const addCount = () => {
    dispatch(increment());
  };
  const subTractCount = () => {
    dispatch(decrement());
  };

  const incrementByValue = (value: string) => {
    setSpecific(parseInt(value));
  };

  return (
    <div className="p-2">
      <p>{count}</p>
      <input type="text" onChange={(e) => incrementByValue(e.target.value)} />
      <button onClick={addCount} className="p-2 border-r-2 bg-primary">
        Add
      </button>
      <button onClick={subTractCount} className="p-2 border-r-2 bg-primary">
        Subtract
      </button>
      <button onClick={addCount} className="p-2 border-r-2 bg-primary">
        Add by value
      </button>
    </div>
  );
};

export default Counter;
