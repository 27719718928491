import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import UpgradePlanModal from "./UpgradePlanModal";
import { Upgrade } from "../../interfaces/Transaction";
import { upgradePlan } from "../../apis/setting";
import { Clinic } from "../../interfaces/User";

const Subscription: React.FC<{
  onCheckout: (
    payload: Upgrade & { identifier: string },
    activeClinic: Clinic
  ) => void;
}> = ({ onCheckout }) => {
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [activeClinic, setActiveClinic] = useState<Clinic>();
  const [plans, setPlans] = useState([
    {
      name: "free",
      price: "0",
      type: "plan",

      features: [
        "1 Doctor + 1 Assistant",
        "Patient Management (EHR)",
        "Appointment Scheduling",
        "Customised Layout",
        "*Unlimited Visits",
        "50 Patients",
      ],
      isCurrent: false,
      isSelected: false,
      quantity: 1,
    },
    {
      price: 900,
      name: "essential",
      features: [
        "Everything From Free",
        "1 Doctor + 2 Assistants",
        "Unlimited Patients & Visits",
        "Reporting",
        "Secure shared storage for patient records",
        "Efficient performance with managed load",
      ],
      isCurrent: false,
      isSelected: false,
      quantity: 1,
      frequency: "monthly",
      type: "plan",
    },
    {
      name: "flexivisit",
      price: 5,

      features: [
        "Everything from Essential",
        "*Unlimited Doctors and Assistants",
        "*Unlimited Patients and Visits",
        "*Unlimited whatsapp message",
        "Secure shared storage for patient records",
        "Standard support",
      ],
      isCurrent: false,
      isSelected: false,
      quantity: 1,
      numberOfVisits: null,
      type: "plan",
    },
  ]);

  const [isVisibleUpgradePlanModal, setVisibleUpgradePlanModal] =
    useState<boolean>(false);
  useEffect(() => {
    const userObj = window.localStorage.getItem("user");
    if (userObj) {
      const user = JSON.parse(userObj);
      if (user && user.activeClinic) {
        setActiveClinic(user.activeClinic);
        const activePlan = user.activeClinic.plan;
        const updatedPlans = plans.map((i) => {
          if (i.name === activePlan) {
            setSelectedPlan(i);

            return {
              ...i,
              isCurrent: true,
              isSelected: true,
            };
          } else {
            return {
              ...i,
              isCurrent: false,
              isSelected: false,
            };
          }
        });
        setPlans(updatedPlans);
      }
    }
  }, []);

  const handlePlanSelect = (item: any) => {
    const updatedPlans = plans.map((i) => {
      if (i.name === item.name) {
        setSelectedPlan(i);
        return {
          ...i,
          isSelected: true,
        };
      } else {
        return { ...i, isSelected: false };
      }
    });
    setPlans(updatedPlans);
  };

  const handleUpgradePlan = () => {
    setVisibleUpgradePlanModal(true);
  };

  const handleCloseModal = () => {
    setVisibleUpgradePlanModal(false);
  };

  const upgrade = async (payload: Upgrade) => {
    if (payload.type === "plan" && activeClinic) {
      const { data } = await upgradePlan(payload);
      setVisibleUpgradePlanModal(false);
      onCheckout({ ...payload, identifier: data.subscriptionId }, activeClinic);
    }
  };

  return (
    <div className="px-2 py-6 bg-white border min-h-[calc(100vh-400px)]">
      <div>
        <span className="text-lg font-medium">Subscription Details</span>
        <div className="grid grid-cols-1 px-6 py-5">
          <dl className="grid grid-cols-2 text-base leading-7 text-gray-600 gap-y-2">
            {selectedPlan &&
              selectedPlan.features.length > 0 &&
              selectedPlan.features.map((i: any, index: number) => (
                <div className="relative pl-9" key={index}>
                  <dt className="text-gray-900 ">
                    <svg
                      className="absolute left-0 w-5 h-5 text-primary top-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      data-slot="icon"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base text-gray-600 font">{i}</span>
                  </dt>
                </div>
              ))}
          </dl>
        </div>

        <div className="grid grid-cols-1 px-6 mt-6 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {plans.map((i, index) => (
            <label
              aria-label={i.name}
              key={index}
              className={`relative flex w-full p-4 bg-white border rounded-lg shadow-sm cursor-pointer focus:outline-none ${
                i.isSelected
                  ? "border-primary ring-2 ring-primary"
                  : "border-gray-300"
              }`}
              onClick={() => handlePlanSelect(i)}
            >
              <input
                type="radio"
                name="project-type"
                value="Newsletter"
                className="sr-only"
              />

              <span className="flex flex-col w-full">
                <span className="block text-base font-bold text-gray-900 capitalize">
                  {i.name}
                </span>
                <div className="py-5">
                  <span
                    id="essential-price"
                    className="text-4xl font-bold tracking-tight text-gray-900"
                  >
                    ₹{i.price}
                  </span>

                  <span className="text-sm font-semibold leading-6 text-gray-400 ">
                    {i.name === "flexivisit"
                      ? "  / per visit"
                      : "  / per doctor"}
                  </span>
                </div>

                <div className="w-full py-5">
                  {i.isCurrent ? (
                    <button
                      type="button"
                      disabled
                      className="cursor-not-allowed px-2.5 py-1.5 text-xs w-full font-semibold text-white  bg-green-400 hover:bg-green-300 rounded shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Current Plan
                    </button>
                  ) : (
                    <button
                      onClick={handleUpgradePlan}
                      type="button"
                      className="px-2.5 py-2.5 text-xs w-full font-semibold text-white bg-primary hover:bg-hover rounded shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Upgrade
                    </button>
                  )}
                </div>
              </span>

              {i.isSelected && (
                <>
                  <svg
                    className="absolute w-5 h-5 right-2 text-primary"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <span
                    className="absolute rounded-lg pointer-events-none -inset-px"
                    aria-hidden="true"
                  ></span>
                </>
              )}
            </label>
          ))}
        </div>
        <div className="px-6 py-5">
          <a
            className="text-sm underline text-primary hover:cursor-pointer"
            href="https://dhanvantaree.com/pricing"
            target="_blank"
            rel="noreferrer"
          >
            Compare plans and pricing options
          </a>
        </div>
      </div>
      {/* {isLoaderVisible && <LoaderComponent />} */}

      {isVisibleUpgradePlanModal && (
        <Modal
          modalContent={
            <UpgradePlanModal
              onUpgrade={(plan) => upgrade(plan)}
              selectedPlan={selectedPlan}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};

export default Subscription;
