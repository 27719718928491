import api from "../middlware/api";
import { BASE_URL } from "../constants";

export const getDashboardCounts = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/dashboard`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
